import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import theme from "../../../../Themes/defaultTheme";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import { FromEpochFormat } from "../../../../../Utils/Helpers/dateUtils";
import { Container, createStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Avatar,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import {
  HighlightOff as HighlightOffIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Loop as LoopIcon,
} from "@material-ui/icons";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import * as httpCall from "../../../../../Utils/apis/apis";
import { message } from "../../../../../Utils/config/messages";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";

const useLoop = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "spin 4s linear infinite",
      fontSize: "35px",
      color: "#1976D2",
      marginTop: "-6px !important",
    },
    NoRotation: {
      animation: "spin 0s linear",
      fontSize: "35px",
      color: "#1976D2",
      marginTop: "-6px !important",
    },
    triggerMessage: {
      backgroundColor: "#337ab7",
      color: "#fff",
      padding: "10px",
      textAlign: "center",
    },
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "smaller",
      fontWeight: "500",
    },
  })
);
const useDefaultStyles = makeStyles(() => ({
  card: {
    minHeight: "5rem",
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
  },
  avatar: {
    marginLeft: "-10px",
  },
  rotateIcon: {
    animation: "spin 4s linear infinite",
  },
  Norotation: {
    animation: "spin 0s linear",
  },
  popover: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  triggerMessage: {
    backgroundColor: "#337ab7",
    color: "#fff",
    padding: "10px",
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "200px",
  },
}));

const getAccounts = (arr) => {
  try {
    return arr.map((obj) => ({
      ...obj,
      fullName: `${obj.firstName || ""} ${obj.lastName || ""}`.trim(),
    }));
  } catch {
    return [];
  }
};
const M_MeetingSyncEA = (props) => {
  const { content } = props;
  const history = useHistory();
  const useStyles = makeStyles();
  const classes = useStyles();
  const defaultStyle = useDefaultStyles();
  const loop = useLoop();
  const [loading, setLoading] = React.useState(false);
  const [iconLoading, setIconLoading] = React.useState(false);
  const [meetingsData, setMeetingData] = React.useState([]);
  const [triggerStart, setTriggerStart] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState({});
  const [CardMessage, setCardMessage] = React.useState(message.SELECT_ACCOUNT);
  const [accounts, setAccounts] = React.useState([]);
  const { crmContactUserId } = getCRMContactInfo();
  let title;
  if (content && content.item && content.item.header) {
    title = content.item.header.title;
  }
  const SpName =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.genericGremlinSP
      ? content.item.content.content.genericGremlinSP
      : "getMeetingsPrefrence";
  const SQLSP = content?.item?.content?.content?.SQLSP
    ? content.item.content.content.SQLSP
    : "dbo.UpsertMeeting";
  let RedirectUrl =
    content.content && content.content.RedirectUrl
      ? content.content.RedirectUrl
      : "";

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
    setCardMessage(message.NO_MEETING_FOUND);
  };

  const fetchData = async (id) => {
    try {
      setLoading(true);
      let data = await genericGremlinSP(SpName, id);
      data = data.filter((meeting) => meeting.Tagged !== false);
      setMeetingData(data ? data : []);
      setLoading(false);
    } catch (error) {
      setMeetingData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedAccount.id) {
      fetchData(selectedAccount.id);
    }
  }, [selectedAccount]);

  useEffect(() => {
    getAccountsData();
  }, []);

  const getAccountsData = async () => {
    setLoading(true);
    let url = `/GenericGremlin/generic/getManagersForEA`;
    let requestPayload = {
      "@userid": crmContactUserId,
    };
    const result = await httpCall.httpPost(url, requestPayload);
    const Accounts = result.length > 0 ? getAccounts(result) : [];
    setAccounts(Accounts);
    setLoading(false);
  };

  const genericGremlinSP = async (name, id) => {
    try {
      let url = `/GenericGremlin/generic/${name}`;
      let requestPayload = {
        "@id": id,
      };
      let data = [];
      const result = await httpCall.httpPost(url, requestPayload);
      result.forEach((item) => {
        data.push({
          id: item.id || "",
          Subject: item.Subject || item.Subject || "",
          StartDT: item.StartDT || item.startDT || "",
          Tagged: item.Tagged,
          UserMeetingPrefrence:
            item.UserMeetingPrefrence || item.userMeetingPrefrence || "",
          edgeId: item.EdgeId || item.edgeId || "",
        });
      });
      return data;
    } catch (error) {
      return [];
    }
  };
  const TriggerSync = async () => {
    if (selectedAccount && selectedAccount.email) {
      let url = `/Meeting/MeetingSyncByUser?emailId=${selectedAccount.email.toLowerCase()}`;
      let res = await httpCall.httpPost(url, "", "", true);
      return res;
    }
  };
  const getTitle = (title) => {
    try {
      let parsedName = JSON.parse(title);
      return parsedName[0].title;
    } catch {
      return title;
    }
  };
  function handelSubtilte(data) {
    let convertData = "";
    try {
      convertData = JSON.parse(data);
      if (convertData.timeZone) {
        let newDate = new Date(convertData.dateTime + "Z");
        let year = newDate.getFullYear();
        let Month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        let Datee = day + "/" + Month + "/" + year;
        return Datee;
      } else {
        let newDate = FromEpochFormat(convertData);
        let date = new Date(newDate);
        let year = date.getFullYear();
        let Month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let finalDate = day + "/" + Month + "/" + year;
        return finalDate;
      }
    } catch (e) {
      return data;
    }
  }
  const LoadingLoop = async () => {
    setTriggerStart(true);
    let res = await TriggerSync();
    if (res.status == 200) {
      setTriggerStart(false);
      if (selectedAccount && selectedAccount.id) {
        fetchData(selectedAccount.id);
      }
    } else {
      props.setToast({
        type: "error",
        severity: "error",
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
      setTriggerStart(false);
    }
  };
  //--------- Commenting code as of now
  //-------New Endpoint will be used
  // const syncDataInEdge = async (edgeDetails) => {
  //   let edgeResponse = await httpCall.httpPost(
  //     "/CRMData/updateEdge",
  //     edgeDetails
  //   );
  //   return edgeResponse;
  // };

  // const syncDataInVertex = async (vertexDetails) => {
  //   let vertexResponse = await httpCall.httpPut(
  //     "/CRMData/update",
  //     vertexDetails
  //   );
  //   return vertexResponse;
  // };
  const syncEdgeAndVertex = async (payload) => {
    let vertexResponse = await httpCall.httpPost(
      "/CRMData/updateEdgeAndVertexMeetingSync",
      payload
    );
    return vertexResponse;
  };
  const Update = async (record, type) => {
    // let edgeFormData = [
    //   {
    //     edgeId: record.edgeId,
    //     edgeProperties: { isTagged: type === "add" },
    //   },
    // ];

    // let vertexFormData = {
    //   id: record.id,
    //   label: "meeting",
    //   SubmitToStoredProc: SQLSP,
    //   properties: {
    //     IsTagged: type === "add",
    //     isOutlook: true,
    //     Subject: record.Subject,
    //     StartDT: record.StartDT,
    //   },
    // };
    let payload = {
      edgeId: record.edgeId,
      isTagged: type === "add",
      recordId: record.id,
      SubmitToStoredProc: SQLSP,
    };

    await Promise.all([syncEdgeAndVertex(payload)]);

    // await Promise.all([
    //   // syncDataInEdge(edgeFormData),
    //   // syncDataInVertex(vertexFormData),
    // ]);
  };
  const handleSync = async (e, type, record) => {
    setIconLoading(true);
    const recordIndex = meetingsData.findIndex((item) => item.id === record.id);
    const updatedData = [...meetingsData];
    switch (type) {
      case "add":
        updatedData[recordIndex] = {
          ...updatedData[recordIndex],
          loadingTexts: "SYNCING",
        };
        break;
      case "remove":
        updatedData[recordIndex] = {
          ...updatedData[recordIndex],
          loadingTexts: "REMOVING",
        };
        break;
      default:
        break;
    }
    setMeetingData(updatedData);
    try {
      setIconLoading(false);
      let updatedData;
      if (type === "add") {
        updatedData = [...meetingsData];
      } else {
        await Update(record, type);
        updatedData = meetingsData.filter((item) => item.id !== record.id);
      }
      if (type === "add") {
        await Update(record, type);
        updatedData[recordIndex] = {
          ...updatedData[recordIndex],
          Tagged: true,
        };
      }
      setMeetingData(updatedData);
      const popMessage = (
        <span>
          {type == "add" ? message.MEETING_SYNCED : message.MEETING_DELETED}
        </span>
      );
      props.setToast({
        type: "success",
        severity: "success",
        message: popMessage,
        status: true,
      });
    } catch {
      if (selectedAccount && selectedAccount.id) {
        fetchData(selectedAccount.id);
      }
      props.setToast({
        type: "error",
        severity: "error",
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
  };
  const handleSeeMeeting = (record) => {
    const handleClick = () => {
      history.push(
        `/${RedirectUrl.pageUrl ? RedirectUrl.pageUrl : "meeting"}/${
          record.id
        }/${RedirectUrl.templateIds}`
      );
    };

    return (
      <a
        href={`/${RedirectUrl.pageUrl ? RedirectUrl.pageUrl : "meeting"}/${
          record.id
        }/${RedirectUrl.templateIds}`}
        style={{ color: "#1976D2", textDecoration: "none", cursor: "pointer" }}
        onClick={handleClick}
      >
        {record.Subject ? getTitle(record.Subject) : ""}
      </a>
    );
  };
  const isObjectEmpty = (selectedAccount) =>
    Object.keys(selectedAccount).length === 0;
  const renderResult =
    !loading && meetingsData.length > 0 ? (
      meetingsData.map((record) => {
        return (
          <container key={record.id || record.Subject}>
            <div>
              <ListItem
                button
                disabled={iconLoading}
                className={classes.popover}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <ListItemAvatar
                      style={{
                        paddingTop: "8px",
                        justify: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar>
                        <DateRangeOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={7}>
                    <div
                      className={classes.avatar}
                      onClick={() => {
                        props.onSelect && typeof props.onSelect === "function"
                          ? props.onSelect(record)
                          : "";
                      }}
                    >
                      <ListItemText
                        primary={
                          RedirectUrl && record.Subject
                            ? handleSeeMeeting(record)
                            : getTitle(record.Subject)
                        }
                        secondary={
                          <React.Fragment>
                            {record && record.subtitle ? (
                              <span>
                                <Typography component="span" variant="body2">
                                  {handleOptionSetValue(record.subtitle)}
                                </Typography>
                                <br />
                              </span>
                            ) : (
                              ""
                            )}
                            {record && record.StartDT ? (
                              <span>
                                <Typography component="span" variant="body2">
                                  {handelSubtilte(record.StartDT)}
                                </Typography>
                              </span>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        }
                      />
                    </div>
                  </Grid>
                  {record.Tagged === "" && !record.loadingTexts ? (
                    <Grid item xs={3}>
                      <Box
                        display="flex"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Tooltip title="Sync" placement="left">
                          <IconButton>
                            <CheckCircleOutlineIcon
                              onClick={(e) => handleSync(e, "add", record)}
                              style={{ fontSize: 35, color: "rgb(76 137 76)" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                          <IconButton>
                            <HighlightOffIcon
                              onClick={(e) => handleSync(e, "remove", record)}
                              style={{ fontSize: 35, color: "rgb(227 96 93)" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: !record.loadingTexts
                          ? "rgb(76 137 76)"
                          : "black",
                        fontSize: 15,
                        fontWeight: "bold",
                        textAlign: "right",
                        margin: "17px 0px 0px -30px",
                        cursor: "context-menu",
                      }}
                    >
                      {!record.loadingTexts ? "SYNCED" : record.loadingTexts}
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            </div>
            <A_Divider />
          </container>
        );
      })
    ) : !loading ? (
      <div className={loop.loading}>
        <p>{CardMessage}</p>
      </div>
    ) : (
      <>
        <Skeleton variant="text" width={80} height={80} />
        <Skeleton variant="text" width={"100%"} height={80} />
        <Skeleton variant="rect" width={"100%"} height={118} />
      </>
    );
  return (
    <ThemeProvider theme={theme}>
      <Card
        className={classes.root}
        style={{ overflow: "visible" }}
        elevation={2}
      >
        <Box className={defaultStyle.card}>
          <CardHeader
            style={{ width: "100%", height: "1rem" }}
            action={
              !isObjectEmpty(selectedAccount) ? (
                <Tooltip title="Start Syncing">
                  <Container maxWidth="sm" style={{ cursor: "pointer" }}>
                    <LoopIcon
                      className={
                        !triggerStart ? loop.NoRotation : loop.rotateIcon
                      }
                      onClick={!triggerStart ? LoadingLoop : ""}
                    />
                    <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
                  </Container>
                </Tooltip>
              ) : (
                ""
              )
            }
            title={
              <React.Fragment>
                <Typography variant="h6" id="tableTitle" component="div">
                  {title} (EA Access)
                </Typography>
              </React.Fragment>
            }
            classes={{
              title: classes.title,
            }}
          />
        </Box>
        <A_Divider />

        {triggerStart && (
          <div className={loop.triggerMessage}>{message.syncStart}</div>
        )}
        <CardContent style={{ height: "700px", overflowY: "auto" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <FormControl className={defaultStyle.formControl}>
              <InputLabel>Account</InputLabel>
              <Select
                disabled={false}
                labelId="accounts"
                id="accounts"
                value={selectedAccount.fullName}
                onChange={handleAccountChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((AccountName, index) => (
                    <MenuItem value={AccountName} key={index}>
                      {AccountName.fullName}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>
          </div>
          {renderResult}
        </CardContent>
        <CardActions>{props.action}</CardActions>
      </Card>
    </ThemeProvider>
  );
};

M_MeetingSyncEA.propTypes = {
  action: PropTypes.any,
  content: PropTypes.any,
  onSelect: PropTypes.func,
  setToast: PropTypes.func.isRequired,
  setReload: PropTypes.any,
};

M_MeetingSyncEA.defaultProps = {
  variant: "elevation",
  elevation: 3,
  header: { zoom: false },
  cardStyle: {
    root: {
      maxWidth: "100%",
      height: "100%",
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_MeetingSyncEA);
