import React, { useState } from "react";
import { Button, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import AddQuestionnaireModal from "./AddQuestionnaireModal";
import QuestionForm from "./QuestionForm";
import "../ESG.css";
import * as esgApi from "../ESG_API/index";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { logicData, unitJsonData } from "../ESG_Config";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { message } from "../../../../../../Utils/config/messages";
import emitter from "./Emitter";

const useStyles = makeStyles(() => ({
  addQuestionnaire: {
    position: "fixed",
    top: "106px",
    right: "26px",
    zIndex: "2",
  },
  customWidth: {
    width: "40.5ch",
  },
  dialog: {
    width: "100%",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ESG_QuestionnaireSetupHeader = (props) => {
  const initialState = {
    pillar: "",
    subPillar: "",
    maturityAssessment: "",
    parentQuestion: "",
    parentQuestionLogic: "",
    question: "",
    type: [],
    guidance: "",
    questionAnswerType: "",
    unitOfMeasure: "",
    paiFramework: "",
    dataConvergenceFramework: "",
    newQuestionOrImportData: "",
    staticOrUpdatedYearly: "",
    rating: "",
    sector: [],
    isConditionalQuestion: "",
    logic: "",
    year: "",
    questionName: "",
    startDate: "",
    endDate: "",
    unit: "",
    scorematrixid: "",
  };
  const initialCodeStatus = {
    pillar: false,
    subPillar: false,
    questionAnswerType: false,
    maturityAssessment: false,
    question: false,
    parentQuestion: false,
    isConditionalQuestion: false,
    paiFramework: false,
    dataConvergenceFramework: false,
    newQuestionOrImportData: false,
    staticOrUpdatedYearly: false,
    sector: false,
    logic: false,
    questionName: false,
    year: false,
    startDate: false,
    endDate: false,
    scorematrixid: false,
  };
  const [listtype, setListType] = useState([]);
  const [unitFixed, setUnitFixed] = useState(false);
  const [unittoggle, setUnitToggle] = useState(false);
  const [sectortoggle, setSectortoggle] = useState(false);
  const [itemtype, setItemType] = useState("");
  const [dialogopen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [formValues, setFormValues] = useState(initialState);
  const [guidance, setGuidance] = useState("");
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [submittoggle, setSubmittoggle] = useState(false);
  const [filterparentquestion, setfilterParentQuestion] = useState([]);
  const [finishbtn, setFinishBtn] = useState(false);
  const [radio, setRadio] = useState("");
  // const [finishtoggle, setFinishToggle] = useState(false);
  const [questionFormPayload, setQuestionFormPayload] = useState({
    questioncategoryid: 1,
    questionsubcategoryid: 1,
    maturityassessmentid: 1,
    isconditional: 1,
    parentquestionid: 0,
    questiontext: "",
    hassubquestions: false,
    parentquestionresponsetypeid: 1,
    guidance: "just this",
    subquestions: [
      {
        text: "Question 1",
        parentquestionid: 1,
        responseMeasurementUnitid: 101,
      },
      {
        text: "Question 2",
        parentquestionid: 1,
        responseMeasurementUnitid: 102,
      },
    ],
    measurementunitid: 1,
    questionanswertypeid: 1,
    paiframeworkid: 1,
    dataconvergenceframeworkid: 1,
    importhistoricdata: false,
    isstatic: true,
    rating: true,
  });
  const [editFormPayload, setEditFormPayload] = useState({
    questioncategoryid: "",
    questionsubcategoryid: "",
    maturityassessmentid: "",
    isconditional: "",
    parentquestionid: "",
    questiontext: "",
    hassubquestions: "",
    parentquestionresponsetypeid: "",
    guidance: "",
    subquestions: [
      {
        text: "",
        parentquestionid: "",
        responseMeasurementUnitid: "",
      },
      {
        text: "Question 2",
        parentquestionid: "",
        responseMeasurementUnitid: "",
      },
    ],
    measurementunitid: "",
    questionanswertypeid: "",
    paiframeworkid: "",
    dataconvergenceframeworkid: "",
    importhistoricdata: "",
    isstatic: true,
    rating: true,
  });
  const [codeError, setCodeError] = useState(initialCodeStatus);
  const [selectedItems, setSelectedItems] = useState({});
  const [erromsg, setErrorMsg] = useState("");
  const [validation, setValidation] = useState(false);
  const [alertvalidation, setAlertValidation] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [allStates, setAllStates] = useState({
    pillar: [],
    subpillar: [],
    maturityassessment: [],
    paiframework: [],
    dataconvergenceframework: [],
    questionanstype: [],
    parentquestion: [],
  });

  const {
    pillar,
    subpillar,
    maturityassessment,
    paiframework,
    dataconvergenceframework,
    questionanstype,
    parentquestion,
  } = allStates;
  const { open, setOpen, edit, setEdit, singleSurvey } = props;

  let editFormData = JSON.parse(localStorage.getItem("SubQuestion"));

  //Delete Functionality
  let handlePopup = () => {
    setIsOpen(true);
  };
  const handleCancelClose = () => {
    setIsOpen(false);
  };
  const handleConfirmClose = async () => {
    setDialogOpen(false);
    setIsOpen(false);
    setOpen(false);
    setFormValues(initialState);
    setGuidance("");
  };
  //Delete Modal Popup Data
  const getActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="OK"
          autoFocus
          onClick={handleConfirmClose}
        />
        <A_Button color="default" label="Cancel" onClick={handleCancelClose} />
      </>
    );
  };

  //Get All Pillar Data
  const getAllPillar = async () => {
    const response = await esgApi.getAllPillar();
    setAllStates((prevStates) => ({ ...prevStates, pillar: response }));
  };
  //Get SubPillar according to Pillar
  const getAllSubPillar = async () => {
    const response = await esgApi.getAllSubPillar(selectedItems.pillar.id);
    setAllStates((prevStates) => ({ ...prevStates, subpillar: response }));
  };

  // Get All Maturity Assessment
  const getAllMaturityAssessment = async () => {
    const response = await esgApi.getAllMaturityAssessment();
    setAllStates((prevStates) => ({
      ...prevStates,
      maturityassessment: response,
    }));
  };

  //Get All PAI Framwork
  const getAllPaiFramework = async () => {
    const response = await esgApi.getAllPaiFramework();
    setAllStates((prevStates) => ({
      ...prevStates,
      paiframework: response,
    }));
  };
  //Get All Data Convergence Framwork
  const getAllDataConvergenceframework = async () => {
    const response = await esgApi.getAllDataConvergenceframework();
    setAllStates((prevStates) => ({
      ...prevStates,
      dataconvergenceframework: response,
    }));
  };
  //Get Question Answer Type
  const getQuestionAnstype = async () => {
    const response = await esgApi.getQuestionAnstype();
    setAllStates((prevStates) => ({
      ...prevStates,
      questionanstype: response,
    }));
  };
  //Get Parent Question
  const getParentQuestion = async () => {
    setFormDataLoading(true);
    const surveyid = JSON.parse(localStorage.getItem("Survey"));
    const response = await esgApi.getQuestionsJsonFromSurvey(surveyid);
    let parentQuestions = [];
    if (response) {
      parentQuestions = response.filter(
        (ques) => ques.questionAnswerTypeId === 3
      );
    }
    setAllStates((prevStates) => ({
      ...prevStates,
      parentquestion: parentQuestions,
    }));
    setFormDataLoading(false);
  };
  // Add Form Post Request
  const addFormPost = async () => {
    setLoading(true);
    // setFinishToggle(true);
    localStorage.removeItem("SubQuestion");
    // let res = edit
    //   ? await esgApi.postUpdateFormQuestion(
    //       editFormData.questionId,
    //       questionFormPayload
    //     )
    //   : await esgApi.postAddFormQuestion(questionFormPayload);
    if (!questionFormPayload.isconditional) {
      let checkParent =
        questionFormPayload.isconditional === true ||
        questionFormPayload.isconditional === 1
          ? false
          : true;
      if (checkParent) {
        questionFormPayload.parentquestionid = null;
      }
    }
    let res = await esgApi.postAddFormQuestion(questionFormPayload);

    // if (edit == false && res.statusCode != 200) {
    //   setErrorMsg(res.errorMsg);
    //   setFailure(true);
    //   setLoading(false);
    // }
    if (
      res && res.response && res.response.data && res.response.data.statusCode
        ? res.response.data.statusCode
        : res.statusCode !== 200
    ) {
      setErrorMsg(
        res.response.data.errorMsg
          ? res.response.data.errorMsg
          : "Error Occured While Adding Form"
      );
      setFailure(true);
      setLoading(false);
      setIsOpen(false);
      setButtonDisabled(false);
    } else {
      // if (res.response.data.parentquestionid) {
      //   setSuccess(true);
      //   setFormValues(initialState);
      //   window.location.reload();
      //   return;
      // }
      let patchPayload = {};
      if (res && res.data) {
        patchPayload = {
          surveyid: JSON.parse(localStorage.getItem("Survey")),
          questionids: [res.data.questionid],
        };
      }
      const mapRes = await esgApi.patchSurveyQuestionMap(patchPayload);
      console.log({ mapRes });
      setSuccess(true);
      localStorage.setItem("QuestionFormAdded", true);
      emitter.emit("reloadSetupViewEvent", true);
      handleConfirmClose();
    }
    setLoading(false);
    setIsOpen(false);
  };

  //EditForm
  const editForm = async () => {
    const logicdata = logicData;
    const pillarData = await esgApi.getAllPillar();
    const Pillar = pillarData.find(
      (element) =>
        element.questioncategoryid === editFormData.questionCategoryId
    );
    const subpillarData = await esgApi.getAllSubPillar(
      Pillar.questioncategoryid
    );
    setAllStates((prevStates) => ({
      ...prevStates,
      subpillar: subpillarData,
    }));

    const SubPillar = subpillarData.find(
      (element) =>
        element.questioncategoryid === editFormData.questionCategoryId
    );
    // const MaturityAssess = await esgApi.getAllMaturityAssessment();
    // const MaturityAssessment = MaturityAssess.find(
    //   (element) =>
    //     element.maturityassessmentid === editFormData.maturityAssessmentId
    // );
    const paiframework = await esgApi.getAllPaiFramework();
    const PaiFramework = paiframework.find(
      (element) => element.paiframeworkid === editFormData.paiFrameworkId
    );
    const dataconvergenceframework =
      await esgApi.getAllDataConvergenceframework();
    const DataConvergenceFramework = dataconvergenceframework.find(
      (element) =>
        element.dataConvergenceframeworkid ===
        editFormData.dataConvergenceFrameworkId
    );
    const questionanstype = await esgApi.getQuestionAnstype();
    const QuesAnsType = questionanstype.find(
      (element) =>
        element.questionanswertypeId === editFormData.questionAnswertypeId
    );
    const parentQuestion = await esgApi.getParentQuestion();
    const parentQues = parentQuestion.find(
      (element) => element.questionId === editFormData.parentQuestionId
    );
    const logicValue = editFormData.parentQuestionResponseTypeId
      ? logicdata.find(
          (element) =>
            element.logicId === editFormData.parentQuestionResponseTypeId
        )
      : "";
    const measurementUnitId = unitJsonData.find(
      (element) => element.measurementunitid === editFormData.measurementUnitId
    );
    setListType(editFormData.subQuestion ? editFormData.subQuestion : []);
    setUnitToggle(true);

    setFormValues({
      ...formValues,
      pillar: Pillar.name,
      subPillar: SubPillar.name,
      maturityAssessment: editFormData.unit ? editFormData.unit : "",
      isConditionalQuestion: editFormData.isConditional ? "Yes" : "No",
      guidance: editFormData.guidance,
      question: editFormData.questionText,
      paiFramework: PaiFramework.name,
      dataConvergenceFramework: DataConvergenceFramework.name,
      questionAnswerType: QuesAnsType.name,
      newQuestionOrImportData: editFormData.importHistoricData ? "Yes" : "No",
      staticOrUpdatedYearly: editFormData.isStatic ? "Yes" : "No",
      rating: editFormData.rating ? 1 : 0,
      parentQuestion: parentQues ? parentQues.questionText : "",
      logic: logicValue && logicValue.name ? logicValue.name : "",
      unitOfMeasure: measurementUnitId.name,
    });

    setEditFormPayload({
      ...editFormPayload,
      questioncategoryid: Pillar.questioncategoryid,
      questionsubcategoryid: SubPillar.questionsubcategoryid,
      maturityassessmentid: editFormData.unit ? editFormData.unit : "",
      isconditional: editFormData.isConditional === "Yes" ? true : false,
      parentquestionid: parentQues ? parentQues.parentquestionid : null,
      questiontext: editFormData.questionText,
      hassubquestions: editFormData.hasSubQuestions,
      parentquestionresponsetypeid: logicValue.logicId,
      guidance: editFormData.guidance,
      type: listtype,
      measurementunitid: measurementUnitId.measurementunitid,
      questionanswertypeid: QuesAnsType.questionanswertypeid,
      paiframeworkid: PaiFramework.paiframeworkid,
      dataconvergenceframeworkid:
        DataConvergenceFramework.dataConvergenceframeworkid,
      importhistoricdata:
        editFormData.importHistoricData === "Yes" ? true : false,
      isstatic: editFormData.isStatic === "Yes" ? true : false,
      rating: radio === "Yes" ? true : false,
    });
  };

  //Calling all required API
  useEffect(() => {
    if (open === true || edit === true) {
      getAllPillar();
      getAllMaturityAssessment();
      getAllPaiFramework();
      getQuestionAnstype();
      getAllDataConvergenceframework();
      getParentQuestion();
      if (edit === true) {
        editForm();
      } else setFormValues(initialState);
    }
  }, [open, edit]);

  useEffect(() => {
    if (selectedItems) {
      if (selectedItems.pillar) {
        getAllSubPillar();
      }
    }
  }, [selectedItems]);

  useEffect(() => {
    setTimeout(() => {
      setAlertValidation(true);
    }, [3000]);
  }, [alertvalidation]);

  useEffect(() => {
    if (validation == true) {
      setButtonDisabled(true);
      addFormPost();
    }
  }, [validation, submittoggle]);
  useEffect(() => {
    formValues.parentQuestion = "";
    const filterParentData =
      parentquestion &&
      selectedItems.pillar &&
      selectedItems.subPillar &&
      parentquestion.filter((item) => {
        return (
          item.questionCategoryId === selectedItems.pillar.id &&
          item.questionSubCategoryId === selectedItems.subPillar.id
        );
      });

    setfilterParentQuestion(filterParentData);
  }, [selectedItems.pillar, selectedItems.subPillar]);
  useEffect(() => {
    formValues.subPillar = "";
  }, [selectedItems.pillar]);

  const handleOpen = () => {
    setFormValues(initialState);
    setCodeError(initialCodeStatus);
    setEdit(false);
    setOpen(true);
  };

  const handleDialogOpen = () => {
    setCodeError(initialCodeStatus);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setEdit(false);
    setListType([]);
    if (!finishbtn) {
      setIsOpen(false);
    }
    setSuccess(false);
    setFailure(false);
    setDialogOpen(false);
    setFinishBtn(false);
    setButtonDisabled(false);
  };
  const handleCloseIcon = () => {
    setEdit(false);
    setListType([]);
    setIsOpen(true);
    setSuccess(false);
    setFailure(false);
    setDialogOpen(false);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleAddType = () => {
    // const unitMeasureId = unitJsonData.find(
    //   (element) => element.name === formValues.unitOfMeasure
    // );
    const trimmedItemType = itemtype.trim();
    const isItemTypeExists = listtype.some((item) => item.text === itemtype);
    if (itemtype && !isItemTypeExists && trimmedItemType) {
      setListType([
        ...listtype,
        {
          text: itemtype,
          responseMeasurementUnitid: formValues.unit,
        },
      ]);
      setItemType("");
    }
  };

  const handleDelete = (typename) => {
    if (listtype.length === 1) {
      setUnitToggle(false);
    }
    setListType(listtype.filter((item) => item.text !== typename));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    //Extracting Id from fieldName

    const getDataArray = (fieldName) => {
      switch (fieldName) {
        case "pillar":
          return pillar;
        case "subPillar":
          return subpillar;
        case "dataConvergenceFramework":
          return dataconvergenceframework;
        case "maturityAssessment":
          return maturityassessment;
        case "paiFramework":
          return paiframework;
        case "questionAnswerType":
          return questionanstype;
        case "parentQuestion":
          return filterparentquestion;
        default:
          return [];
      }
    };

    if (
      name === "pillar" ||
      name === "subPillar" ||
      name === "dataConvergenceFramework" ||
      name === "maturityAssessment" ||
      name === "paiFramework" ||
      name == "subPillar" ||
      name === "parentQuestion" ||
      name === "questionAnswerType" ||
      name === "questionAnswerType" ||
      name === "parentQuestion"
    ) {
      const dataArray = getDataArray(name);
      if (name != "parentQuestion") {
        var selectedItem = dataArray.find((item) => item.name === value);
      } else {
        selectedItem = dataArray.find((item) => item.questionText === value);
      }

      if (selectedItem) {
        const selectedItemId =
          (name == "pillar" && selectedItem.questioncategoryid) ||
          selectedItem.questionsubcategoryid ||
          selectedItem.dataConvergenceframeworkid ||
          selectedItem.maturityassessmentid ||
          selectedItem.paiframeworkid ||
          selectedItem.questionanswertypeid ||
          selectedItem.questionId;

        setSelectedItems((prevItems) => ({
          ...prevItems,
          [name]: {
            id: selectedItemId,
            name: value,
          },
        }));
      }
    }

    if (
      [
        "pillar",
        "subPillar",
        "questionAnswerType",
        "maturityAssessment",
        "question",
        "guidance",
        "parentQuestion",
        "type",
        "unitOfMeasure",
        "isConditionalQuestion",
        "paiFramework",
        "dataConvergenceFramework",
        "newQuestionOrImportData",
        "staticOrUpdatedYearly",
        "sector",
        "logic",
        "questionName",
        "year",
        "startDate",
        "endDate",
      ].includes(name)
    ) {
      setCodeError((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
    if (name === "type") {
      formValues.questionAnswerType = "";
      setItemType(value);
    }

    if (name === "unitOfMeasure") {
      setUnitFixed(value);
    }

    if (formValues.sector.length > 0) {
      setSectortoggle(false);
    }

    if (name !== "type") {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  function isFieldEmpty(value) {
    return !value || (typeof value === "string" && value.trim() === "");
  }
  const formatDate = (date) => {
    const inputDateString = date;
    const parts = inputDateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const inputDate = new Date(year, month - 1, day);
    //Get only the date part iso format
    let outputDateString =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    outputDateString += "T00:00:00.000Z";
    return outputDateString;
  };

  const questionPayload = () => {
    const logic = logicData.find(
      (element) => element.name === formValues.logic
    );
    // const unitMeasure = unitJsonData.find(
    //   (element) => element.name === formValues.unitOfMeasure
    // );
    if (formValues.pillar) {
      setQuestionFormPayload({
        ...questionFormPayload,
        questioncategoryid:
          selectedItems && selectedItems.pillar
            ? selectedItems.pillar.id
            : editFormPayload.questioncategoryid
            ? editFormPayload.questioncategoryid
            : 0,
        questionsubcategoryid:
          selectedItems && selectedItems.subPillar
            ? selectedItems.subPillar.id
            : editFormPayload.questioncategoryid
            ? editFormPayload.questioncategoryid
            : 0,
        maturityassessmentid:
          selectedItems && selectedItems.maturityAssessment
            ? selectedItems.maturityAssessment.id
            : editFormPayload.maturityassessmentid
            ? editFormPayload.maturityassessmentid
            : 0,
        isconditional: formValues.isConditionalQuestion
          ? formValues.isConditionalQuestion === "Yes"
            ? true
            : false
          : editFormPayload.isconditional
          ? editFormPayload.isconditional
          : false,
        parentquestionid:
          selectedItems && selectedItems.parentQuestion
            ? selectedItems.parentQuestion.id
            : editFormPayload.parentquestionid
            ? editFormPayload.parentquestionid
            : null,
        questiontext: formValues.question
          ? formValues.question
          : editFormPayload.questiontext
          ? editFormPayload.questiontext
          : "",
        hassubquestions:
          listtype.length > 0
            ? true
            : editFormPayload.hassubquestions
            ? editFormPayload.hassubquestions
            : false,
        parentquestionresponsetypeid:
          logic && logic.logicId
            ? logic.logicId
            : editFormPayload && editFormPayload.parentquestionresponsetypeid
            ? editFormPayload.parentquestionresponsetypeid
            : null,
        guidance: guidance
          ? guidance
          : editFormPayload.guidance
          ? editFormPayload.guidance
          : "",
        subquestions: listtype
          ? listtype
          : editFormPayload.type
          ? editFormPayload.type
          : [],
        measurementunitid: formValues.unit
          ? formValues.unit
          : editFormPayload.measurementunitid
          ? editFormPayload.measurementunitid
          : "",
        questionanswertypeid:
          selectedItems && selectedItems.questionAnswerType
            ? selectedItems.questionAnswerType.id
            : editFormPayload.questionanswertypeid
            ? editFormPayload.questionanswertypeid
            : 0,
        paiframeworkid:
          selectedItems && selectedItems.paiFramework
            ? selectedItems.paiFramework.id
            : editFormPayload.paiframeworkid
            ? editFormPayload.paiframeworkid
            : 0,
        dataconvergenceframeworkid:
          selectedItems && selectedItems.dataConvergenceFramework
            ? selectedItems.dataConvergenceFramework.id
            : editFormPayload.dataconvergenceframeworkid
            ? editFormPayload.dataconvergenceframeworkid
            : 0,
        importhistoricdata: formValues.newQuestionOrImportData
          ? formValues.newQuestionOrImportData === "Yes"
            ? true
            : false
          : editFormPayload.importhistoricdata
          ? editFormPayload.importhistoricdata
          : false,
        isstatic: formValues.staticOrUpdatedYearly
          ? formValues.staticOrUpdatedYearly === "Yes"
            ? true
            : false
          : editFormPayload.isstatic
          ? editFormPayload.isstatic
          : false,
        rating: radio
          ? radio === "Yes"
            ? true
            : false
          : editFormPayload.rating
          ? editFormPayload.rating
          : 0,
      });
    }
  };

  const handleSubmitQuestionnaireModal = async () => {
    var currentDate = new Date();
    let fieldErrors = {
      questionName: isFieldEmpty(formValues.questionName),
      year: isFieldEmpty(formValues.year),
      startDate: isFieldEmpty(formValues.startDate),
      endDate: isFieldEmpty(formValues.endDate),
      scorematrixid: isFieldEmpty(formValues.scorematrixid),
    };
    var startDateString = formValues.startDate;
    var endDateString = formValues.endDate;

    var startDateComponents = startDateString.split("/");
    var endDateComponents = endDateString.split("/");

    var startDate = new Date(
      parseInt(startDateComponents[2], 10),
      parseInt(startDateComponents[1], 10) - 1,
      parseInt(startDateComponents[0], 10)
    );
    var endDate = new Date(
      parseInt(endDateComponents[2], 10),
      parseInt(endDateComponents[1], 10) - 1,
      parseInt(endDateComponents[0], 10)
    );

    if (startDate > endDate) {
      alert("The start date should not be later than the end date.");
      fieldErrors.startDate = true;
      fieldErrors.endDate = true;
    }
    startDate.setHours(23, 59, 59, 999);
    endDate.setHours(23, 59, 59, 999);
    if (startDate < currentDate || endDate < currentDate) {
      alert("Please select a date that is not in the past.");
      if (startDate < currentDate) {
        fieldErrors.startDate = true;
      }
      if (endDate < currentDate) {
        fieldErrors.endDate = true;
      }
    } else if (
      formValues.questionName &&
      formValues.year &&
      !fieldErrors.startDate &&
      !fieldErrors.endDate &&
      !fieldErrors.scorematrixid
    ) {
      setButtonDisabled(true);
      const formattedStartDate = formatDate(formValues.startDate);
      const formattedEndDate = formatDate(formValues.endDate);

      const res = await esgApi.addSurvey({
        name: formValues.questionName,
        startdate: formattedStartDate,
        enddate: formattedEndDate,
        year: formValues.year,
        scorematrixid: formValues.scorematrixid,
      });
      if (res && res.statusCode && res.statusCode == 200) {
        setSuccess(true);
      } else if (res && res.errorMsg) {
        setErrorMsg(
          res && res.response && res.response.data && res.response.data.errorMsg
            ? res.response.data.errorMsg
            : "Unable to add Questionnaire"
        );
      } else setFailure(true);
      setErrorMsg(
        res && res.response && res.response.data && res.response.data.errorMsg
          ? res.response.data.errorMsg
          : "Unable to add Questionnaire"
      );
      handleModalClose();
      window.location.reload();
    }
    setCodeError(fieldErrors);
  };

  const handleSubmit = () => {
    setValidation(false);
    setFinishBtn(true);
    let fieldErrors = {
      pillar: isFieldEmpty(formValues.pillar),
      subPillar: isFieldEmpty(formValues.subPillar),
      questionAnswerType: isFieldEmpty(formValues.questionAnswerType),
      maturityAssessment: isFieldEmpty(formValues.maturityAssessment),
      isConditionalQuestion: isFieldEmpty(formValues.isConditionalQuestion),
      question: isFieldEmpty(formValues.question),
      parentQuestion:
        formValues.isConditionalQuestion == "Yes"
          ? isFieldEmpty(formValues.parentQuestion)
          : false,
      paiFramework: isFieldEmpty(formValues.paiFramework),
      dataConvergenceFramework: isFieldEmpty(
        formValues.dataConvergenceFramework
      ),
      staticOrUpdatedYearly: isFieldEmpty(formValues.staticOrUpdatedYearly),
      newQuestionOrImportData: isFieldEmpty(formValues.newQuestionOrImportData),
      logic:
        formValues.isConditionalQuestion == "Yes"
          ? isFieldEmpty(formValues.logic)
          : false,
      questionName: isFieldEmpty(formValues.questionName),
      year: isFieldEmpty(formValues.year),
      startDate: isFieldEmpty(formValues.startDate),
      endDate: isFieldEmpty(formValues.endDate),
    };
    questionPayload();
    setUnitToggle(false);
    setSubmittoggle(!submittoggle);
    setSectortoggle(formValues.sector.length > 0 ? false : true);
    setCodeError(fieldErrors);
    const excludeKeys = ["endDate", "questionName", "startDate", "year"];

    const noErrors = Object.keys(fieldErrors)
      .filter((key) => !excludeKeys.includes(key))
      .every((key) => fieldErrors[key] === false);

    setAlertValidation(noErrors);
    setValidation(noErrors);
  };
  return (
    <div>
      <AddQuestionnaireModal
        dialogopen={dialogopen}
        handleModalClose={handleModalClose}
        formValues={formValues}
        setFormValues={setFormValues}
        handleChange={handleChange}
        codeError={codeError}
        setCodeError={setCodeError}
        handleSubmit={handleSubmitQuestionnaireModal}
        handleModalOpen={handleModalOpen}
        isButtonDisabled={isButtonDisabled}
        setButtonDisabled={setButtonDisabled}
        handleClose={handleClose}
      />
      {
        <div className="addIconAlignment">
          {singleSurvey && singleSurvey[0] && singleSurvey[0].status == 0 && (
            <IconButton color="primary" aria-label="add" onClick={handleOpen}>
              <AddIcon />
            </IconButton>
          )}
        </div>
      }
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleDialogOpen()}
        className={classes.addQuestionnaire}
      >
        Create new questionnaire
      </Button>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        PaperProps={{ style: { width: "100%" } }}
        BackdropProps={{ invisible: true }}
      >
        <div className={classes.addForm}>
          <div>
            <DialogTitle>
              {edit ? "Edit ESG Question" : "Add ESG Question"}
            </DialogTitle>
          </div>
          <div>
            <CloseIcon
              onClick={() => handleCloseIcon()}
              className="closeIcon"
            />
          </div>
        </div>
        <QuestionForm
          formValues={formValues}
          codeError={codeError}
          handleChange={handleChange}
          itemtype={itemtype}
          sectortoggle={sectortoggle}
          listtype={listtype}
          unittoggle={unittoggle}
          unitFixed={unitFixed}
          handleDelete={handleDelete}
          handleAddType={handleAddType}
          handleSubmit={handleSubmit}
          dialogopen={dialogopen}
          pillar={pillar}
          subpillar={subpillar}
          maturityassessment={maturityassessment}
          paiframework={paiframework}
          dataconvergenceframework={dataconvergenceframework}
          questionanstype={questionanstype}
          parentquestion={parentquestion}
          setGuidance={setGuidance}
          loading={loading}
          formDataLoading={formDataLoading}
          edit={edit}
          filterparentquestion={filterparentquestion}
          isButtonDisabled={isButtonDisabled}
          setButtonDisabled={setButtonDisabled}
          alertvalidation={alertvalidation}
          setAlertValidation={setAlertValidation}
          radio={radio}
          setRadio={setRadio}
        />
      </Dialog>
      <A_Snackbar
        open={success}
        message={"Successfully Added"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
      <A_SimpleDialog
        id="2"
        open={isOpen}
        closePopUp={handlePopup}
        title="Do you want to close?"
        dialogContent={message.ARE_YOU_SURE}
        dialogActions={getActionItems()}
        onClose={handleCancelClose}
      />
    </div>
  );
};
ESG_QuestionnaireSetupHeader.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  edit: PropTypes.any,
  setEdit: PropTypes.any,
  singleSurvey: PropTypes.any,
};

export default ESG_QuestionnaireSetupHeader;
