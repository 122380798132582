import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  selectMenu: {
    position: "relative",
  },
  popoverPaper: {
    transform: "translateY(-50%)",
  },
  selectedListItem: {
    backgroundColor: "yellow",
  },
}));

const GroupsComponent = React.memo(
  ({ dropDownVals, selectedGroups, handleChange }) => {
    const classes = useStyles();

    if (!dropDownVals || dropDownVals.length === 0)
      return "User already added to all groups";

    return (
      <FormControl fullWidth>
        <InputLabel id="groups-select-label">Select Groups</InputLabel>
        <Select
          labelId="groups-select-label"
          id="groups-select"
          multiple
          value={selectedGroups}
          MenuProps={MenuProps}
          onChange={handleChange}
          renderValue={(selected) => (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selected.map((value) => (
                <Chip
                  style={{
                    overflow: "auto",
                    margin: "2px",
                  }}
                  key={value.id}
                  label={value.name}
                />
              ))}
            </div>
          )}
          classes={{ selectMenu: classes.selectMenu }}
        >
          {dropDownVals.map((group) => (
            <MenuItem
              key={group.id}
              value={group}
              style={{
                backgroundColor:
                  selectedGroups.indexOf(group) > -1 ? "gainsboro" : "",
              }}
            >
              <Checkbox
                checked={selectedGroups.indexOf(group) > -1}
                color="primary"
              />
              <ListItemText primary={group.name} style={{ textWrap: "wrap" }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

GroupsComponent.displayName = "GroupsComponent";

GroupsComponent.propTypes = {
  dropDownVals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default GroupsComponent;
