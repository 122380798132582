/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Notifications } from "@material-ui/icons";
import { Typography, Box } from "@material-ui/core";
import { useParams } from "react-router";
import { AccountTreeOutlined } from "@material-ui/icons";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import { arrayOfObjectSortingByKey } from "../GenericList/Helper/GenericList.Helper";
import { makeStyles } from "@material-ui/core/styles";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
const useStyles = makeStyles((theme) => ({
  timeline: {
    width: "2px",
    height: "12px",
    display: "inline-flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  verticalLine: {
    width: "2px",
    height: "12px",
    display: "inline-flex",
    justifyContent: "center",
    alignSelf: "center",
  },
}));
const O_DealTimeline = (props) => {
  const { pageUrl, crmId } = useParams();
  const [timelineData, setTimelineData] = useState([]);
  const { prefix, timelineTitle, timelineDescription } = props.content;
  useEffect(() => {
    getTimelineData();
  }, []);
  const classes = useStyles();
  let getTimelineData = async () => {
    let statusHistory = props.currentPage.data.statusHistory;
    let currentTimeline = [];
    try {
      currentTimeline = JSON.parse(statusHistory);
    } catch (e) {}
    let payload = {
      request: {
        fields: [timelineTitle || "", timelineDescription || ""],
        label: "ircportal",
        sourceLabel: pageUrl,
        sourceId: crmId,
      },
    };
    let response = await httpCall.httpPost("/IRC/getIRCRecord", payload);
    response = response
      ? (currentTimeline = [
          ...currentTimeline.map((item) => {
            try {
              if (typeof item?.date == "string") {
                let [day, month, year] = item.date.split("/");
                return {
                  ...item,
                  epoch: Date.parse(`${month}/${day}/${year}`) / 1000,
                };
              } else {
                return {
                  ...item,
                  epoch: item.date,
                };
              }
            } catch {
              return {
                ...item,
                epoch: item.date,
              };
            }
          }),
          ...response.map((ircItem) => ({
            status: `${prefix ? `${prefix}:` : ""} ${handleOptionSetValue(
              ircItem[timelineDescription] || ""
            )}`,
            epoch: ircItem[timelineTitle],
            date: getFormattedDate(
              FromEpochFormat(ircItem[timelineTitle]),
              "DD/MM/YYYY"
            ),
          })),
        ])
      : [];
    currentTimeline = arrayOfObjectSortingByKey(currentTimeline, "epoch");
    setTimelineData(currentTimeline);
  };
  let getIcon = (data) => {
    if (data.includes("Created")) {
      return (
        <TimelineDot color="primary">
          <Notifications />
        </TimelineDot>
      );
    } else if (prefix && data.includes(prefix)) {
      return (
        <Typography style={{ color: "#9c8662", fontSize: "1.2rem" }}>
          {prefix}
        </Typography>
      );
    } else {
      return (
        <TimelineDot color="primary">
          <AccountTreeOutlined />
        </TimelineDot>
      );
    }
  };
  return (
    <Box overflow={"auto"}>
      {timelineData ? (
        <Timeline position="alternate">
          <Box className={classes.timeline} bgcolor={"#f0f0f0"}></Box>
          {Array.isArray(timelineData)
            ? timelineData.map((timeline, index) => {
                let date =
                  typeof timeline?.date == "string"
                    ? timeline.date.split(" ")[0]
                    : convertEpochToOffsetDate(timeline?.date);
                return (
                  <>
                    <TimelineItem
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: 0,
                      }}
                    >
                      <TimelineOppositeContent>
                        <Typography>{date}</Typography>
                      </TimelineOppositeContent>
                      <div>{getIcon(timeline.status || "")}</div>
                      <TimelineContent>
                        <Typography component="span">
                          {timeline.status}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <Box
                      className={classes.verticalLine}
                      bgcolor={"#f0f0f0"}
                    ></Box>
                  </>
                );
              })
            : ""}
        </Timeline>
      ) : (
        ""
      )}
    </Box>
  );
};
function convertEpochToOffsetDate(epochDate) {
  var localDate = new Date(epochDate * 1000);
  var formattedDate =
    ("0" + localDate.getDate()).slice(-2) +
    "/" +
    ("0" + (localDate.getMonth() + 1)).slice(-2) +
    "/" +
    localDate.getFullYear();
  return formattedDate;
}
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_DealTimeline);
