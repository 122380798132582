import React, { useEffect, useState } from "react";
import A_DropdownFilter from "../../../Atoms/Inputs/DropdownFields/A_DropdownFilter";
import A_SearchInputField from "../../../Atoms/Inputs/SearchInputField/A_SearchInputField";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApiData,
  fetchApiSelectedValue,
  fetchFilterData,
} from "../../../../../store/actions/proposalTemplateActions";
import { bindActionCreators } from "redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { propTypes } from "react-addons-css-transition-group";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
const ProposalTracker = (props) => {
  const {
    spNamePortfolio = "",
    spNameBorrower = "",
    query = "",
    sqlAPI = false,
    fieldName = "",
    pageId = "",
    templateId = "",
    pageName = "",
    snackBarMessage = "",
    addBtnTitle = "",
    filterDropdownLabel = "",
    filterOptionLabel = "",
  } = (props && props.content && props.content.chart) || {};
  const [openForm, setOpenForm] = useState(false);
  const [entity, setEntity] = useState({});
  const [borrowertoggle, setBorrowerToggle] = useState(false);
  const [selectedDropData, setSelectedDropData] = useState("");
  const [selectedBorData, setSelectedBorData] = useState("");
  const [addtoggle, setAddToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdowndata, setDropdownData] = useState(
    props && props.sqlApiData && props.sqlApiData.res
  );
  const prevEntity = useSelector((state) => state.previousEntity.prevEntity);
  const [borrowerData, setBorrowerData] = useState(
    props && props.borrowerData && props.borrowerData.res
      ? props.borrowerData.res
      : []
  );
  let { borrowerdata } = props;
  borrowerdata =
    borrowerdata && borrowerdata.borrowerData && borrowerdata.borrowerData.res;
  const dispatch = useDispatch();

  useEffect(() => {
    setDropdownData(props && props.sqlApiData && props.sqlApiData.res);
  }, [props]);
  const userEmail = props.user && props.user.email;
  // Fetching API for Proposal
  useEffect(() => {
    const Query = query;
    const SpNamePortfolio = spNamePortfolio;
    if (sqlAPI == true) {
      dispatch(fetchApiData(Query, SpNamePortfolio, userEmail));
    }
  }, [dispatch]);

  // Fetching Borrower Data with respect to Proposal Dropdown Value
  useEffect(() => {
    if (selectedDropData.length > 0) {
      setSelectedBorData("");
      const Query = query;
      const SpNameBorrower = spNameBorrower;
      dispatch(
        fetchApiSelectedValue(
          Query,
          SpNameBorrower,
          selectedDropData,
          userEmail
        )
      );
    }
  }, [selectedDropData]);

  // setSelectedBorData("");
  useEffect(() => {
    setBorrowerData(
      props && props.borrowerData && props.borrowerData.res
        ? props.borrowerData.res
        : []
    );
  }, [props && props.borrowerData]);

  useEffect(() => {
    if (
      selectedDropData.length > 0 &&
      selectedBorData &&
      selectedBorData.length > 0
    ) {
      dispatch(fetchFilterData(selectedDropData, selectedBorData));
    }
  }, [selectedDropData, selectedBorData]);

  const handleAddButton = () => {
    if (prevEntity && prevEntity.templateId !== templateId) {
      dispatch(resetManagePage());
    }
    setOpenForm(true);
  };

  useEffect(() => {
    setEntity({
      pageName: pageName,
      recordId: "",
      pageId: pageId,
      templateId: templateId,
    });
  }, []);
  const setPorfolio = (val) => {
    setLoading(true);
    setSelectedDropData(val);

    setTimeout(() => {
      setLoading(false);
    }, 50);
  };
  return (
    <div>
      <div>
        <div style={{ display: "flex", marginRight: 10 }}>
          <div style={{ marginRight: 25 }}>
            <A_DropdownFilter
              setBorrowerToggle={setBorrowerToggle}
              dropdowndata={dropdowndata}
              selectedDropData={selectedDropData}
              setSelectedDropData={setPorfolio}
              filterDropdownLabel={filterDropdownLabel}
            />
          </div>
          {!loading && (
            <A_SearchInputField
              borrowerToggle={borrowertoggle}
              setAddToggle={setAddToggle}
              dropSearchData={borrowerData}
              filterOptionLabel={filterOptionLabel}
              setSelectedBorData={setSelectedBorData}
              dropNewSearchData={borrowerdata}
            />
          )}
        </div>
        {addtoggle && (
          <Button
            style={{ float: "right", bottom: 50, right: 50 }}
            variant="contained"
            color="primary"
            onClick={handleAddButton}
          >
            {addBtnTitle}
          </Button>
        )}
      </div>
      {openForm && (
        <M_ManageEntityForm
          isEditable={false}
          entity={entity}
          isFormUploadFileShare={true}
          open={openForm}
          refresh={false}
          fieldName={fieldName}
          snackBarContent={snackBarMessage}
          onClose={() => {
            setOpenForm(false);
          }}
        />
      )}
    </div>
  );
};
ProposalTracker.propTypes = {
  sqlApiData: propTypes.any,
  borrowerData: propTypes.any,
  content: propTypes.any,
  user: propTypes.any,
};

function mapStateToProps(state) {
  return {
    sqlApiData: state.proposalTrackerReducer.apiData,
    borrowerData: state.proposalTrackerReducer.borrowerData,
    user: state.oidc.user.profile,
    borrowerdata: state.proposalTrackerReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ProposalTracker);
