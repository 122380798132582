import React from "react";
import PropTypes from "prop-types";

import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import { TabContainer } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  body: {
    height: "100px",
  },
  pillar: {
    color: "white",
    background: "rgba(38,52,64,1)",
    textDecoration: "none",
    border: "0px",
  },
  stickyHead: {
    zIndex: 0,
    position: "sticky",
    top: "0px",
  },
  removeBoder: {
    textDecoration: "none",
    border: "0px",
  },
  removePadding: {
    "& input": {
      padding: "10px",
    },
    padding: "2px",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
  loader: {
    position: "absolute",
    left: "50%",
  },
  mrgBottom: {
    marginBottom: "5px",
  },
  headingColor: {
    color: "white",
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: "10px",
    border: 0,
  },
}))(TableCell);
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  border: "1px solid white",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function ESG_MaturityMatrixPreviewLegend(props) {
  const classes = useStyles();
  // Sample config to modify the table contents
const config = [
  {
    statementColour: "Green",
    implication: "The asset has successfully achieved the described Maturity Assessment",
  },
  {
    statementColour: "Grey",
    implication: "The asset has not achieved the described Maturity Assessment",
  },
];
  return (
    <TabContainer component={Paper} className={classes.body}>
      <Table aria-label="customized table">
        <TableHead style={{ background: "grey" }}>
          <TableRow>
            <StyledTableCell>
              <div
                className={`${classes.headingColor} ${classes.stickyHead}`}
                style={{ width: "15ch" }}
              >
                Statement Colour
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div
                className={`${classes.headingColor} ${classes.stickyHead}`}
                style={{ width: "50ch" }}
              >
                Implication/Assessment
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {props.content.config.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.statementColour}</StyledTableCell>
                <StyledTableCell>{row.Implication}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
      </Table>
    </TabContainer>
  );
}
ESG_MaturityMatrixPreviewLegend.propTypes = {
  content: PropTypes,
};

export default ESG_MaturityMatrixPreviewLegend;
