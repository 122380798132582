import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import TableContainer from "@material-ui/core/TableContainer";
import React, { useEffect, useState } from "react";
import {
  getAllPillar,
  getInvestmentByCrmId,
  getRespondentActionPlanBySurveyIdAndInvesmentId,
  getSubPillar,
  getSurveyBySurveyId,
  updateAllRespondentActionPlan,
} from "../ESG_API";
import { makeStyles, withStyles } from "@material-ui/styles";
import { SurveyFilter, SurveyFilterYear } from "../components";
import CloseIcon from "@material-ui/icons/Close";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { useParams } from "react-router";
import ActionPlanPopUp from "./ActionPlanPopUp";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { Alert } from "@material-ui/lab";
import { SurveyStatusMap } from "../ESG_Config";
import { addHeaderHightForESGPages } from "../utils";
import { useDispatch } from "react-redux";
import { getCurrentEventValue } from "../../../../../../store/actions/getEventValueAction";
const useStyles = makeStyles((theme) => ({
  table: {
    position: "relative",
  },
  stickyHead: {
    zIndex: 0,
    position: "sticky",
    top: "0px",
  },
  pillar: {
    color: "white",
    background: "rgba(38,52,64,1)",
    textDecoration: "none",
    border: "1px solid white !important",
    width: "120px",
  },
  removeBoder: {
    textDecoration: "none",
    border: "0px",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
  loader: {
    position: "absolute",
    left: "50%",
  },
  textbox: {
    resize: "none !important",
    border: "1px solid #ccc",
    width: "100%",
    padding: "4px 8px",
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
    },
  },
  number: {
    fontSize: "16px",
    fontWeight: "600",
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    position: "absolute",
    margin: "6px",
    left: "50%",
    zIndex: "1",
    textAlign: "center",
  },
  color: {
    backgroundColor: theme.palette.action.hover,
  },
  white: {
    backgroundColor: "white",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    textDecoration: "none",
    border: "0px",
    padding: "10px",
  },
}))(TableCell);

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

function ESG_RespondentActionPlan(props) {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  const classes = useStyles();
  const [allPillars, setAllPillars] = useState([]);
  const [allSubPillars, setAllSubPillars] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [actionPlanData, setActionPlanData] = useState([]);
  const [investmentId, setInvestmentid] = useState(null);
  const [successPost, setSuccessPost] = React.useState(false);
  const [failure, setFailure] = useState(false);
  const { crmId } = useParams();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [bestPracticeError, setBestPracticeError] = useState({});
  const [surveyFreeze, setSurveyFreeze] = useState(true);
  const [alertvalidation, setAlertvalidation] = useState(false);
  // for pop-up screen
  const [selectedRow, setSelectedRow] = useState(null);
  const [reloadCheck, setReloadCheck] = useState(false);

  const dispatch = useDispatch();
  const [checkCurrentPageBool, setCheckCurrentPageBool] = useState(true);

  const openRatingForm = async (subPilllarData) => {
    setOpenDialog(true);
    setPopUpTitle(subPilllarData.subpillarName);
    setSelectedRow(subPilllarData);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handlepopClose = () => {
    setSuccessPost(false);
  };

  const setSubPillarScoreData = (subPillarData, scoreData) => {
    const data = [];
    subPillarData.forEach((subPillar) => {
      const findScoreData = scoreData.find(
        (item) => subPillar.questionsubcategoryid === item.questionsubcategoryId
      );
      if (findScoreData && findScoreData !== null) {
        data.push({
          questioncategoryid: subPillar.questioncategoryid,
          questionsubcategoryid: subPillar.questionsubcategoryid,
          subpillarName: subPillar.name,
          scoreId: findScoreData.scoreId,
          portfolioInvestmentId: findScoreData.portfolioinvestmentId,
          systemGeneratedScore: findScoreData.systemGeneratedScore,
          reviewedScore: findScoreData.reviewedScore,
          bestpractices: findScoreData.bestpractices,
          initiatives: findScoreData.initiatives,
          targets: findScoreData.targets,
        });
      }
    });
    return data;
  };

  const mergeByPillars = (pillarsData, scoreData) => {
    const finalData = [];
    pillarsData.forEach((pillar) => {
      const subPillarsForPillar = scoreData.filter(
        (subPillar) =>
          subPillar.questioncategoryid === pillar.questioncategoryid
      );
      if (subPillarsForPillar && subPillarsForPillar.length !== 0) {
        const data = {
          questioncategoryid: pillar.questioncategoryid,
          pillarName: pillar.name,
          subPillarScoreData: [...subPillarsForPillar],
        };
        finalData.push(data);
      }
    });
    setFinalData(finalData);
  };

  const esgRatingDataShow = () => {
    setReloadCheck(false);
    setLoading(true);
    const apiCalls = async () => {
      // check surveyStatus
      const surveyData = await getSurveyBySurveyId(selectedSurveyId);
      setSurveyFreeze(
        surveyData.status === SurveyStatusMap.ScoreFreeze ? true : false
      );
      const investmentid = await getInvestmentByCrmId(crmId);
      const pillars = await getAllPillar();
      setAllPillars(pillars);
      const subpillars = await getSubPillar();
      subpillars.sort((a, b) => a.name.localeCompare(b.name));
      setAllSubPillars(subpillars);
      const allData = await getRespondentActionPlanBySurveyIdAndInvesmentId(
        selectedSurveyId,
        investmentid.investmentId
      );
      if (
        allData &&
        allData.data &&
        allData.data.length > 0 &&
        subpillars.length !== 0 &&
        pillars.length !== 0
      ) {
        setActionPlanData(allData.data);
        // set payload
        setPayload(allData.data);
        setInvestmentid(investmentid.investmentId);
        setBestPracticeError({});
        const getSubPillarScoreData = setSubPillarScoreData(
          subpillars,
          allData.data
        );
        if (getSubPillarScoreData?.length !== 0) {
          mergeByPillars(pillars, getSubPillarScoreData);
        } else {
          setFinalData([]);
          setPayload(null);
        }
      } else {
        setPayload(null);
        setInvestmentid(null);
        setActionPlanData([]);
        setFinalData([]);
        setBestPracticeError({});
      }
      setLoading(false);
    };
    if (selectedSurveyId) {
      apiCalls();
    }
  };

  useEffect(() => {
    esgRatingDataShow();
  }, [selectedSurveyId]);

  useEffect(() => {
    if (reloadCheck) {
      esgRatingDataShow();
    }
  }, [reloadCheck]);

  useEffect(() => {
    let commonFilter = window.document.getElementsByClassName(
      "MuiFormControl-root"
    );
    let titleHeading = window.document.getElementById("PageTitleWrapper");
    titleHeading.style.marginTop = "-0.4em";
    if (commonFilter[0] && commonFilter[0].classList.length > 1) {
      commonFilter[0].style.visibility = "hidden";
    }
  });
  const handleChange = (value, item, scoreId) => {
    dispatch(getCurrentEventValue(setCheckCurrentPageBool));
    // disable save
    setShowSaveButton(true);
    // create payload
    setPayload((prev) => {
      const newPayload = [...prev];
      const existingPayloadIndex = newPayload?.findIndex(
        (obj) => obj.scoreId === scoreId
      );
      // change from payload
      if (existingPayloadIndex !== -1) {
        newPayload[existingPayloadIndex][item] = value;
      }
      return newPayload;
    });
  };

  const handleSubmit = async () => {
    // disable save
    setShowSaveButton(false);
    const finalPayload = [];
    payload.forEach((item) => {
      finalPayload.push({
        scoreId: item.scoreId,
        targets: item.targets,
        initiatives: item.initiatives,
        bestpractices: item.bestpractices,
      });
    });
    const response = await updateAllRespondentActionPlan(finalPayload);
    if (response && response.statusCode != 200) {
      setFailure(true);
      setTimeout(() => {
        setFailure(false);
      }, 2000);
    } else {
      setReloadCheck(true);
      setSuccessPost(true);
      setTimeout(() => {
        setSuccessPost(false);
      }, 2000);
    }
  };

  let isLightBlue = false;

  useEffect(() => {
    setTimeout(() => {
      setAlertvalidation(false);
    }, [3000]);
  }, [alertvalidation]);

  useEffect(() => {
    const cleanupFunction = addHeaderHightForESGPages();
    return cleanupFunction;
  }, []);

  return (
    <>
      <div>
        <SurveyFilter
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          surveyClosetoggle={true}
          heading={"Closed Survey"}
          style={{ styleTop: "112px" }}
        />
      </div>
      <div>
        <SurveyFilterYear
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          setSurveyFilterYear={setSurveyFilterYear}
          surveyClosetoggle={true}
          heading={"Survey Years"}
          style={{ styleTop: "112px" }}
        />
      </div>
      {!loading && actionPlanData.length > 0 ? (
        <>
          {" "}
          {surveyFreeze ? (
            <Alert
              severity="error"
              style={{
                width: "auto",
                marginBottom: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Survey Rating is finalized and hence not editable now.
            </Alert>
          ) : (
            ""
          )}
          <TableContainer component={Paper}>
            <Table aria-label="customized table" className={classes.table}>
              <TableHead style={{ background: "grey" }}>
                <TableRow>
                  <StyledTableCell>
                    <div
                      className={classes.stickyHead}
                      style={{ width: "15ch" }}
                    >
                      {" "}
                      Pillar
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      className={classes.stickyHead}
                      style={{ width: "25ch" }}
                    >
                      {" "}
                      Sub Pillar
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{ width: "40ch" }}
                      className={classes.stickyHead}
                    >
                      {" "}
                      Target looking forward
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{ width: "40ch" }}
                      className={classes.stickyHead}
                    >
                      {" "}
                      Initiatives looking forward
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        width: "40ch",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={classes.stickyHead}
                    >
                      {props.content.showInfoIcon && (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              {
                                "Practices in the reporting year are prefilled based on your questionnaire responses. These can be amended to summarise key initiatives and targets."
                              }
                            </React.Fragment>
                          }
                          arrow
                        >
                          <InfoIcon
                            style={{ color: "black", marginRight: "5px" }}
                          />
                        </HtmlTooltip>
                      )}
                      <span>Practices in the reporting year</span>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "auto" }}>
                {finalData &&
                  finalData.map((pillarData, idx) => {
                    isLightBlue = !isLightBlue; // toggell color
                    return (
                      <React.Fragment key={idx}>
                        <StyledTableRow
                          className={`${
                            isLightBlue ? classes.color : classes.white
                          }`}
                          key={`idx${pillarData.questioncategoryid}`}
                        >
                          <StyledTableCell
                            className={classes.pillar}
                            rowSpan={pillarData.subPillarScoreData.length}
                          >
                            {pillarData.pillarName}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <span
                              onClick={() =>
                                openRatingForm(
                                  pillarData?.subPillarScoreData[0]
                                )
                              }
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              {pillarData.subPillarScoreData[0].subpillarName}
                            </span>
                          </StyledTableCell>

                          <StyledTableCell>
                            <TextField
                              id="outlined-multiline-static"
                              InputProps={{ disableUnderline: true }}
                              minRows={3}
                              maxRows={3}
                              multiline
                              className={classes.textbox}
                              defaultValue={
                                pillarData.subPillarScoreData[0]?.targets
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.value,
                                  "targets",
                                  pillarData.subPillarScoreData[0]?.scoreId
                                )
                              }
                              disabled={surveyFreeze}
                            />
                            <div
                              style={{
                                color: "red",
                                fontSize: "13px",
                                textAlign: "center",
                                height: "3ch",
                              }}
                            >
                              {""}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell component="th">
                            <TextField
                              id="outlined-multiline-static"
                              InputProps={{ disableUnderline: true }}
                              minRows={3}
                              maxRows={3}
                              multiline
                              className={classes.textbox}
                              defaultValue={
                                pillarData.subPillarScoreData[0]?.initiatives
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.value,
                                  "initiatives",
                                  pillarData.subPillarScoreData[0]?.scoreId
                                )
                              }
                              disabled={surveyFreeze}
                            />
                            <div
                              style={{
                                color: "red",
                                fontSize: "13px",
                                textAlign: "center",
                                height: "3ch",
                              }}
                            >
                              {""}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell component="th">
                            <TextField
                              id="outlined-multiline-static"
                              InputProps={{ disableUnderline: true }}
                              minRows={3}
                              maxRows={3}
                              multiline
                              className={classes.textbox}
                              defaultValue={
                                pillarData.subPillarScoreData[0].bestpractices
                              }
                              onChange={(e) =>
                                handleChange(
                                  e.target.value,
                                  "bestpractices",
                                  pillarData.subPillarScoreData[0]?.scoreId
                                )
                              }
                              disabled={surveyFreeze}
                            />
                            <div
                              style={{
                                color: "red",
                                fontSize: "13px",
                                textAlign: "center",
                                height: "3ch",
                              }}
                            >
                              {bestPracticeError[
                                pillarData.subPillarScoreData[0]?.scoreId
                              ]
                                ? bestPracticeError[
                                    pillarData.subPillarScoreData[0]?.scoreId
                                  ]
                                : ""}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>

                        {pillarData.subPillarScoreData.slice(1).length > 0 &&
                          pillarData.subPillarScoreData
                            .slice(1)
                            .map((scoreData) => {
                              return (
                                <StyledTableRow
                                  className={`${
                                    isLightBlue ? classes.color : classes.white
                                  }`}
                                >
                                  <StyledTableCell component="th">
                                    <span
                                      onClick={() => openRatingForm(scoreData)}
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                    >
                                      {scoreData.subpillarName}
                                    </span>
                                  </StyledTableCell>

                                  <StyledTableCell component="th">
                                    <TextField
                                      id="outlined-multiline-static"
                                      InputProps={{ disableUnderline: true }}
                                      minRows={3}
                                      maxRows={3}
                                      multiline
                                      className={classes.textbox}
                                      defaultValue={scoreData?.targets}
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          "targets",
                                          scoreData?.scoreId
                                        )
                                      }
                                      disabled={surveyFreeze}
                                    />
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                        textAlign: "center",
                                        height: "3ch",
                                      }}
                                    >
                                      {""}
                                    </div>
                                  </StyledTableCell>

                                  <StyledTableCell component="th">
                                    <TextField
                                      id="outlined-multiline-static"
                                      InputProps={{ disableUnderline: true }}
                                      minRows={3}
                                      maxRows={3}
                                      multiline
                                      className={classes.textbox}
                                      defaultValue={scoreData?.initiatives}
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          "initiatives",
                                          scoreData?.scoreId
                                        )
                                      }
                                      disabled={surveyFreeze}
                                    />
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                        textAlign: "center",
                                        height: "3ch",
                                      }}
                                    >
                                      {""}
                                    </div>
                                  </StyledTableCell>

                                  <StyledTableCell component="th">
                                    <TextField
                                      id="outlined-multiline-static"
                                      InputProps={{ disableUnderline: true }}
                                      minRows={3}
                                      maxRows={3}
                                      multiline
                                      className={classes.textbox}
                                      defaultValue={scoreData?.bestpractices}
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          "bestpractices",
                                          scoreData?.scoreId
                                        )
                                      }
                                      disabled={surveyFreeze}
                                      // required={!scoreData?.bestpractices}
                                    />
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                        textAlign: "center",
                                        height: "3ch",
                                      }}
                                    >
                                      {bestPracticeError[scoreData?.scoreId]
                                        ? bestPracticeError[scoreData?.scoreId]
                                        : ""}
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {alertvalidation == true && (
            <Alert severity="error" className={classes.errorMessage}>
              <strong>Please fill out the inputs</strong>
            </Alert>
          )}
          <A_Button
            label="Save"
            color="primary"
            style={{ position: "absolute", right: "50px", margin: "5px" }}
            disabled={showSaveButton ? false : true}
            onClick={handleSubmit}
          />
        </>
      ) : loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress
            className={classes.loader}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      ) : !selectedSurveyId ? (
        <Alert severity="info">
          All active surveys are either live or unpublished.
        </Alert>
      ) : (
        <Alert severity="info">
          There is no response to show,no Rating questions are found for this
          survey.
        </Alert>
      )}
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="xl"
        PaperProps={{ style: { width: "100%" } }}
        aria-labelledby="max-width-dialog-title"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <div className={classes.addForm}>
          <div>
            <DialogTitle>{popUpTitle}</DialogTitle>
          </div>
          <div>
            <CloseIcon onClick={() => handleClose()} className="closeIcon" />
          </div>
        </div>
        <ActionPlanPopUp
          selectedSurveyId={selectedSurveyId}
          investmentId={investmentId}
          selectedRow={selectedRow}
          onFormClose={handleClose}
        />
      </Dialog>
      <A_Snackbar
        open={successPost}
        message={"Data saved successfully"}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handlepopClose}
      />
      <A_Snackbar
        open={failure}
        type="error"
        message={"Some issue occured while saving data"}
        autoHideDuration={2000}
        horizontal="center"
      />
    </>
  );
}
ESG_RespondentActionPlan.propTypes = {
  content: PropTypes,
};

export default ESG_RespondentActionPlan;
