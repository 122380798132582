import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagination from "@material-ui/lab/Pagination";
import { getAllGroups } from "./UserManagementHelper";
import GroupDashboard from "./GroupDashboard";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";
import { message } from "../../../../../Utils/config/messages";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    padding: theme.spacing(1),
    boxSizing: "border-box",
  },
  main: {
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    margin: 0,
    fontSize: "20px",
    color: "#34495e",
  },
  headerSubtitle: {
    margin: "5px 0 0",
    color: "#7f8c8d",
  },
  content: {
    backgroundColor: "#ecf0f1",
    padding: theme.spacing(2),
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  contentTitle: {
    margin: "0 0 10px 0",
    color: "#2980b9",
    fontSize: "22px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
  },
  searchContainer: {
    marginRight: "3rem",
    backgroundColor: "white",
    borderRadius: "25px",
    width: "100%",
    maxWidth: "25rem",
    marginBottom: theme.spacing(0.5),
  },
  searchIcon: {
    position: "absolute",
    left: theme.spacing(1),
    fontSize: "14px",
    color: "#7f8c8d",
  },
  searchBar: {
    width: "100%",
    padding: theme.spacing(0.5, 0.5, 0.5, 3.5),
    fontSize: "14px",
  },
  addUserButton: {
    padding: "6px 10px 6px 10px",
    backgroundColor: "#3498db",
    color: "white",
    borderRadius: "5px",
    fontSize: "14px",
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "#2980b9",
    },
  },
  userTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: theme.spacing(2),
  },
  userTableHeader: {
    backgroundColor: "#ecf0f1",
    fontWeight: "bold",
  },
  userTableCellHeader: {
    color: "#7F8C8D",
    fontWeight: "bold",
  },
  userInfo: {
    fontWeight: "bold",
  },
  userEmail: {
    color: "#7f8c8d",
    fontSize: "14px",
  },
  role: {
    display: "inline-block",
    padding: "5px 10px",
    borderRadius: "12px",
    color: "white",
    fontSize: "14px",
  },
  superAdminRole: {
    backgroundColor: "#2980b9",
  },
  adminRole: {
    backgroundColor: "#27ae60",
  },
  employeeRole: {
    backgroundColor: "#95a5a6",
  },
  actionButtons: {
    fontSize: "18px",
    padding: "5px",
  },
  editButton: {
    color: "#2980b9",
    "&:hover": {
      color: "#1true9c",
    },
  },
  deleteButton: {
    color: "#e74c3c",
    "&:hover": {
      color: "#c0392b",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "right",
    margin: theme.spacing(2),
  },
}));

function M_UserGroupsManagement(props) {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [seletedGroupData, setSelectedGroupData] = useState({});
  const [userNavigateActive, setUserNavigateActive] = useState(false);
  let header = props?.content?.item?.header?.title || "Account Management";

  async function fetchData() {
    try {
      setLoading(true);
      let data = await getAllGroups();
      setGroups(data ? data : []);
      setLoading(false);
    } catch (error) {
      setGroups([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const filteredGroups = groups?.filter((list) => {
    const { name, description } = list;
    const lowerCaseSearchTerm = searchTerm?.trim().toLowerCase();
    return (
      name?.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      description?.trim().toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const paginationCount = Math.ceil(filteredGroups.length / rowsPerPage);

  const indexOfLastUser = page * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentGroups = filteredGroups?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSelcetdGroup = (user) => {
    let data = groups.filter((item) => item.id == user.id);
    setSelectedGroupData(data[0]);
    setUserNavigateActive(!userNavigateActive);
  };
  if (userNavigateActive)
    return (
      <ErrorBoundary>
        <GroupDashboard
          data={seletedGroupData}
          UserNavigateActive={setUserNavigateActive}
          header={header}
        />
      </ErrorBoundary>
    );
  return (
    <div className={classes.dashboard}>
      <div className={classes.main}>
        <div className={classes.content}>
          {/* <Typography variant="h6" className={classes.contentTitle}>
            Group Dashboard
          </Typography> */}
          <div className={classes.actions}>
            <div className={classes.searchContainer}>
              <InputBase
                placeholder=" Search"
                classes={{
                  root: classes.searchBar,
                }}
                value={searchTerm}
                onChange={handleSearch}
                startAdornment={
                  <Search
                    fontSize={"small"}
                    style={{ margin: "0 0 2px 5px" }}
                    className={classes.searchIcon}
                  />
                }
              />
            </div>
          </div>
          {loading ? (
            <>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </>
          ) : !currentGroups ? (
            <Typography variant="h6">{message.NO_CONTENT}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.userTable} size={"small"}>
                <TableHead className={classes.userTableHeader}>
                  <TableRow>
                    <TableCell className={classes.userTableCellHeader}>
                      Name
                    </TableCell>
                    <TableCell className={classes.userTableCellHeader}>
                      Description
                    </TableCell>
                    <TableCell className={classes.userTableCellHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentGroups.length > 0 ? (
                    currentGroups.map((group) => (
                      <TableRow key={group.id}>
                        <TableCell>
                          <span>{group?.name}</span>
                        </TableCell>
                        <TableCell>
                          <span>{group?.description}</span>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleSelcetdGroup(group)}
                            className={`${classes.actionButtons} ${classes.editButton}`}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell>
                      <span>{message.NO_DATA_FOUND}</span>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <div className={classes.pagination}>
                <Pagination
                  count={paginationCount}
                  page={page}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
}

M_UserGroupsManagement.propTypes = {
  setToast: PropTypes.func,
  content: PropTypes.any,
};

export default M_UserGroupsManagement;
