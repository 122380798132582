import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  makeStyles,
  createStyles,
  Tab,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import {
  getAllPillar,
  getAllSubPillar,
  getInvestmentByCrmId,
  getResponseToQuestion,
  getQuestionsJsonFromSurvey,
} from "../ESG_API";
import SubmissionForm from "./SubmissionForm";
import { SurveyFilter, SurveyFilterYear } from "../components";
import { Alert } from "@material-ui/lab";
import { customDateFormat } from "../../../../../../Utils/Helpers/dateUtils";
import { convertToDate } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPillar } from "../../../../../../store/actions/getpillarnameActions";

const useStyles = makeStyles((theme) =>
  createStyles({
    tabHeader: {
      backgroundColor: "#eeeeee",
      "&.Mui-selected": {
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    dialog: {
      width: "100%",
    },
    addForm: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EsgView = () => {
  const classes = useStyles();
  const [pillar, setPillar] = useState([]);
  const [subPillar, setSubPillar] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [isSurveyExpired, setIsSurveyExpired] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [publishedtoggle, setPublishedToggle] = useState(true);
  const [questionsSurvey, setQuestionsSurvey] = useState([]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [child, setChild] = useState(false);
  const [refraceData, setRefraceData] = useState(false);
  let { crmId } = useParams();
  const pillardata = useSelector((state) => state.getpillarfromstore);
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(false);

  const handleCellClick = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleTabClick = (tabValue) => {
    if (pillar[tabValue]) {
      // const selectedTabLabel = selectedTabFind[0].name;
      // const shortLabel = selectedTabLabel[0].split(" ")[0];
      getSubpillarWithPillar(pillar[tabValue].questioncategoryid);
      setActiveTab(tabValue);
    }
  };

  const buildRows = (subpillars, questionsSurveyPassed, answersPassed) => {
    let questions;
    let answer;
    if (!questionsSurveyPassed && !answersPassed) {
      questions = questionsSurvey;
      answer = responses;
    } else {
      questions = questionsSurveyPassed;
      answer = answersPassed;
    }
    const rows = [];
    if (subpillars && subpillars.length > 0) {
      subpillars.forEach((obj) => {
        let questionSubpillar = [];
        let responseSubPillar = [];
        questions.forEach((question) => {
          if (question.questionSubCategoryId === obj.questionsubcategoryid) {
            questionSubpillar.push(question);
          }
        });
        answer.forEach((response) => {
          questionSubpillar.forEach((ques) => {
            if (response.questionId === ques.questionId) {
              responseSubPillar.push(response);
            }
          });
        });
        let percent = Math.floor(
          (responseSubPillar.length / questionSubpillar.length) * 100
        );
        rows.push({ ...obj, completion: `${percent || 0}%` });
      });
      setRows(rows);
    } else {
      setRows(rows);
    }
  };

  const getPillarData = async (questionsSurvey, answers) => {
    const pillarData = await getAllPillar();
    if (pillarData && pillarData.length > 0 && pillarData[0].name) {
      setPillar(pillarData);
      setActiveTab(activeTab);
      let activeSubPillarId = pillarData[activeTab].questioncategoryid;
      getSubpillarWithPillar(activeSubPillarId, questionsSurvey, answers);
    }
    setLoading(false);
  };

  const getSubpillarWithPillar = async (pillarId, questionsSurvey, answers) => {
    const subpillarData = await getAllSubPillar(pillarId);
    if (subpillarData && subpillarData.length > 0) {
      setSubPillar(subpillarData);
      buildRows(subpillarData, questionsSurvey, answers);
    } else {
      buildRows(subpillarData, questionsSurvey, answers);
    }
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getTabWithStoredPillar = (name) => {
    if (name !== "") {
      setRefraceData(true);
      let tabname = pillar.find((item) => item.name == name);
      // console.log("hi",tabname);
      getSubpillarWithPillar(tabname.questioncategoryid);
      setActiveTab(tabname.questioncategoryid - 1);
      dispatch(getCurrentPillar(""));
    }
  };
  useEffect(() => {
    let commonFilter = window.document.getElementsByClassName(
      "MuiFormControl-root"
    );
    let titleHeading = window.document.getElementById("PageTitleWrapper");
    titleHeading.style.marginTop = "-0.4em";
    if (commonFilter[0] && commonFilter[0].classList.length > 1) {
      commonFilter[0].style.visibility = "hidden";
    }
  });

  useEffect(() => {
    setResponses([]);
    setQuestionsSurvey([]);

    let surveys = JSON.parse(localStorage.getItem("AllSurvey"));
    let currentSurvey =
      surveys && surveys.find((item) => item.surveyId == selectedSurveyId);

    if (currentSurvey) {
      let endDate = customDateFormat(currentSurvey.endDate, "DD/MM/YYYY");
      let currentDate = customDateFormat(new Date(), "DD/MM/YYYY");
      endDate = convertToDate(endDate);
      currentDate = convertToDate(currentDate);
      setIsSurveyExpired(currentDate > endDate);
    }

    const setInvestmentId = async () => {
      setLoading(true);
      const data = await getInvestmentByCrmId(crmId);
      if (data && data.investmentId) {
        localStorage.setItem("investment", JSON.stringify(data.investmentId));
      }
      let questionsSurvey = [];
      let answers = [];
      if (selectedSurveyId) {
        questionsSurvey = await getQuestionsJsonFromSurvey(selectedSurveyId);
        answers = await getResponseToQuestion(
          selectedSurveyId,
          data.investmentId
        );
      }
      if (questionsSurvey && questionsSurvey.length) {
        getPillarData(questionsSurvey, answers);
        setQuestionsSurvey(questionsSurvey);
      }
      if (answers && answers.length) {
        setResponses(answers);
      }
    };
    setInvestmentId();
  }, [selectedSurveyId, child]);

  useEffect(() => {
    if (pillar.length > 0) {
      getTabWithStoredPillar(pillardata);
    }
  }, [pillar]);
  useEffect(() => {
    if (isEmpty) {
      setLoading(false);
    }
  }, [isEmpty]);

  return (
    <div>
      <SurveyFilter
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        publishedtoggle={publishedtoggle}
        setIsEmpty={setIsEmpty}
      />
      <SurveyFilterYear
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        setSurveyFilterYear={setSurveyFilterYear}
        publishedtoggle={publishedtoggle}
      />
      {!filtersurveyyear && !loading ? (
        <Alert
          severity="info"
          style={{
            width: "auto",
            marginBottom: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          All surveys are closed.
        </Alert>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            {isSurveyExpired && !loading && (
              <Alert
                severity="error"
                style={{
                  width: "auto",
                  marginBottom: "10px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                The questionnaire submission date has passed. Response
                submission is no longer available.
              </Alert>
            )}
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(event, newValue) => handleTabClick(newValue)}
                  className={classes.tabHeader}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {pillar &&
                    pillar.map((tab) => (
                      <Tab
                        key={tab.questioncategoryid}
                        label={tab.name}
                        className={classes.tabHeader}
                        {...a11yProps(tab.questioncategoryid - 1)}
                      />
                    ))}
                </TabList>
              </Box>
              {pillar &&
                pillar.map((tab) => (
                  <TabPanel
                    key={tab.questioncategoryid}
                    value={tab.questioncategoryid - 1}
                  >
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              style={{
                                background: "black",
                                color: "white",
                              }}
                            >
                              Section Title
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                background: "black",
                                color: "white",
                              }}
                            >
                              % Completed
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={{ borderBottom: "1px solid gray" }}
                              >
                                {isSurveyExpired ? (
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#8797b2",
                                      paddingLeft: "16px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {" "}
                                    {row.name}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#3b82f6",
                                      paddingLeft: "16px",
                                      fontWeight: 500,
                                    }}
                                    onClick={() => handleCellClick(row)}
                                  >
                                    {" "}
                                    {row.name}
                                  </div>
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ borderBottom: "1px solid gray" }}
                              >
                                {row.completion}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                ))}
            </TabContext>
          </Box>
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth="xl"
            PaperProps={{ style: { width: "100%" } }}
            aria-labelledby="max-width-dialog-title"
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
          >
            <div className={classes.addForm}>
              <div>
                <DialogTitle>{selectedRow && selectedRow.name}</DialogTitle>
              </div>
              <div>
                <CloseIcon
                  onClick={() => handleClose()}
                  className="closeIcon"
                />
              </div>
            </div>
            <SubmissionForm
              selectedRow={selectedRow}
              answers={responses}
              onFormClose={handleClose}
              setChild={setChild}
              child={child}
            />
          </Dialog>
        </>
      )}
    </div>
  );
};

export default EsgView;
