import React, { useState, useEffect } from "react";
import {
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tabs,
  Tab,
  AppBar,
  TablePagination,
} from "@material-ui/core";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import MasterMappingForm from "./MasterMappingForm";
import AddIcon from "@material-ui/icons/Add";
import * as common from "../../../../../../Utils/Helpers/common";
import { getDataFromSQLSp } from "../../../../Molecules/Common/File Upload/M_fileUpload.helper";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PropTypes from "prop-types";
import { getCRMContactInfo } from "../../../../../../Utils/Helpers/common";
import * as XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    color: "rgb(25, 118, 210)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  tabHeader: {
    backgroundColor: "#d3d3d3",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const MasterMappingData = (props) => {
  const classes = useStyles();
  const title = props.content && props.content.title ? props.content.title : "";
  const { crmContactUserEmail } = getCRMContactInfo();
  let validUsers =
    props.content && props.content.ValidUsers ? props.content.ValidUsers : [];
  const Pagination =
    props.content && props.content.Pagination ? props.content.Pagination : 25;
  const order = "asc";
  const orderBy = "";
  const [rows, setRows] = useState([]);
  const showButton = validUsers.includes(crmContactUserEmail);
  const [rowsPerPage, setRowsPerPage] = React.useState(Pagination);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [isFormVisible, setFormVisible] = useState(false);
  const [bool, setBool] = useState(false);
  const [GDataset, setGData] = useState([]);
  const [UPDataset, setUPData] = useState([]);
  const [VEDataset, setVEData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};
  const [Icon, setIcon] = React.useState({
    Code: "down",
    Label: "down",
    Category1: "down",
    Category2: "down",
    Category3: "down",
  });
  const [formData, setFormData] = useState({
    Code: "",
    Label: "",
    Category1: "",
    Category2: "",
    Category3: "",
    KTCodeMicroCategoryID: "",
  });

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    let GData = JSON.parse(JSON.stringify(GDataset));
    let UPData = JSON.parse(JSON.stringify(UPDataset));
    let VEData = JSON.parse(JSON.stringify(VEDataset));
    if (GData && GData.length > 0) {
      for (let i = 0; i < GData.length; i++) {
        delete GData[i].KTCodeMicroCategoryID;
      }
      const GWorkSheet = XLSX.utils.json_to_sheet(GData);
      XLSX.utils.book_append_sheet(workbook, GWorkSheet, "G");
    }
    if (UPData && UPData.length > 0) {
      for (let i = 0; i < UPData.length; i++) {
        delete UPData[i].KTCodeMicroCategoryID;
      }
      const UPWorkSheet = XLSX.utils.json_to_sheet(UPData);
      XLSX.utils.book_append_sheet(workbook, UPWorkSheet, "UP");
    }
    if (VEData && VEData.length > 0) {
      for (let i = 0; i < VEData.length; i++) {
        delete VEData[i].KTCodeMicroCategoryID;
      }
      const VEWorkSheet = XLSX.utils.json_to_sheet(VEData);
      XLSX.utils.book_append_sheet(workbook, VEWorkSheet, "VE");
    }

    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  const getTableData = async () => {
    setLoading(true);
    try {
      let query = "res";
      let spResponse = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "JLCHART.MASTERDATA",
            parameters: {},
          },
        ],
      });
      setRows(spResponse.res ? spResponse.res : []);
      const pattern1 = /([G|g])\w+/g;
      const pattern2 = /([UP|up])\w+/g;
      const pattern3 = /([VE|ve])\w+/g;
      let GData = spResponse.res.filter((item) => {
        let data = item.Code.match(pattern1);
        return data;
      });
      let UPData = spResponse.res.filter((item) => {
        let data = item.Code.match(pattern2);
        return data;
      });
      let VEData = spResponse.res.filter((item) => {
        let data = item.Code.match(pattern3);
        return data;
      });
      setGData(GData || []);
      setUPData(UPData || []);
      setVEData(VEData || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, []);

  const EmptyUpdate = () => {
    setFormData({
      Code: "",
      Label: "",
      Category1: "",
      Category2: "",
      Category3: "",
      KTCodeMicroCategoryID: "",
    });
  };

  const handleAddRow = () => {
    EmptyUpdate();
    setFormVisible(false);
  };

  const handleOpenForm = () => {
    EmptyUpdate();
    setBool(false);
    setFormVisible(true);
  };

  const handleCloseForm = (flag) => {
    if (flag) {
      setFormVisible(false);
      getTableData();
    } else {
      setFormVisible(false);
    }
  };

  const [anchorElList, setAnchorElList] = useState(
    Array(rows.length).fill(null)
  );

  const handleMenuClose = (index) => {
    const newAnchorElList = [...anchorElList];
    newAnchorElList[index] = null;
    setAnchorElList(newAnchorElList);
  };

  const handleEditRow = (index) => {
    const getObjectById = (categoryId, data) =>
      data.find((entry) => entry.KTCodeMicroCategoryID === categoryId);
    const resultObject = getObjectById(index, rows);
    const selectedRow = resultObject;
    setBool(true);
    setFormData({
      Code: selectedRow.Code,
      Label: selectedRow.Label,
      Category1: selectedRow.Category1,
      Category2: selectedRow.Category2,
      Category3: selectedRow.Category3,
      KTCodeMicroCategoryID: selectedRow.KTCodeMicroCategoryID,
    });
    setFormVisible(true);
    handleMenuClose();
  };
  function compareValues(value1, value2) {
    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }
    return 0;
  }
  const getSortedRow = (id, dir, type, dataValues) => {
    let data = [...dataValues];
    if (data.length > 0) {
      data.sort((a, b) => {
        let first = a[id];
        let second = b[id];
        if (typeof first === "string") {
          first = first.toLowerCase();
          second = second.toLowerCase();
        }

        if (dir === "down") {
          return compareValues(second, first);
        } else {
          return compareValues(first, second);
        }
      });

      let arrowDir = dir == "down" ? "up" : "down";
      let obj = { ...Icon, [id]: arrowDir };
      setIcon(obj);
      if (type === "G") {
        setGData([...data] || []);
      }
      if (type === "UP") {
        setUPData([...data] || []);
      }
      if (type === "VE") {
        setVEData([...data] || []);
      }
    }
  };
  const DirectionArrow = (id, dir, type, dataValues) => {
    return dir === "down" ? (
      <ArrowDownwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, "down", type, dataValues)}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, "up", type, dataValues)}
      />
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;

      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const handleTabChange = (event, index) => {
    setGData([...GDataset]);
    setUPData([...UPDataset]);
    setVEData([...VEDataset]);
    setPage(0);
    setCurrentTab(index);
    setLoading(false);
  };
  const renderPagingnation = (filterData) => {
    let data = [...filterData];
    return data && data.length > 5 ? (
      <div className={classes.root}>
        <TablePagination
          variant="outlined"
          shape="rounded"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    ) : (
      ""
    );
  };
  const renderChart = (tabData, key) => {
    return (
      <Table className={classes.table} aria-Label="simple table">
        <TableHead>
          <TableRow style={{ background: style.background }}>
            <StyledTableCell>
              <div className={classes.flexContainer}>
                Code {DirectionArrow("Code", Icon["Code"], key, tabData)}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={classes.flexContainer}>
                Label {DirectionArrow("Label", Icon["Label"], key, tabData)}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={classes.flexContainer}>
                Category 1{" "}
                {DirectionArrow("Category1", Icon["Category1"], key, tabData)}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={classes.flexContainer}>
                Category 2{" "}
                {DirectionArrow("Category2", Icon["Category2"], key, tabData)}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div className={classes.flexContainer}>
                Category 3 {DirectionArrow("Category3", Icon["Category3"], key,tabData)}
              </div>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabData.length > 0 ? (
            stableSort(tabData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.KTCodeMicroCategoryID}>
                  <TableCell>{row.Code}</TableCell>
                  <TableCell>{row.Label}</TableCell>
                  <TableCell>{row.Category1}</TableCell>
                  <TableCell>{row.Category2}</TableCell>
                  <TableCell>{row.Category3}</TableCell>
                  <TableCell>
                    {showButton && (
                      <Tooltip title="Edit" aria-label="Edit" placement="right">
                        <EditIcon
                          key={row.KTCodeMicroCategoryID}
                          id={row.KTCodeMicroCategoryID}
                          onClick={() =>
                            handleEditRow(row.KTCodeMicroCategoryID)
                          }
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };
  return (
    <div>
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <span
          style={{
            display: "flex",
            width: showButton ? "65px" : "30px",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          {showButton && (
            <Tooltip title="Add" aria-label="Add">
              <AddIcon
                onClick={handleOpenForm}
                style={{ color: "rgb(25, 118, 210)" }}
              />
            </Tooltip>
          )}
          <Tooltip title="Download Excel" aria-label="Download Excel">
            <GetAppIcon
              onClick={handleDownload}
              style={{ color: "rgb(25, 118, 210)" }}
            />
          </Tooltip>
        </span>
      </span>
      <A_Divider />
      {loading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          <AppBar position="static">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example"
              className={classes.tabHeader}
            >
              <Tab label="G" />
              <Tab label="UP" />
              <Tab label="VE" />
            </Tabs>
          </AppBar>
          <TableContainer component={Paper} className={classes.container}>
            {currentTab === 0 && renderChart(GDataset, "G")}
            {currentTab === 1 && renderChart(UPDataset, "UP")}
            {currentTab === 2 && renderChart(VEDataset, "VE")}
            {currentTab === 0 && renderPagingnation(GDataset)}
            {currentTab === 1 && renderPagingnation(UPDataset)}
            {currentTab === 2 && renderPagingnation(VEDataset)}

          </TableContainer>
        </>
      )}
      <A_SimpleDialog
        open={isFormVisible}
        fullScreen={false}
        height="400px"
        fullWidth={true}
        title={bool ? "Edit item" : "Add item"}
        maxWidth={"sm"}
        onClose={handleCloseForm}
        dialogContent={
          <MasterMappingForm
            formData={formData}
            handleAddRow={handleAddRow}
            handleCloseForm={handleCloseForm}
            isEdited={bool}
          />
        }
      />
    </div>
  );
};
MasterMappingData.propTypes = {
  content: PropTypes.object,
};
export default MasterMappingData;
