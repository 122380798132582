import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { Search, Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import {
  getAllUsersBasedOnGroupId,
  getUsersList,
  removeUserToGroups,
  addUserToGroups,
} from "./UserManagementHelper";
import { ArrowBack } from "@material-ui/icons";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { message } from "../../../../../Utils/config/messages";
import Skeleton from "@material-ui/lab/Skeleton";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    padding: theme.spacing(1),
    boxSizing: "border-box",
  },
  main: {
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing(2),
  },

  content: {
    backgroundColor: "#ecf0f1",
    padding: theme.spacing(2),
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  contentTitle: {
    margin: "0 0 10px 0",
    color: "#2980b9",
    fontSize: "17px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
  },
  searchContainer: {
    marginRight: "3rem",
    backgroundColor: "white",
    borderRadius: "25px",
    width: "100%",
    maxWidth: "25rem",
    marginBottom: theme.spacing(0.5),
  },
  searchIcon: {
    position: "absolute",
    left: theme.spacing(1),
    fontSize: "14px",
    color: "#7f8c8d",
  },
  searchBar: {
    width: "100%",
    padding: theme.spacing(0.5, 0.5, 0.5, 3.5),
    fontSize: "14px",
  },
  addUserButton: {
    padding: "6px 10px 6px 10px",
    backgroundColor: "#3498db",
    color: "white",
    borderRadius: "5px",
    fontSize: "14px",
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "#2980b9",
    },
  },
  userTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: theme.spacing(2),
  },
  userTableHeader: {
    backgroundColor: "#ecf0f1",
    fontWeight: "bold",
  },
  userTableCellHeader: {
    color: "#7F8C8D",
    fontWeight: "bold",
  },

  role: {
    display: "inline-block",
    padding: "5px 7px",
    borderRadius: "12px",
    color: "white",
    fontSize: "13px",
  },

  adminRole: {
    backgroundColor: "#27ae60",
  },
  employeeRole: {
    backgroundColor: "#b70202",
  },
  actionButtons: {
    fontSize: "18px",
    padding: "5px",
  },

  deleteButton: {
    color: "#e74c3c",
    "&:hover": {
      color: "#c0392b",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "right",
    margin: theme.spacing(2),
  },
  backButton: {
    padding: theme.spacing(1),
    width: "17rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "cenr",
    color: "#1976D1",
    marginLeft: "0.5rem",
    cursor: "pointer",
  },
}));

function GroupDashboard(props) {
  const { data, UserNavigateActive } = props;
  const classes = useStyles();
  const [UserList, setGroupUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [addUserPopUp, setAddUserPopUp] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [dropDownUers, setDropDownUers] = useState(false);
  const [finalUsers, setFinalUsers] = useState([]);
  const [disableButtonLoading, setDisableButtonLoading] = useState(false);
  const [selectedGroupforDelete, setSelectedGroupforDelete] = useState([]);

  async function getGroupUsers() {
    try {
      setLoading(true);
      const [res, allUsers] = await Promise.all([
        getAllUsersBasedOnGroupId(data.id),
        getUsersList(),
      ]);
      const isNewUser = (user) => {
        return !res.some((prevUser) => prevUser.id === user.id);
      };
      const newUsers = allUsers.filter(isNewUser);
      setDropDownUers(newUsers.length > 0 ? newUsers : []);
      setGroupUserList(res.length > 0 ? res : []);
      setLoading(false);
    } catch (error) {
      setGroupUserList([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    getGroupUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const filteredUsers = UserList.filter((user) => {
    const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
    return (
      user.email.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      user.phoneNumber.trim().toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const paginationCount = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = page * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (event) => {
    let allUsers = event.target.value;
    let result = allUsers.map((user) => {
      return {
        groupId: data?.id?.toString(),
        id: 0,
        userId: user.id,
      };
    });
    setSelectedGroups(allUsers);
    setFinalUsers(result);
  };
  const handleSave = async () => {
    if (finalUsers.length === 0) {
      props.setToast({
        type: toastSeverity.WARNING,
        severity: toastSeverity.WARNING,
        message: `Atleast one group is required`,
        status: true,
      });
      return;
    }
    setDisableButtonLoading(true);
    let res = await addUserToGroups(finalUsers);
    if (res.status >= 200 && res.status < 300) {
      props.setToast({
        type: toastSeverity.SUCCESS,
        severity: toastSeverity.SUCCESS,
        message: `New User Added to ${data?.name || "Group"}`,
        status: true,
      });
    } else {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setDisableButtonLoading(false);
    setSelectedGroups([]);
    setFinalUsers([]);
    setAddUserPopUp(false);
    await getGroupUsers();
  };

  const handleDelete = (data) => {
    setSelectedGroupforDelete(data);
    setDeletePopup(true);
  };
  const confirmDelete = async () => {
    let res = await removeUserToGroups(selectedGroupforDelete.id, data.id);
    if (res.status >= 200 && res.status < 300) {
      props.setToast({
        type: toastSeverity.SUCCESS,
        severity: toastSeverity.SUCCESS,
        message: `${selectedGroupforDelete.email || "User"} Removed from ${
          data?.name || "Group"
        }`,
        status: true,
      });
    } else {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setDeletePopup(false);
    setSelectedGroupforDelete([]);
    await getGroupUsers();
  };

  const GroupsComponent = (dropDownVals) => {
    return (
      <FormControl fullWidth>
        <InputLabel id="groups-select-label">Select Users</InputLabel>
        <Select
          labelId="groups-select-label"
          id="groups-select"
          multiple
          value={selectedGroups}
          onChange={handleChange}
          renderValue={(selected) => (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selected.map((value) => (
                <Chip
                  style={{
                    overflow: "auto",
                  }}
                  key={value.id}
                  label={value.email}
                />
              ))}
            </div>
          )}
        >
          {dropDownVals?.data?.map((user) => (
            <MenuItem key={user.id} value={user}>
              {user.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <div
        className={classes.backButton}
        onClick={() => UserNavigateActive(false)}
      >
        <ArrowBack />
        <Typography
          variant="body1"
          style={{ marginLeft: "8px", fontWeight: "bold" }}
        >
          {`Back to ${props.header}`}
        </Typography>
      </div>
      <div className={classes.dashboard}>
        <div className={classes.main}>
          <div className={classes.content}>
            <Typography variant="h6" className={classes.contentTitle}>
              {`List of users for group ${data?.name}`}
            </Typography>
            <div className={classes.actions}>
              <div className={classes.searchContainer}>
                <InputBase
                  placeholder=" Search"
                  classes={{
                    root: classes.searchBar,
                  }}
                  value={searchTerm}
                  onChange={handleSearch}
                  startAdornment={
                    <Search
                      fontSize={"small"}
                      style={{ margin: "0 0 2px 5px" }}
                      className={classes.searchIcon}
                    />
                  }
                />
              </div>
              <Button
                className={classes.addUserButton}
                disabled={loading}
                size="small"
                onClick={() => setAddUserPopUp(!addUserPopUp)}
              >
                Add User To Group
                <AddIcon
                  fontSize={"small"}
                  style={{ margin: "0 0 2px 3px" }}
                  size="small"
                />
              </Button>
            </div>
            {loading ? (
              <>
                <Skeleton variant="text" width={80} height={80} />
                <Skeleton variant="text" width={"100%"} height={80} />
                <Skeleton variant="rect" width={"100%"} height={118} />
              </>
            ) : !currentUsers ? (
              <Typography variant="h6">{message.NO_CONTENT}</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.userTable} size={"small"}>
                  <TableHead className={classes.userTableHeader}>
                    <TableRow>
                      <TableCell className={classes.userTableCellHeader}>
                        Email
                      </TableCell>
                      <TableCell className={classes.userTableCellHeader}>
                        Phone Number
                      </TableCell>
                      <TableCell className={classes.userTableCellHeader}>
                        Enabled
                      </TableCell>
                      <TableCell className={classes.userTableCellHeader}>
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUsers.length > 0 ? (
                      currentUsers.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            <span style={{ wordWrap: "anywhere" }}>
                              {user?.email}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span style={{ wordWrap: "anywhere" }}>
                              {user?.phoneNumber}
                            </span>
                          </TableCell>
                          <TableCell>
                            <sapn
                              className={`${classes.role} ${
                                user?.isEnabled
                                  ? classes?.adminRole
                                  : classes?.employeeRole
                              }`}
                            >
                              {user.isEnabled ? "YES" : "No"}
                            </sapn>
                          </TableCell>

                          <TableCell>
                            <IconButton
                              onClick={() => handleDelete(user)}
                              className={`${classes.actionButtons} ${classes.deleteButton}`}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableCell>
                        <span>{message.NO_DATA_FOUND}</span>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
                <div className={classes.pagination}>
                  <Pagination
                    count={paginationCount}
                    page={page}
                    onChange={handleChangePage}
                    shape="rounded"
                  />
                </div>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <A_SimpleDialog
        id="2"
        open={addUserPopUp}
        title="Add Users"
        fullScreen={false}
        height="600px"
        fullwidth={true}
        maxWidth={"sm"}
        onClose={() => {
          setAddUserPopUp(false);
          setFinalUsers([]);
          setSelectedGroups([]);
        }}
        dialogContent={
          <ErrorBoundary>
            <GroupsComponent data={dropDownUers} />
          </ErrorBoundary>
        }
        dialogActions={
          <>
            <A_Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              size="small"
              label="Save"
              endIcon={
                disableButtonLoading ? (
                  <A_CircularIndeternment color="secondary" size={20} />
                ) : (
                  <></>
                )
              }
            />
          </>
        }
      />
      <A_SimpleDialog
        id="3"
        title="Remove User"
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        dialogContent={
          <div style={{ wordWrap: "break-word" }}>
            {`Are you sure you want to remove ${
              selectedGroupforDelete.email
            } from the ${data?.name || "Group"}`}
          </div>
        }
        dialogActions={
          <>
            <A_Button
              size="small"
              label="Remove"
              color={"primary"}
              onClick={() => confirmDelete()}
            />
            <A_Button
              size="small"
              label="Cancel"
              color={"inherit"}
              onClick={() => setDeletePopup(false)}
            />
          </>
        }
      />
    </>
  );
}

GroupDashboard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  UserNavigateActive: PropTypes.func.isRequired,
  name: PropTypes.any,
  setToast: PropTypes.func,
  header: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupDashboard);
