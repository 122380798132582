import React, { useState, useEffect } from "react";
import { SurveyFilter, SurveyFilterYear } from "../components";
import * as esgApi from "../ESG_API/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import {  CircularProgress, TextField } from "@material-ui/core";
import { useDispatch} from "react-redux";
import { getCurrentEventValue } from "../../../../../../store/actions/getEventValueAction";
import {
  getQuestionsJsonFromSurvey,
  getAllPillar,
  getSubPillar,
} from "../ESG_API";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { responseLogic , responseLogicMapping } from "../ESG_Config";
import { addHeaderHightForESGPages } from "../utils";
import { customDateFormat } from "../../../../../../Utils/Helpers/dateUtils";
import { convertToDate } from "../utils";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding:"10px",
    border: 0,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const StyledTableRow2 = withStyles((theme) => ({}))(TableRow);

const useStyles = makeStyles((theme) => ({
  color: {
    backgroundColor: theme.palette.action.hover,
  },
  white: {
    backgroundColor: "white",
  },
  table: {
    position: "relative",
    //width:"100%",
  },
  button: {
    background: "#d7d7d7",
  },
  cell: {
    whiteSpace: "wrap",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
  },
  stickyHead:{
    zIndex: 0,
    position: "sticky",
    top: "0px",
  },
  pillar:{
    color:"white",
    background:"rgba(38,52,64,1)",
    textDecoration:"none",
    border:"1px solid white",
    padding:"10px"
  },
  subPillar:{
    padding:"10px"
  },
  textField: {
    width: "80px",
    "& input": {
      caretColor: "black",
      textAlign:"center"
    },
  },
  displayStatement: {
        width: '100%',
        resize:"none",
        border: "1px solid #ccc", // Add border to create a box-like appearance
        padding: "4px 8px",
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
        },
  },
  scoringModel: {
    padding: "8px",
    background: "white",
    marginLeft: "5px",
    border: "0.8px solid grey",
    borderRadius: "4px",
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    position: "absolute",
    margin: "6px",
    left: "50%",
    zIndex: "1",
    textAlign: "center",
  },
  addIconAlignment :{
    position: "absolute",
    top: "230px",
    right: "53px",
  }

}));

const QuestionnaireScore = () => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [questionnaireScoreData, setQuestionnaireScoreData]=useState([])
  const [finalTableData,setFinalTableData]=useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [erromsg, setErrorMsg] = useState("");
  const [surveyClosed,setSurveyClosed]=useState(false);
  const [displayStatementError,setDisplayStatementError]=useState({})
  const [alertvalidation,setAlertvalidation]=useState(false)
  const [reloadCheck, setReloadCheck] = useState(false);

  const dispatch = useDispatch();
  const [checkCurrentPageBool, setCheckCurrentPageBool]=useState(true);
  useEffect(()=>{
    let commonFilter = window.document.getElementsByClassName("MuiFormControl-root")
    let titleHeading = window.document.getElementById("PageTitleWrapper");
    titleHeading.style.marginTop = '-0.4em';
    if(commonFilter[0] && commonFilter[0].classList.length > 1){
    commonFilter[0].style.visibility = "hidden";
    }
   })
  const getQuestionTitle=(responseLogic)=>{
  if(responseLogic === responseLogicMapping.AdminInput ){
    return "Non-Boolean Question";
   }
   else{
    return "Boolean Question";
   }
  }
  


 const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setErrorMsg("");
 };

  const getResponseCss=(id)=>{
    if(id !== responseLogicMapping.AdminInput){
      return {  width: "110px",fontSize: 14}
    }
      return {  width: "110px", fontSize: 14,color:"black",backgroundColor:"lightgrey"}
  }


 const handleSave=async()=>{
      // set required error
      setIsDisable(true);
      let hasError = false;
      const updatedErrors = {};
      finalTableData.forEach((item)=>{
        item?.eSGQuestionInfoResponses.forEach((questionData)=>{
          if(!questionData?.displayStatement || questionData.displayStatement.trim() =="") {
            updatedErrors[questionData?.questionId] = 'This field is required';
            hasError = true;
          }
        })
      });
      // set error
      if (hasError) {
        setAlertvalidation(true)
        setDisplayStatementError(updatedErrors);
     }
     else{
      try {
        // filter extra fields
        const formData = finalTableData.map((item) => {
          return {
            surveySubCategoryWeightId: item.surveysubCategoryWeightId,
            subCategoryId: item.questionSubcategoryId,
            weight: item.weight,
            eSGQuestionScoreRequests: item.eSGQuestionInfoResponses,
          };
        });
        const response = await esgApi.putScoreLogicBySurveyId(formData);
        if (response && response.statusCode != 200) {
        setFailure(true);
        setErrorMsg(
        response && response?.errorMsg
          ? response && response.errorMsg
          : "Some issue occured while saving data"
       );
       } else {
        setReloadCheck(true);
       setSuccess(true);
       setErrorMsg("Data saved successfully");
       }
     }catch (error) {
      setErrorMsg( "Some issue occured while saving data" );
    }
  }
}


  const addWeightBySubpillar=(scoreData,pillarSubPillar,allQuestions)=>{
       const finalData=[];
       pillarSubPillar.forEach((data)=>
            { const filteredData= scoreData.find((item)=>(data.questionSubcategoryId===item.subCategoryId));
              if(filteredData && filteredData !== null){
              finalData.push({...data,
              surveysubCategoryWeightId:filteredData.surveySubCategoryWeightId,
              weight:filteredData.weight?filteredData.weight:0,
              eSGQuestionInfoResponses:filteredData.eSGQuestionInfoResponses
              })
          }
    });

    if (finalData.length !== 0) {
      finalData.forEach((item) => {
        item.eSGQuestionInfoResponses.forEach((obj) => {
          const questionText = getQuestionName(obj.questionId, allQuestions);
          obj["questionText"] = questionText;
        });
      });
    }
    return finalData;
  };

  const getQuestionName = (questionId, allQuestions) => {
    const question = allQuestions?.find(
      (item) => item.questionId === questionId
    );
    return question?.questionText;
  }

  const handleWeightChange = (e, questionSubcategoryId) => {

    dispatch(getCurrentEventValue(setCheckCurrentPageBool));
    let value = e.target.value;
    if(e.target.type=='number'){
      // remove -ive and
      let numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
      if (!isNaN(numericValue)) {
        value = numericValue.toFixed(3);
      } else {
        e.target.value = "";
      }
      }

    setFinalTableData((prevData) => {
      return prevData.map((item) => {
        if (item.questionSubcategoryId === questionSubcategoryId) {
          return {
            ...item,
            weight: value,
          };
        }
        return item;
      });
    });
    setIsDisable(false);
  };

  const handleChange = (e, subCategoryId, questionId, field) => {

    dispatch(getCurrentEventValue(setCheckCurrentPageBool));
    setIsDisable(false);
    let value = e.target.value;
    if(e.target.type=='number'){
     // remove -ive and
     let numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
     // Limit to 3 decimal places
     if (!isNaN(numericValue)) {
      value = numericValue.toFixed(3);
     }else{
      e.target.value = "";
     }
    }
    // set error for displaystatement required
    if (field === "displayStatement") {
      if (!value || value.trim() == "") {
        setDisplayStatementError((prevErrors) => ({
          ...prevErrors,
          [questionId]: "This field is required",
        }));
      } else {
        setDisplayStatementError((prevErrors) => {
          const { [questionId]: _, ...errorsLeft } = prevErrors;
          return errorsLeft;
        });
      }
    }

    // Update the finalJson array
    setFinalTableData((prevData) => {
      return prevData.map((item) => {
        if (item.subCategoryId === subCategoryId) {
          return {
            ...item,
            eSGQuestionInfoResponses: item.eSGQuestionInfoResponses.map(
              (question) => {
                if (question.questionId === questionId) {
                  return {
                    ...question,
                    [field]: value,
                  };
                }
                return question;
              }
            ),
          };
        }
        return item;
      });
    });
  };
 

  const getQuestionnaireScoreBysurvey=async()=>{
     let response= await esgApi.getScoreLogicBySurveyId(surveyId);
     return response?response:[];
  }

  
  const questionnaireScoreDataShow = () => {
    setReloadCheck(false);

    const getQuestionnaireDataBySurvey = async () => {
      // get survey status
      const surveyData= await esgApi.getSurveyBySurveyId(surveyId);
      let endDate =customDateFormat(surveyData.endDate,"DD/MM/YYYY");
      let currentDate = customDateFormat(new Date(),"DD/MM/YYYY");
      endDate= convertToDate(endDate)
      currentDate=convertToDate(currentDate)
      let closed= endDate < currentDate?true:false;
      setSurveyClosed(closed)
     if(!closed)
     {
      setIsLoading(true);
      // get all score and weight data
      const getQuestionnaireScoreData= await getQuestionnaireScoreBysurvey();
      const allQuestions = await getQuestionsJsonFromSurvey(surveyId);
      let pillarsData = await getAllPillar();
      pillarsData.sort((a, b) => a.name.localeCompare(b.name));
      let subPillarData = await getSubPillar();
      subPillarData.sort((a, b) => a.name.localeCompare(b.name));
      setQuestionnaireScoreData(getQuestionnaireScoreData);
      const pillarSubPillars = [];
       pillarsData.forEach(pillar => {
       subPillarData.forEach(subPillar => {
          if(subPillar.questioncategoryid === pillar.questioncategoryid){
           const data={
             questionCategoryId: pillar.questioncategoryid,
             pillarName:pillar.name,
             questionSubcategoryId:subPillar.questionsubcategoryid,
             subpillarName:subPillar.name
               }
               pillarSubPillars.push(data);
           }
        });
      });
        if (
          pillarSubPillars?.length !== 0 &&
          getQuestionnaireScoreData &&
          getQuestionnaireScoreData?.length !== 0 &&
          allQuestions &&
          allQuestions.length !== 0
        ) {
          const pillerSubpillarweight = addWeightBySubpillar(
            getQuestionnaireScoreData,
            pillarSubPillars,
            allQuestions
          );
          // set table data for piller subpiller & weight
          setFinalTableData(pillerSubpillarweight);
          setIsLoading(false);
        } else {
          setFinalTableData([]);
          setIsLoading(false);
        }
      } else {
        setFinalTableData([]);
        setIsLoading(false);
      }
    };

  // get all data
  if(surveyId){
            getQuestionnaireDataBySurvey();
            setDisplayStatementError({})
     }
  }
  useEffect(() => {
    questionnaireScoreDataShow();
  }, [surveyId]);

  useEffect(()=>{
    if(reloadCheck){
      questionnaireScoreDataShow();
    }
  },[reloadCheck]);

  useEffect(() => {
    setTimeout(() => {
      setAlertvalidation(false);
    }, [3000]);
  }, [alertvalidation]);

  useEffect(() => {
    const cleanupFunction = addHeaderHightForESGPages();
    return cleanupFunction;
  }, []); 

  let isLightBlue = false;

  return (
    <div>
    <div>
    <SurveyFilter
    selectedSurveyId={surveyId}
    filtersurveyyear={surveyYear}
    setSelectedSurveyId={setSurveyId}
    publishedtoggle ={true}
    heading={"Published Survey"}
    style={{"styleTop":"112px"}}
   />
   </div>
   <div>
   <SurveyFilterYear
    selectedSurveyId={surveyId}
    filtersurveyyear={surveyYear}
    setSelectedSurveyId={setSurveyId}
    setSurveyFilterYear={setSurveyYear}
    publishedtoggle={true}
    heading={"Survey Years"}
    style={{"styleTop":"112px"}}
   />
   </div>
   {surveyClosed?(<Alert severity="error">Survey Closed</Alert>):
   ( isLoading ? (
         <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ width: "30px", height: "30px" }} />
         </div>
        ) : ( finalTableData.length !==0?(
          <>
           <div className={classes.addIconAlignment}>
             <span style={{fontWeight:"600"}}>Rating Model: </span>
             <span className={classes.scoringModel}> Weighted Average</span>
           </div>

          <TableContainer component={Paper} >
            <Table aria-label="customized table" className={classes.table}>
            <TableHead >
            <TableRow>
              <StyledTableCell>
              <div style={{width:"12ch"}} className={classes.stickyHead}>Pillar Name</div>
              </StyledTableCell>
              <StyledTableCell>
              <div style={{width:"15ch"}} className={classes.stickyHead}>Sub Pillar Name</div>
              </StyledTableCell>
              <StyledTableCell>
              <div style={{width:"12ch"}} className={classes.stickyHead}>Weight</div>
              </StyledTableCell>
              <StyledTableCell>
               <div style={{minWidth:"25ch"}} className={classes.stickyHead}>Question</div>
              </StyledTableCell>
              <StyledTableCell align="center">
              <div style={{width:"12ch"}} className={classes.stickyHead}>Question Rating</div>
              </StyledTableCell>
              <StyledTableCell align="center">
              <div style={{width:"12ch"}} className={classes.stickyHead}>Response Logic</div>
              </StyledTableCell>
              <StyledTableCell>
              <div style={{width:"35ch"}} className={classes.stickyHead}>Display statement *</div>
              </StyledTableCell>
             </TableRow>
            </TableHead>

             <TableBody style={{ overflowY: "auto"}}>
             {finalTableData.map((data, idx) => {
               isLightBlue = !isLightBlue // toggell color
               return(
               <React.Fragment key={idx}>
               <StyledTableRow  className={`${isLightBlue?classes.color:classes.white}`} key={idx} >

                  <StyledTableCell className={`${classes.pillar}`} component="th" scope="row" 
                  rowSpan={data.eSGQuestionInfoResponses.length}>
                  {data.pillarName}
                  </StyledTableCell>

                   <StyledTableCell component="th" scope="row" rowSpan={data.eSGQuestionInfoResponses.length}>
                      {data.subpillarName}
                    </StyledTableCell>

                    <StyledTableCell  component="th" scope="row" rowSpan={data.eSGQuestionInfoResponses.length}>
                    <TextField
                     InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        min: 0}
                      }}
                      className={classes.textField}
                      type='number'
                      defaultValue={parseFloat(data.weight)}
                      onChange={(e) =>{
                      handleWeightChange(e,data.questionSubcategoryId)}}
                    />
                    </StyledTableCell>
                   
                    <StyledTableCell component="th" scope="row" 
                      className={ `${classes.cell} `}
                      key={data.eSGQuestionInfoResponses[0].questionId}
                      title={getQuestionTitle(data.eSGQuestionInfoResponses[0].responseLogic)}
                    >
                    {data.eSGQuestionInfoResponses[0].questionText}
                    </StyledTableCell>
                    
                    <StyledTableCell component="th" align="center" >
                        <TextField
                          InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            min: 0,
                          }
                          }}
                          className={`${classes.textField}`}
                          name="questionScore"
                          key={data.eSGQuestionInfoResponses[0].questionId}
                          type='number'
                          defaultValue={parseFloat(data.eSGQuestionInfoResponses[0].questionScore)}
                          onChange={(e) => {
                          e.preventDefault();
                          handleChange(e,data.subCategoryId, data.eSGQuestionInfoResponses[0].questionId, e.target.name)
                          }}
                          title="Enter number up to three decimal places"
                        />
                    </StyledTableCell>

                    <StyledTableCell  component="th" align="center">
                        <Select
                           style={getResponseCss(data.eSGQuestionInfoResponses[0].responseLogic)}
                           name="responseLogic"
                           key={data.eSGQuestionInfoResponses[0].questionId}
                           value={data.eSGQuestionInfoResponses[0].responseLogic === responseLogicMapping.AdminInput ? "Admin Input" : data.eSGQuestionInfoResponses[0].responseLogic}
                           disabled={data.eSGQuestionInfoResponses[0].responseLogic === responseLogicMapping.AdminInput}
                           onChange={(e) => handleChange(e,data.subCategoryId, data.eSGQuestionInfoResponses[0].questionId, e.target.name)}
                           >
                           {data.eSGQuestionInfoResponses[0].responseLogic === responseLogicMapping.AdminInput && (
                           <MenuItem
                               disabled
                                value= "Admin Input">
                                Admin Input
                               </MenuItem>
                           )}
                           {responseLogic .filter((item) => item.responseLogicId !== responseLogicMapping.AdminInput)
                           .map((item) => (
                                <MenuItem
                                 value={item.responseLogicId}
                                 key={item.responseLogicId}
                                 className="menuitemtext"
                                 style={{
                                 whiteSpace: "normal",
                                 overflowWrap: "break-word",
                                }}
                                >{item.name}</MenuItem>
                            ))}
                          </Select>
                    </StyledTableCell>

                    <StyledTableCell  component="th" align="center"  >
                        <TextField
                         id="outlined-multiline-static"
                         InputProps={{ disableUnderline: true }}
                         minRows={3}
                         maxRows={3}
                         multiline
                         className={classes.displayStatement}
                         name="displayStatement"
                         key={data.eSGQuestionInfoResponses[0].questionId}
                         defaultValue={data.eSGQuestionInfoResponses[0].displayStatement}
                         onChange={(e) => handleChange(e,data.subCategoryId, data.eSGQuestionInfoResponses[0].questionId, e.target.name)}
                        // error={!question.displayStatement}
                        />
                        <div style={{ color: "red", fontSize: "13px" }}>
                        {displayStatementError[data.eSGQuestionInfoResponses[0]?.questionId]}
                        </div>
                    </StyledTableCell>
                </StyledTableRow>
                  
                {data.eSGQuestionInfoResponses.slice(1).length >0 &&
                  data.eSGQuestionInfoResponses.slice(1).map((question, qIdx) => (
                   <StyledTableRow2 key={qIdx} className={`${isLightBlue?classes.color:classes.white}`}>
                   
                    <StyledTableCell component="th" scope="row" 
                      className={ `${classes.cell} `}
                      key={question.questionId}
                      title={getQuestionTitle(question.responseLogic)}
                    >
                    {question.questionText}
                    </StyledTableCell>
                       
                    <StyledTableCell component="th" align="center" >
                        <TextField
                          InputProps={{
                          disableUnderline: true,
                          inputProps: {
                            min: 0 }
                          }}
                          className={`${classes.textField}`}
                          name="questionScore"
                          key={question.questionId}
                          type='number'
                          defaultValue={parseFloat(question.questionScore)}
                          onChange={(e) => {
                          e.preventDefault();
                          handleChange(e,data.subCategoryId, question.questionId, e.target.name)
                          }}
                          title="Enter number up to three decimal places"
                        />
                    </StyledTableCell>

                    <StyledTableCell  component="th" align="center">
                        <Select
                           style={getResponseCss(question.responseLogic)}
                           name="responseLogic"
                           key={question.questionId}
                           value={question.responseLogic === responseLogicMapping.AdminInput ? "Admin Input" : question.responseLogic}
                           disabled={question.responseLogic === responseLogicMapping.AdminInput}
                           onChange={(e) => handleChange(e,data.subCategoryId, question.questionId, e.target.name)}
                           >
                           {question.responseLogic === responseLogicMapping.AdminInput && (
                           <MenuItem
                               disabled
                                value= "Admin Input">
                                Admin Input
                               </MenuItem>
                           )}
                           {responseLogic .filter((item) => item.responseLogicId !== responseLogicMapping.AdminInput)
                           .map((item) => (
                                <MenuItem
                                 value={item.responseLogicId}
                                 key={item.responseLogicId}
                                 className="menuitemtext"
                                 style={{
                                 whiteSpace: "normal",
                                 overflowWrap: "break-word",
                                }}
                                >{item.name}</MenuItem>
                            ))}
                          </Select>
                    </StyledTableCell>

                    <StyledTableCell  component="th" align="center"  >
                        <TextField
                         id="outlined-multiline-static"
                         InputProps={{ disableUnderline: true }}
                         rows={3}
                         multiline
                         className={classes.displayStatement}
                         name="displayStatement"
                         key={question.questionId}
                         defaultValue={question.displayStatement}
                         onChange={(e) => handleChange(e,data.subCategoryId, question.questionId, e.target.name)}
                        // error={!question.displayStatement}
                        />
                        <div style={{ color: "red", fontSize: "13px" }}>
                        {displayStatementError[question?.questionId]}
                        </div>
                    </StyledTableCell>
                 
                  </StyledTableRow2>

                  ))}
                 </React.Fragment>
              )}
            )}
           </TableBody>
          </Table>
          </TableContainer>
          {alertvalidation == true && (
            <Alert severity="error" className={classes.errorMessage}>
              <strong>Please fill out the inputs</strong>
            </Alert>
          )}
          <A_Button
           label={"Save"}
           style={{ position: "absolute",right:"50px",margin:"5px"}}
           color={"primary"}
           onClick={handleSave}
           disabled={isDisable}
           ></A_Button>
          </>
              ):(
                !surveyId?(
                  <Alert severity="info">
                  All active surveys are either unpublished or not live.
                 </Alert>
                ):(
                 <Alert severity="info">
                 No rating questions are found for this survey.
                </Alert>)
               )
            )
    )}
       <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }} >
       </div>
       <A_Snackbar
        open={success}
        message={erromsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
    </div>
  );
};

export default QuestionnaireScore;
