import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { creatUser, updateUser } from "./UserManagementHelper";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { message } from "../../../../../Utils/config/messages";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    margin: "0 auto",
    padding: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function AddUsers(props) {
  const allUsers = props.allUsers;
  const { data, handleSaveUser } = props;
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    email: data.email || "",
    phoneNumber: data.phoneNumber || "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
    };

    if (formData.firstName.trim() === "") {
      newErrors.firstName = "First Name is required";
      valid = false;
    }

    if (formData.lastName.trim() === "") {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    } else if (
      !data.email &&
      allUsers.some((user) => user.email === formData.email)
    ) {
      newErrors.email = "Account already exists";
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
    } else {
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
      });
      let res;
      let msg;
      setSaveButtonLoading(true);
      if (!data.email) {
        res = await creatUser(formData);
        msg = "Created";
      } else {
        let payload = { ...formData, id: data.id };
        res = await updateUser(payload);
        msg = "Updated";
      }
      if (res.status >= 200 && res.status < 300) {
        props.setToast({
          type: toastSeverity.SUCCESS,
          severity: toastSeverity.SUCCESS,
          message: `Account ${msg}`,
          status: true,
        });
      } else {
        props.setToast({
          type: toastSeverity.ERROR,
          severity: toastSeverity.ERROR,
          message: message.SOMETHING_WENT_WRONG,
          status: true,
        });
      }

      handleSaveUser();
      setSaveButtonLoading(false);
    }
  };

  return (
    <div>
      <form className={classes.form}>
        <TextField
          label="First Name"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          value={formData.firstName}
          defaultValue={formData.firstName}
          name="firstName"
          required
          error={!!errors.firstName}
          helperText={errors.firstName}
          inputProps={{ maxLength: 32 }}
          size="small"
        />
        <TextField
          label="Last Name"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          value={formData.lastName}
          defaultValue={formData.lastName}
          name="lastName"
          required
          error={!!errors.lastName}
          helperText={errors.lastName}
          inputProps={{ maxLength: 32 }}
          size="small"
        />
        <TextField
          label="Email (Login Id)"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          value={formData.email}
          defaultValue={formData.email}
          name="email"
          required
          error={!!errors.email}
          helperText={errors.email}
          disabled={data.email}
          inputProps={{ maxLength: 65 }}
          size="small"
        />
        <TextField
          label="Contact"
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          value={formData.phoneNumber}
          defaultValue={formData.phoneNumber}
          name="phoneNumber"
          size="small"
          inputProps={{ maxLength: 20 }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
          }}
        />
        <A_Button
          variant="contained"
          label={!saveButtonLoading ? "SAVE" : "SAVING..."}
          color="primary"
          className={classes.button}
          onClick={() => handleSubmit()}
          disabled={saveButtonLoading}
          endIcon={
            saveButtonLoading ? (
              <A_CircularIndeternment color="secondary" size={20} />
            ) : (
              <></>
            )
          }
        />
      </form>
    </div>
  );
}

AddUsers.propTypes = {
  handleSaveUser: PropTypes.any,
  setToast: PropTypes.func,
  allUsers: PropTypes.array,
  data: PropTypes.shape({
    id: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};
function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);
