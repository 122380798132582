import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TabContainer } from "react-bootstrap";
import {
  getAllMaturityAssessment,
  getAllPillar,
  getAllSubPillar,
  getInvestmentByCrmId,
  getQuestionsJsonFromSurvey,
  getSubPillar,
  getSurveyBySurveyId,
  getAllMaturityAssessmentForSurveyAndInvestment,
  getAllmaturitymatrixpreviewForSurveyAndInvestment,
} from "../ESG_API";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { SurveyFilter, SurveyFilterYear } from "../components";

import { useParams } from "react-router";
import { Alert } from "@material-ui/lab";
import { SurveyStatusMap } from "../ESG_Config";
import { addHeaderHightForESGPages } from "../utils";
import { useDispatch } from "react-redux";
import { getCurrentPillar } from "../../../../../../store/actions/getpillarnameActions";

const useStyles = makeStyles((theme) => ({
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  },
  body: {
    height: "100px",
  },
  pillar: {
    color: "white",
    background: "rgba(38,52,64,1)",
    textDecoration: "none",
    border: "0px",
  },
  stickyHead: {
    zIndex: 0,
    position: "sticky",
    top: "0px",
  },
  removeBoder: {
    textDecoration: "none",
    border: "0px",
  },
  removePadding: {
    "& input": {
      padding: "10px",
    },
    padding: "2px",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
  loader: {
    position: "absolute",
    left: "50%",
  },
  mrgBottom: {
    marginBottom: "5px",
  },
  headingColor: {
    color: "white",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: "10px",
    border: 0,
  },
}))(TableCell);
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  border: "1px solid white",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ESG_MaturityMatrixPreviewTemplate(props) {
  const classes = useStyles();
  const [allPillars, setAllPillars] = useState([]);
  const [allSubPillars, setAllSubPillars] = useState([]);
  const [survrydata, setSurveyData] = useState([]);
  const [data, setData] = useState([]);
  const [subdata, setSubData] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [loading, setLoading] = useState(true);
  const [maturityAssessment, setMaturityAssessment] = useState([]);
  const [allMaturity, setAllMaturity] = useState([]);
  const [surveyStatus, setSurveyStatus] = useState(false);
  let { crmId } = useParams();
  const [pillarOrder, setPillarOrder] = useState([]);
  const [publishedtoggle, setPublishedToggle] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const dispatch = useDispatch();

  const data1 = [
    {
      categoryId: 1,
      subCategoryId: 1,
      maturityAssessmentId: 2,
      dashboardStatement: "Test1",
      responseText: "true",
    },
    {
      categoryId: 1,
      subCategoryId: 1,
      maturityAssessmentId: 1,
      dashboardStatement: "ABC",
      responseText: "true",
    },
    {
      categoryId: 1,
      subCategoryId: 1,
      maturityAssessmentId: 3,
      dashboardStatement: "Test2",
      responseText: "false",
    },
    {
      categoryId: 2,
      subCategoryId: 2,
      maturityAssessmentId: 1,
      dashboardStatement: "Test3",
      responseText: null,
    },
    {
      categoryId: 3,
      subCategoryId: 5,
      maturityAssessmentId: 2,
      dashboardStatement: "Test4",
      responseText: 0,
    },
  ];

  const setAllMaturityAssessment = (data) => {
    let val = new Set();

    data.map((e) => {
      val.add(e.maturityAssessmentId);
    });

    const array = [...val];
    array.sort((x, y) => {
      return x - y;
    });
    setMaturityAssessment(array);
  };

  const getSubPillarforPillar = (data) => {
    const showData = data?.reduce((acc, item) => {
      const { categoryId } = item;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }
      acc[categoryId].push(item);
      return acc;
    }, {});
    setSubData(showData);
  };

  const mergeByPillars = (data) => {
    const showData = data?.reduce((acc, item) => {
      const { questionCategoryId } = item;
      if (!acc[questionCategoryId]) {
        acc[questionCategoryId] = [];
      }
      acc[questionCategoryId].push(item);
      return acc;
    }, {});
    setData(showData);
  };

  useEffect(() => {
    if (isEmpty) {
      setLoading(false);
    }
  }, [isEmpty]);

  useEffect(() => {
    if (selectedSurveyId != "") {
      const statusRes = async () => {
        setLoading(true);
        const resStatus = await getSurveyBySurveyId(selectedSurveyId);
        let statusResult = resStatus?.status === SurveyStatusMap.Publish;
        setSurveyStatus(statusResult);
        if (!statusResult) {
          setLoading(false);
        }
      };
      statusRes();
    }
  }, [selectedSurveyId]);

  useEffect(() => {
    if (selectedSurveyId != "" && surveyStatus) {
      setLoading(true);
      const apiCalls = async () => {
        const pillars = await getAllPillar();
        const subpillars = await getSubPillar();
        const surveyData = await getQuestionsJsonFromSurvey(selectedSurveyId);
        const maturity = await getAllMaturityAssessment();
        const crmData = await getInvestmentByCrmId(crmId);
        const investmentId = crmData.investmentId;

        const responseDataPreview =
          await getAllmaturitymatrixpreviewForSurveyAndInvestment(
            selectedSurveyId,
            investmentId
          );

        const pillarIndexMap = {};
        pillars?.forEach((item, index) => {
          pillarIndexMap[item?.questioncategoryid] = index;
        });
        setPillarOrder(pillarIndexMap);
        setAllPillars(pillars);
        setAllSubPillars(subpillars);
        setSurveyData(surveyData);
        mergeByPillars(surveyData);
        getSubPillarforPillar(responseDataPreview);
        setLoading(false);
        setAllMaturityAssessment(responseDataPreview);
        setAllMaturity(maturity);
      };
      apiCalls();
    }
  }, [selectedSurveyId, filtersurveyyear, surveyStatus]);

  useEffect(() => {
    const cleanupFunction = addHeaderHightForESGPages();
    return cleanupFunction;
  }, []);
  const navigateToESG = (name) => {
    dispatch(getCurrentPillar(name));
    let templateId = props.content.ESGTemplateId;
    window.open(
      `/${props.content.ASSETS_PATH}/${crmId}/${templateId}`,
      "_blank"
    );
  };
  return (
    <>
      <div>
        <SurveyFilter
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setIsEmpty={setIsEmpty}
          setSelectedSurveyId={setSelectedSurveyId}
          publishedtoggle={publishedtoggle}
          heading={"Published Survey"}
          style={{ styleTop: "112px" }}
        />
      </div>
      <div>
        <SurveyFilterYear
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          setSurveyFilterYear={setSurveyFilterYear}
          publishedtoggle={publishedtoggle}
          heading={"Survey Years"}
          style={{ styleTop: "112px" }}
        />
      </div>
      {!loading ? (
        surveyStatus && maturityAssessment.length != 0 ? (
          <div>
            <div>
              <TabContainer component={Paper} className={classes.body}>
                <Table aria-label="customized table">
                  <TableHead style={{ background: "grey" }}>
                    <TableRow>
                      <StyledTableCell>
                        <div
                          className={`${classes.headingColor} ${classes.stickyHead}`}
                          style={{ width: "15ch" }}
                        >
                          Pillar
                        </div>
                      </StyledTableCell>
                      {maturityAssessment.map((e) => {
                        let maturity = allMaturity.find(
                          (item) => e == item.maturityassessmentid
                        );
                        return (
                          <StyledTableCell style={{ width: "22vw" }}>
                            <div
                              id={maturity.maturityassessmentid}
                              className={`${classes.headingColor} ${classes.stickyHead}`}
                              style={{ width: "25ch" }}
                            >
                              {maturity?.name
                                ? maturity.name.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )
                                : maturity?.name}
                            </div>
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(subdata)?.map((element) => {
                      let val = allPillars.find(
                        (item) => element == item.questioncategoryid
                      );
                      return (
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              color: "white",
                              background: "rgba(38,52,64,1)",
                              textDecoration: "none",
                              border: "0px",
                            }}
                            className={classes.headingColor}
                            component="th"
                          >
                            <div
                              onClick={() => navigateToESG(val.name)}
                              style={{ cursor: "pointer" }}
                            >
                              {val.name}
                            </div>
                          </StyledTableCell>
                          {maturityAssessment.map((items) => {
                            const responseScoresCheck = (ele) => {
                              const newObject = {};
                              ele.forEach((item) => {
                                const { maturityAssessmentId } = item;

                                if (!newObject[maturityAssessmentId]) {
                                  newObject[maturityAssessmentId] = [];
                                }
                                newObject[maturityAssessmentId].push(item);
                              });
                              const objectvalues = Object.values(newObject);
                              for (let i = 0; i < objectvalues.length; i++) {
                                const objValue = objectvalues[i];
                                const dashboardValue = objValue?.filter(
                                  (data) => data.responseText != null
                                );
                                if (dashboardValue.length !== 0) {
                                  objValue.forEach((data) => {
                                    if (data.responseText != null) {
                                      if (data.responseText == "false") {
                                        data["color"] = "grey";
                                      } else {
                                        data["color"] = "green";
                                      }
                                    } else {
                                      data["color"] = "grey";
                                    }
                                  });
                                } else {
                                  objValue.forEach((data) => {
                                    data["color"] = "grey";
                                  });
                                }
                              }
                            };

                            return (
                              <StyledTableCell component="th">
                                {subdata[element].map((ele) => {
                                  const color = responseScoresCheck(
                                    subdata[element]
                                  );
                                  if (ele.maturityAssessmentId == items) {
                                    return (
                                      <div
                                        className={classes.mrgBottom}
                                        style={
                                          ele?.color
                                            ? {
                                                color: ele.color,
                                                fontWeight: 600,
                                              }
                                            : { color: "grey", fontWeight: 600 }
                                        }
                                      >
                                        {ele?.dashboardStatement}
                                      </div>
                                    );
                                  }
                                })}
                              </StyledTableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TabContainer>
            </div>
            <div>
              {props.content.config && (
                <Box style={{ marginTop: "18px" }}>
                  {props.content.config.map((item, index) => (
                    <Box key={index} className={classes.legendItem}>
                      <Box
                        className={classes.circle}
                        style={{ backgroundColor: item.statementColour }}
                      ></Box>
                      <Typography variant="body2">
                        {item.Implication}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </div>
          </div>
        ) : selectedSurveyId ? (
          <Alert
            severity="info"
            style={{
              width: "auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Maturity matrix statement not found for this survey.
          </Alert>
        ) : (
          <Alert
            severity="info"
            style={{
              width: "auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            All surveys are closed.
          </Alert>
        )
      ) : (
        <div style={{ height: "30px" }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
    </>
  );
}
ESG_MaturityMatrixPreviewTemplate.propTypes = {
  content: PropTypes,
};
export default ESG_MaturityMatrixPreviewTemplate;
