import React, { useState } from "react";
// import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import {
  Chip,
  CircularProgress,
  Popover,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { message } from "../../../../../../Utils/config/messages";
import Backdrop from "@material-ui/core/Backdrop";
import { tableHeader } from "../ESG_Config";
import { useEffect } from "react";
import * as esgApi from "../ESG_API/index";
import SurveyFilter from "./SurveyFilter";
import SurveyFilterYear from "./SurveyFilterYear";
import { organizeQuestions } from "../utils";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { Alert } from "@material-ui/lab";
import emitter from "./Emitter";
import { CommonFilter } from "../components";
import { ListItem } from "react-bootstrap/lib/Media";
import { Edit, MoreVert } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditQuestionForm from "./EditQuestionForm";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  fixedHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  inputcontrol: { minWidth: "20ch" },
  selectData: {
    minwidth: "20ch",
  },
  iconButton: {
    padding: "4px",
  },

  iconLabel: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "4px",
    cursor: "pointer",
  },
  iconLabelLarge: {
    fontSize: "16px",
    "& > *": {
      marginBottom: "6px",
    },
  },
  columnHeader: {
    color: "#fff",
  },
  iconButtonLabel: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      marginBottom: "4px",
    },
    iconButtonMainContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ESG_QuestionnaireSetupView = (props) => {
  const classes = useStyles();
  const [openRowIndex, setOpenRowIndex] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [allfilterData, setAllfilterData] = useState([]);
  const [selectedPillarValue, setSelectedPillarValue] = useState("");
  const [selectedSubPillarValue, setSelectedSubPillarValue] = useState("");
  const [filterPillarTextValues, setFilterPillarTextValues] = useState([]);
  const [filterSubPillarTextValues, setFilterSubPillarTextValues] = useState(
    []
  );
  const [filteredData, setFilteredData] = useState([]);
  const [surveyquestion, setSurveyQuestion] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reloadSetupView, setReloadSetupView] = useState(false);
  const [isPublishedDisabled, setIsPublishedDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [failure, setFailure] = useState(false);
  const [questionItem, setQuestionItem] = useState({});
  const [erromsg, setErrorMsg] = useState("");
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const { singleSurvey, setSingleSurvey } = props;
  const [fullFormData, setFullFormData] = useState({});
  const [allStates, setAllStates] = useState({
    pillar: [],
    subpillar: [],
    maturityassessment: [],
    paiframework: [],
    dataconvergenceframework: [],
    questionanstype: [],
    publishsurvey: [],
    parentquestion: [],
  });
  const [deleted, setDelete] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [surveydata, setSurveyData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenBox, setIsOpenBox] = useState(false);
  const [listtype, setListType] = useState([]);
  const [popoverStates, setPopoverStates] = useState({});
  const [guidancetext, setGuidancetext] = useState("");
  const [itemType, setItemType] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const {
    pillar,
    maturityassessment,
    paiframework,
    dataconvergenceframework,
    questionanstype,
    parentquestion,
  } = allStates;
  // const { setOpen, setEdit } = props;
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const addedForm = localStorage.getItem("QuestionFormAdded")
    ? localStorage.getItem("QuestionFormAdded")
    : false;
  const [subPillardata, setSubPillardata] = useState([]);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [isPublishedPopup, setIsPublishedPopup] = useState(false);
  const [editQuestionPayload, setEditQuestionPayload] = useState({
    questiontext: "",
    hasSubquestions: false,
    subquestions: [],
    guidance: "",
    measurementUnitId: "",
  });
  useEffect(()=>{
    let commonFilter = window.document.getElementsByClassName("MuiFormControl-root")
    let titleHeading = window.document.getElementById("PageTitleWrapper");
    titleHeading.style.marginTop = '-0.4em';
    if(commonFilter[0] && commonFilter[0].classList.length > 1){
    commonFilter[0].style.visibility = "hidden";
    }
   })
  useEffect(() => {
    if (surveydata) {
      setSingleSurvey(
        Object.assign(
          {},
          surveydata.filter((item) => {
            return item.surveyId == selectedSurveyId;
          })
        )
      );
    }
  }, [selectedSurveyId, surveydata]);

  //Get All Pillar Data
  const getAllPillar = async () => {
    const response = await esgApi.getAllPillar();
    response.sort((a, b) => a.name.localeCompare(b.name));
    setAllStates((prevStates) => ({ ...prevStates, pillar: response }));
  };

  //Get SubPillar Data
  const getsubPillar = async () => {
    const resp = await esgApi.getSubPillar();
    resp.sort((a, b) => a.name.localeCompare(b.name));
    setSubPillardata(resp);
  };

  // Get All Maturity Assessment
  const getAllMaturityAssessment = async () => {
    const response = await esgApi.getAllMaturityAssessment();
    setAllStates((prevStates) => ({
      ...prevStates,
      maturityassessment: response,
    }));
  };

  //Get All PAI Framwork
  const getAllPaiFramework = async () => {
    const response = await esgApi.getAllPaiFramework();
    setAllStates((prevStates) => ({
      ...prevStates,
      paiframework: response,
    }));
  };

  //Get All Data Convergence Framwork
  const getAllDataConvergenceframework = async () => {
    const response = await esgApi.getAllDataConvergenceframework();
    setAllStates((prevStates) => ({
      ...prevStates,
      dataconvergenceframework: response,
    }));
  };

  //Get Question Answer Type
  const getQuestionAnstype = async () => {
    const response = await esgApi.getQuestionAnstype();
    setAllStates((prevStates) => ({
      ...prevStates,
      questionanstype: response,
    }));
  };

  //Publish Survey
  const publishSurvey = async () => {
    setIsLoading(true);
    try {
      const response = await esgApi.publishSurvey(selectedSurveyId);
      if (response && response.statusCode != 200) {
        setFailure(true);
        setErrorMsg(
          response && response.errorMsg
            ? response && response.errorMsg
            : "Some issue occured during publish"
        );
        setIsLoading(false);
        setIsPublishedDisabled(false);
      } else {
        setSuccess(true);
        setErrorMsg(response.data);
        setIsPublishedDisabled(true);
        setReloadSetupView(true);
      }
      setAllStates((prevStates) => ({
        ...prevStates,
        publishsurvey: response,
      }));
    } catch (error) {
      console.error("Failed to publish survey:", error);
    }
  };

  //Get Question By Survey Id
  const getQuestionsBySurveyId = async () => {
    const survey = JSON.parse(localStorage.getItem("Survey"));
    if (survey) {
      const resp = await esgApi.getQuestionsJsonFromSurvey(survey);
      if (resp) {
        setSurveyQuestion(organizeQuestions(resp));
      } else {
        setSurveyQuestion([]);
      }
    }
  };

  const getParentQuestion = async () => {
    const surveyid = localStorage.getItem("Survey");
    if (surveyid) {
      const response = await esgApi.getQuestionsJsonFromSurvey(surveyid);
      let parentQuestions = [];
      if (response) {
        parentQuestions = response.filter(
          (ques) => ques.questionAnswerTypeId === 3
        );
      }
      setAllStates((prevStates) => ({
        ...prevStates,
        parentquestion: parentQuestions,
      }));
    }
  };

  //Get All Survey
  const getAllSurvey = async () => {
    let response = await esgApi.getSurvey();
    setSurveyData(response);
  };

  const sortOnPillarAndSubPillar = (allQues) => {
    const pillarIndexMap = new Map(
      pillar.map((pillar, index) => [pillar.questioncategoryid, index])
    );
    const subPillarIndexMap = new Map(
      subPillardata.map((subPillar, index) => [
        subPillar.questionsubcategoryid,
        index,
      ])
    );

    // Sort filter data based on the sorted pillar array
    allQues.sort((a, b) => {
      const pillarAIndex = pillarIndexMap.get(a.questionCategoryId);
      const pillarBIndex = pillarIndexMap.get(b.questionCategoryId);

      const subPillarAIndex = subPillarIndexMap.get(a.questionSubCategoryId);
      const subPillarBIndex = subPillarIndexMap.get(b.questionSubCategoryId);

      if (pillarAIndex !== pillarBIndex) {
        return pillarAIndex - pillarBIndex;
      }

      return subPillarAIndex - subPillarBIndex;
    });
  };

  function getUniqueNames(array) {
    const uniqueNames = new Set(); // Use a Set to automatically handle uniqueness
    array.forEach((item) => {
      uniqueNames.add(item.name); // Add each name to the Set
    });
    return Array.from(uniqueNames); // Convert the Set back to an array
  }

  function handleSelectedPillarChange(value) {
    if (
      selectedSubPillarValue !== "" &&
      selectedSubPillarValue !== "All Subpillars"
    ) {
      setSelectedSubPillarValue("All Subpillars");
    }
    setSelectedPillarValue(value);
  }

  const handleClosePopToggle = () => {
    setOpenEditForm(false);
    handleEditClose();
    setIsOpenBox(false);
  };

  useEffect(() => {
    let uniquePillarNames = getUniqueNames(pillar);
    uniquePillarNames.unshift("All Pillars");
    setFilterPillarTextValues(uniquePillarNames);
  }, [pillar]);

  useEffect(() => {
    let selectedPillar;
    let newSubPillars = subPillardata;
    if (selectedPillarValue !== "" && selectedPillarValue !== "All Pillars") {
      selectedPillar = pillar.find((item) => item.name === selectedPillarValue);
    }

    if (selectedPillar) {
      newSubPillars = newSubPillars.filter(
        (item) => item.questioncategoryid == selectedPillar.questioncategoryid
      );
    }

    let uniqueSubPillarNames = getUniqueNames(newSubPillars);
    uniqueSubPillarNames.unshift("All Subpillars");
    setFilterSubPillarTextValues(uniqueSubPillarNames);
  }, [subPillardata, selectedPillarValue, pillar]);

  useEffect(() => {
    let localFilteredData = allfilterData;

    if (selectedPillarValue !== "" && selectedPillarValue !== "All Pillars") {
      localFilteredData = localFilteredData.filter((item) => {
        const foundPillar = pillar.find(
          (element) => element.questioncategoryid === item.questionCategoryId
        );

        if (foundPillar) {
          return foundPillar.name.includes(selectedPillarValue);
        }
      });
    }

    if (
      selectedSubPillarValue !== "" &&
      selectedSubPillarValue !== "All Subpillars"
    ) {
      localFilteredData = localFilteredData.filter((item) => {
        const foundSubPillar = subPillardata.find(
          (element) =>
            element.questionsubcategoryid === item.questionSubCategoryId
        );

        if (foundSubPillar) {
          return foundSubPillar.name.includes(selectedSubPillarValue);
        }
      });
    }

    setFilteredData(localFilteredData);
  }, [selectedPillarValue, selectedSubPillarValue, allfilterData]);

  useEffect(() => {
    getAllPillar();
    getsubPillar();
    getAllMaturityAssessment();
    getAllPaiFramework();
    getAllDataConvergenceframework();
    getQuestionsBySurveyId();
    getQuestionAnstype();
    getAllSurvey();
    getParentQuestion();
  }, []);

  useEffect(() => {
    getParentQuestion();
    if (selectedSurveyId) {
      getQuestionsBySurveyId();
    }
  }, [selectedSurveyId]);

  useEffect(() => {
    if (surveyquestion) {
      sortOnPillarAndSubPillar(surveyquestion);
      setAllfilterData(surveyquestion);
      setIsLoading(false);
    } else setAllfilterData([]);

    setIsLoading(false);
  }, [surveyquestion]);

  useEffect(() => {
    if (addedForm === true || deleted === true) {
      getAllPillar();
      getAllMaturityAssessment();
      getAllPaiFramework();
      getAllDataConvergenceframework();
      getQuestionAnstype();
      getParentQuestion();
    }
  }, [addedForm, deleted]);

  useEffect(() => {
    const onSetupViewReloadEvent = (receivedData) => {
      setReloadSetupView(receivedData);
    };

    emitter.on("reloadSetupViewEvent", onSetupViewReloadEvent);

    if (reloadSetupView) {
      getAllPillar();
      getsubPillar();
      getAllMaturityAssessment();
      getAllPaiFramework();
      getAllDataConvergenceframework();
      getQuestionsBySurveyId();
      getQuestionAnstype();
      getAllSurvey();
      getParentQuestion();
      setReloadSetupView(false);
    }
  }, [reloadSetupView]);

  useEffect(() => {
    setPopoverStates(Array(filteredData.length).fill(false));
  }, [filteredData]);

  //Edit Toggle PopOver
  const handleRowToggle = (parentIndex) => {
    const currentIndex = openRowIndex.indexOf(parentIndex);
    const newOpenRowIndex = [...openRowIndex];

    if (currentIndex === -1) {
      newOpenRowIndex.push(parentIndex);
    } else {
      newOpenRowIndex.splice(currentIndex, 1);
    }

    setOpenRowIndex(newOpenRowIndex);
  };

  const handleDelete = async () => {
    // API call to delete mapping
    const res = await esgApi.deleteSurveyQuestionMap(
      questionItem.questionId,
      JSON.parse(localStorage.getItem("Survey"))
    );

    if (res && res.statusMessage && res.statusMessage === "Success") {
      setSuccessDelete(true);
      setReloadSetupView(true);
      setDeletePopUp(false);
    }

    if (
      res &&
      res.response &&
      res.response.data &&
      res.response.data.errorMsg
    ) {
      setFailure(true);
      setErrorMsg(res.response.data.errorMsg);
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setSuccessDelete(false);
  };

  const handlePublish = () => {
    setIsPublishedDisabled(true);
    publishSurvey();
  };

  const handleConfirmDelete = async () => {
    const deleteid = localStorage.getItem("DeleteId");

    let res = await esgApi.deleteQuestion(deleteid);

    if (res.statusCode == 200) {
      localStorage.setItem("addedForm", true);
      setDelete(true);
    }
    setReloadSetupView(true);
    setIsOpen(false);
  };

  const getActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="OK"
          autoFocus
          onClick={handleConfirmDelete}
        />
        <A_Button color="default" label="Cancel" onClick={handleCancelDelete} />
      </>
    );
  };

  const handlePublishConfirmPublish = () => {
    handlePublish();
    handlePublishCancelPublish();
  };

  const handleDeleteConfirm = () => {
    handleDelete();
    emitter.emit("reloadExistingQuestionEvent", true);
  };

  const handlePublishCancelPublish = () => {
    setIsPublishOpen(false);
  };

  let handlePublishPopup = () => {
    setIsPublishOpen(true);
  };

  let handleDeletePopUp = () => {
    setDeletePopUp(true);
  };

  const handleDeletePopUpCancel = () => {
    setDeletePopUp(false);
  };

  const handleEditOpen = (event, index) => {
    const newPopoverStates = {...popoverStates};
    newPopoverStates[index] = true;
    setPopoverStates(newPopoverStates);
    setAnchorEl(event.currentTarget);
  };

  const handleEditClose = (index) => {
    const newPopoverStates = {...popoverStates};
    if (index) {
      newPopoverStates[index] = false;
      setPopoverStates(newPopoverStates);
    } else {
      setPopoverStates({});
    }
  };

  const getPublishActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handlePublishConfirmPublish}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handlePublishCancelPublish}
        />
      </>
    );
  };

  const handleQuestionClosePopup = () => {
    setIsOpenBox(false);
  };

  const getPopActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handleClosePopToggle}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handleQuestionClosePopup}
        />
      </>
    );
  };

  const getDeleteActionItem = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handleDeleteConfirm}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handleDeletePopUpCancel}
        />
      </>
    );
  };

  const openEditESGForm = (
    item,
    Pillar,
    SubPillar,
    MaturityAssessment,
    QuesAnsType,
    PaiFramework,
    DataConvergenceFramework,
    index
  ) => {
    setCurrentQuestion(item);
    if(item?.ispublished){
      setIsPublishedPopup(true);
    }else{
    const parentQuestion = surveyquestion.find(
      (ques) => ques.questionId == item.parentQuestionId
    );
    const text = parentQuestion
      ? parentQuestion.questionText
        ? parentQuestion.questionText
        : ""
      : "";

    const list = item.hasSubQuestions ? item.subQuestion : [];
    let filtereditem = [];
    if (list) {
      for (let i of list) {
        filtereditem.push({
          measurementUnitId:
            editQuestionPayload.measurementUnitId ||
            currentQuestion.measurementUnitId,
          text: i.text,
        });
      }
    }
    const obj = {
      pillar: Pillar.name,
      subPillar: SubPillar.name,
      maturityAssessment: MaturityAssessment.name,
      parentQuestion: text,
      parentQuestionLogic: "",
      question: item.questionText,
      type: [],
      guidance: item.guidance,
      questionAnswerType: QuesAnsType.name,
      unitOfMeasure: item.measurementUnitId,
      paiFramework: PaiFramework.name,
      dataConvergenceFramework: DataConvergenceFramework.name,
      newQuestionOrImportData: item.importHistoricData ? "Yes" : "No",
      staticOrUpdatedYearly: item.isStatic ? "Yes" : "No",
      rating: item.rating ? "Yes" : "No",
      sector: [],
      isConditionalQuestion: item.isConditional ? "Yes" : "No",
      logic: "",
      year: "",
      questionName: "",
      startDate: "",
      endDate: "",
      unit: "",
    };
    setEditQuestionPayload({
      questiontext: item.questionText,
      hasSubquestions: list.length > 0 ? true : false,
      subquestions: list.length > 0 ? filtereditem : [],
      guidance: item.guidance,
      measurementUnitId: item.measurementUnitId,
    });
    setListType(list);
    setFullFormData(obj);
    setOpenEditForm(true);
  }
  };

  const handleAddType = () => {
    const trimmedItemType = itemType.trim();
    const isItemTypeExists = listtype.some((item) => item.text === itemType);
    if (itemType && !isItemTypeExists && trimmedItemType) {
      let localListtype = [
        ...listtype,
        {
          text: itemType,
          responseMeasurementUnitid:
            editQuestionPayload.measurementUnitId ||
            currentQuestion.measurementUnitId,
        },
      ];
      setListType(localListtype);
      setEditQuestionPayload((prev) => ({
        ...prev,
        hasSubquestions: localListtype.length > 0 ? true : false,
      }));
      setEditQuestionPayload((prev) => ({
        ...prev,
        subquestions: localListtype.length > 0 ? localListtype : [],
      }));
      setItemType("");
    }
  };

  const handleListDelete = (text) => {
    let locallist = listtype.filter((item) => item.text !== text);
    setListType(locallist);
    setEditQuestionPayload((prev) => ({
      ...prev,
      hasSubquestions: locallist.length > 0 ? true : false,
    }));
    setEditQuestionPayload((prev) => ({
      ...prev,
      subquestions: locallist.length > 0 ? locallist : [],
    }));
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    if (name == "question") {
      setFullFormData({ ...fullFormData, question: value });
      setEditQuestionPayload({ ...editQuestionPayload, questiontext: value });
    } else if (name == "unit") {
      setFullFormData({ ...fullFormData, unitOfMeasure: value });
      setEditQuestionPayload({
        ...editQuestionPayload,
        measurementUnitId: value,
      });
    } else if (name == "type") {
      setItemType(value);
    }
  };

  useEffect(() => {
    setEditQuestionPayload({ ...editQuestionPayload, guidance: guidancetext });
  }, [guidancetext]);

  const handleEditFormSubmit = async () => {
    let response = await esgApi.putEditExistingQuestion(
      currentQuestion.questionId,
      editQuestionPayload
    );
    if (response.message) {
      setFailure(true);
      setErrorMsg(response.response.data.errorMsg);
    } else {
      setSuccess(true);
      setErrorMsg(response.data);
      setReloadSetupView(true);
      setOpenEditForm(false);
    }
  };

  const handlePopupClose = () => {
    setIsOpenBox(true);
  };

  const handleESGEditClose = () => {
    setOpenEditForm(false);
    setAnchorEl(null);
  };

  const handleCancelDelete = () => {
    setIsOpen(false);
  };

  let handlePopup = () => {
    setIsOpen(true);
  };
  const getActionItemsIsPublished = () => {
    return (
      <A_Button
        color="primary"
        label="OK"
        autoFocus
        onClick={() => setIsPublishedPopup(false)}
      />
    );
  };
  useEffect(() => {
    if (allfilterData.length > 0) {
      setIsLoading(false);
    } else if (!selectedSurveyId && !surveydata) {
      setIsLoading(false);
    }
    // else setIsLoading(true);
  }, [allfilterData]);
  // const handlePopoverClose = () => {
  //   setOpenPopoverIndex(null);
  // };

  // const handleEdit = (data) => {
  //   setOpen(true);
  //   setEdit(true);
  //   localStorage.setItem("SubQuestion", JSON.stringify(data));
  // };

  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolling(offset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderNestedList = (item, parentIndex) => {
    return (
      <Collapse
        in={openRowIndex.includes(parentIndex)}
        timeout="auto"
        unmountOnExit
        key={parentIndex}
      >
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            {item.children && item.children.length > 0 && (
              <TableBody>
                {item.children.map((child, subIndex) => {
                  if (true) {
                    const Pillar = pillar.find(
                      (element) =>
                        element.questioncategoryid === child.questionCategoryId
                    );

                    const SubPillar = subPillardata.find(
                      (element) =>
                        element.questionsubcategoryid ===
                        child.questionSubCategoryId
                    );
                    const MaturityAssessment = maturityassessment.find(
                      (element) =>
                        element.maturityassessmentid ===
                        child.maturityAssessmentId
                    );
                    const QuesAnsType = questionanstype.find(
                      (element) =>
                        element.questionanswertypeid ===
                        child.questionAnswerTypeId
                    );
                    const PaiFramework = paiframework.find(
                      (element) =>
                        element.paiframeworkid === child.paiFrameworkId
                    );
                    const DataConvergenceFramework =
                      dataconvergenceframework.find(
                        (element) =>
                          element.dataConvergenceframeworkid ===
                          child.dataConvergenceFrameworkId
                      );
                    return (
                      <React.Fragment
                        key={`subIndex${subIndex}${child.questionCategoryId}`}
                      >
                        {Pillar &&
                          SubPillar &&
                          MaturityAssessment &&
                          QuesAnsType &&
                          // Unit &&
                          paiframework &&
                          DataConvergenceFramework ? (
                          <>
                            <StyledTableRow
                              key={`subIndex${subIndex}${child.questionCategoryId}`}
                            >
                              <StyledTableCell style={{ width: "4ch" }}>
                                {child.children &&
                                  child.children.length > 0 && (
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        handleRowToggle(
                                          `subIndex${subIndex}${child.questionCategoryId}`
                                        )
                                      }
                                    >
                                      {openRowIndex.includes(
                                        `subIndex${subIndex}${child.questionCategoryId}`
                                      ) ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{Pillar && Pillar.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{SubPillar && SubPillar.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>
                                  {MaturityAssessment &&
                                    MaturityAssessment.name}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{child.questionText}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                {child.hasSubQuestions == true
                                  ? child.subQuestion.map((subques, index) => {
                                    return (
                                      <span key={index}>
                                        <Chip
                                          label={subques.text}
                                          variant="outlined"
                                        />
                                      </span>
                                    );
                                  })
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{QuesAnsType && QuesAnsType.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{child.guidance || "-"}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.measurementUnitId || "-"}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{PaiFramework && PaiFramework.name}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>
                                  {DataConvergenceFramework &&
                                    DataConvergenceFramework.name}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.importHistoricData.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.isStatic.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.rating.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "0" }}
                              >
                                <div>
                                  {" "}
                                  <span>
                                    {" "}
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      p={2}
                                    >
                                      <div
                                        className={
                                          classes.iconButtonMainContainer
                                        }
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            handleEditOpen(e, `subIndex${subIndex}${child.questionCategoryId}`);
                                          }}
                                          key={subIndex}
                                          disabled={
                                            singleSurvey &&
                                            singleSurvey[0] &&
                                            (singleSurvey[0].status == 1 ||
                                              singleSurvey[0].status == 2)
                                          }
                                        >
                                          <MoreVert></MoreVert>
                                        </IconButton>
                                        <Popover
                                          open={popoverStates[`subIndex${subIndex}${child.questionCategoryId}`]}
                                          id={popoverId}
                                          anchorEl={anchorEl}
                                          onClose={() => {
                                            handleEditClose(`subIndex${subIndex}${child.questionCategoryId}`);
                                          }}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <List>
                                            <ListItem
                                              onClick={() => {
                                                openEditESGForm(
                                                  child,
                                                  Pillar,
                                                  SubPillar,
                                                  MaturityAssessment,
                                                  QuesAnsType,
                                                  PaiFramework,
                                                  DataConvergenceFramework,
                                                  subIndex
                                                );
                                              }}
                                            >
                                              <ListItemIcon
                                                style={{
                                                  width: "35px",
                                                  margin: "0 0 0 5px",
                                                  minWidth: "0",
                                                }}
                                              >
                                                <Edit />
                                              </ListItemIcon>
                                              <ListItemText primary="Edit" />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                              onClick={() => {
                                                setDeletePopUp(true),
                                                  setQuestionItem(child);
                                              }}
                                            >
                                              <ListItemIcon
                                                style={{
                                                  width: "35px",
                                                  margin: "5px 0 0 5px",
                                                  minWidth: "0",
                                                }}
                                              >
                                                <DeleteIcon />
                                              </ListItemIcon>
                                              <ListItemText
                                                primary="Remove"
                                                style={{ marginRight: "8px" }}
                                              />
                                            </ListItem>
                                          </List>
                                        </Popover>
                                      </div>
                                    </Box>
                                  </span>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                                colSpan={17}
                                className={classes.table}
                              >
                                {child.children &&
                                  child.children.length > 0 &&
                                  renderNestedList(
                                    child,
                                    `subIndex${subIndex}${child.questionCategoryId}`
                                  )}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                No Child Found
                              </span>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Collapse>
    );
  };

  return (
    <div>
      <div>
        <SurveyFilter
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
        />
      </div>
      <div>
        <SurveyFilterYear
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          setSurveyFilterYear={setSurveyFilterYear}
        />
      </div>
      <div>
        <A_Snackbar
          open={successDelete}
          message={"Successfully Removed"}
          autoHideDuration={1000}
          horizontal="center"
          handleClose={handleClose}
        />
        <A_Snackbar
          open={failure}
          message={erromsg}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleClose}
          type="error"
        />
        <div>
          <CommonFilter
            selectedDataValue={selectedPillarValue}
            list={filterPillarTextValues}
            setSelectedDataValue={handleSelectedPillarChange}
            defaultText={"All Pillars"}
            style={{
              position: "absolute",
              top: "220px",
              left: "500px",
              border: "1px solid black",
              backgroundColor: "#ffff",
              width: "150px",
              height: "38px",
              borderRadius: "3px",
            }}
          />
        </div>
        <div>
          <CommonFilter
            selectedDataValue={selectedSubPillarValue}
            list={filterSubPillarTextValues}
            setSelectedDataValue={setSelectedSubPillarValue}
            defaultText={"All Subpillars"}
            style={{
              position: "absolute",
              top: "220px",
              left: "700px",
              border: "1px solid black",
              backgroundColor: "#ffff",
              width: "150px",
              height: "38px",
              borderRadius: "3px",
            }}
          />
        </div>
        <div>
          {singleSurvey &&
            singleSurvey[0] &&
            (singleSurvey[0].status == 1 || singleSurvey[0].status == 2) && (
              <Alert severity="info">
                This questionnaire has already been published/closed. Hence the
                questions cannot be modified or removed.
              </Alert>
            )}
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead className={scrolling ? classes.fixedHeader : ""}>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                {tableHeader.map((item, index) => {
                  return (
                    <StyledTableCell key={index}>
                      <div
                        style={{ width: "25ch", position: "sticky", zIndex: 1 }}
                      >
                        {item}
                      </div>
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((item, index) => {
                  const Pillar = pillar.find(
                    (element) =>
                      element.questioncategoryid === item.questionCategoryId
                  );

                  const SubPillar = subPillardata.find(
                    (element) =>
                      element.questionsubcategoryid ===
                      item.questionSubCategoryId
                  );
                  const MaturityAssessment = maturityassessment.find(
                    (element) =>
                      element.maturityassessmentid === item.maturityAssessmentId
                  );
                  const QuesAnsType = questionanstype.find(
                    (element) =>
                      element.questionanswertypeid === item.questionAnswerTypeId
                  );
                  const PaiFramework = paiframework.find(
                    (element) => element.paiframeworkid === item.paiFrameworkId
                  );
                  const DataConvergenceFramework =
                    dataconvergenceframework.find(
                      (element) =>
                        element.dataConvergenceframeworkid ===
                        item.dataConvergenceFrameworkId
                    );
                  return (
                    <React.Fragment key={`index${index}`}>
                      {Pillar &&
                        SubPillar &&
                        MaturityAssessment &&
                        QuesAnsType &&
                        paiframework &&
                        DataConvergenceFramework ? (
                        <>
                          <StyledTableRow key={`index${index}`}>
                            <StyledTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleRowToggle(`index${index}`)}
                              >
                                {openRowIndex.includes(`index${index}`) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{Pillar && Pillar.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{SubPillar && SubPillar.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>
                                {MaturityAssessment && MaturityAssessment.name}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{item.questionText}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.hasSubQuestions == true
                                ? item.subQuestion.map((subques, index) => {
                                  return (
                                    <span key={index}>
                                      <Chip
                                        label={subques.text}
                                        variant="outlined"
                                      />
                                    </span>
                                  );
                                })
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{QuesAnsType && QuesAnsType.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{item.guidance || "-"}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.measurementUnitId || "-"}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{PaiFramework && PaiFramework.name}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>
                                {DataConvergenceFramework &&
                                  DataConvergenceFramework.name}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.importHistoricData.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.isStatic.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.rating.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>
                                {" "}
                                <span>
                                  {" "}
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    p={2}
                                  >
                                    <div
                                      className={
                                        classes.iconButtonMainContainer
                                      }
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleEditOpen(e, item.questionId ,singleSurvey);
                                        }}
                                        key={index}
                                        disabled={
                                          singleSurvey &&
                                          singleSurvey[0] &&
                                          (singleSurvey[0].status == 1 ||
                                            singleSurvey[0].status == 2)
                                        }
                                      >
                                        <MoreVert></MoreVert>
                                      </IconButton>
                                      <Popover
                                        open={popoverStates[item.questionId]}
                                        id={popoverId}
                                        anchorEl={anchorEl}
                                        onClose={() => {
                                          handleEditClose(item.questionId);
                                        }}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <List>
                                          <ListItem
                                            onClick={() => {
                                              openEditESGForm(
                                                item,
                                                Pillar,
                                                SubPillar,
                                                MaturityAssessment,
                                                QuesAnsType,
                                                PaiFramework,
                                                DataConvergenceFramework,
                                                index
                                              );
                                            }}
                                          >
                                            <ListItemIcon
                                              style={{
                                                width: "35px",
                                                margin: "0 0 0 5px",
                                                minWidth: "0",
                                              }}
                                            >
                                              <Edit />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" />
                                          </ListItem>
                                          <Divider />
                                          <ListItem
                                            onClick={() => {
                                              setDeletePopUp(true),
                                                setQuestionItem(item);
                                            }}
                                          >
                                            <ListItemIcon
                                              style={{
                                                width: "35px",
                                                margin: "5px 0 0 5px",
                                                minWidth: "0",
                                              }}
                                            >
                                              <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary="Remove"
                                              style={{ marginRight: "8px" }}
                                            />
                                          </ListItem>
                                        </List>
                                      </Popover>
                                    </div>
                                  </Box>
                                </span>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                              }}
                              colSpan={15}
                              className={classes.table}
                            >
                              {item.children &&
                                item.children.length > 0 &&
                                renderNestedList(item, `index${index}`)}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              No Data Found
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      No Data Found
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Backdrop style={{ color: "#fff", zIndex: 999 }} open={isLoading}>
        <CircularProgress
          color="inherit"
          style={{ color: "#fff", zIndex: 999 }}
        />
      </Backdrop>
      <A_SimpleDialog
        id="2"
        open={isOpen}
        closePopUp={handlePopup}
        title="Delete"
        dialogContent={message.DELETE_WARNING}
        dialogActions={getActionItems()}
        onClose={handleCancelDelete}
      />
      {filteredData.length > 0 && (
        <div style={{ textAlign: "right" }}>
          <A_Button
            textAlign={"right"}
            label={"Publish"}
            style={{ margin: "6px 0px" }}
            color={"primary"}
            onClick={handlePublishPopup}
            disabled={
              isPublishedDisabled ||
              (singleSurvey &&
                singleSurvey[0] &&
                (singleSurvey[0].status == 1 || singleSurvey[0].status == 2 || singleSurvey[0].status == 3))
            }
          ></A_Button>
        </div>
      )}
      <Dialog
        open={openEditForm}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleESGEditClose();
          }
        }}
        // onClose={handleESGEditClose}
        PaperProps={{ style: { width: "100%" } }}
        BackdropProps={{ invisible: true }}
      >
        <div className={classes.addForm}>
          <div>
            <DialogTitle>Edit ESG Question</DialogTitle>
          </div>
          <div>
            <CloseIcon
              onClick={() => {
                // handleClosePopToggle()
                handlePopupClose();
              }}
              className="closeIcon"
            />
          </div>
        </div>
        <EditQuestionForm
          fullFormData={fullFormData}
          pillar={pillar}
          subPillar={subPillardata}
          maturityassessment={maturityassessment}
          parentquestion={parentquestion}
          paiframework={paiframework}
          dataconvergenceframework={dataconvergenceframework}
          listtype={listtype}
          handleListDelete={handleListDelete}
          handleChange={handleEditFormChange}
          setGuidancetext={setGuidancetext}
          itemType={itemType}
          handleAddType={handleAddType}
          handleSubmit={handleEditFormSubmit}
        />
      </Dialog>
      <A_Snackbar
        open={success}
        message={erromsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
      <A_SimpleDialog
        open={deletePopUp}
        dialogContent=""
        closePopUp={handleDeletePopUp}
        title={`Are you sure you want to remove this question from this questionnaire?`}
        dialogActions={getDeleteActionItem()}
        onClose={handleDeletePopUpCancel}
      />
      <A_SimpleDialog
        id="2"
        open={isPublishOpen}
        dialogContent=""
        closePopUp={handlePublishPopup}
        title={`Are you sure you want to publish the questionnaire for the year ${filtersurveyyear} across all the assets?`}
        dialogActions={getPublishActionItems()}
        onClose={handlePublishCancelPublish}
      />
      <A_SimpleDialog
        id="3"
        open={isOpenBox}
        closePopUp={handleQuestionClosePopup}
        dialogContent={message.ARE_YOU_SURE}
        title="Do you want to close?"
        dialogActions={getPopActionItems()}
        onClose={handleQuestionClosePopup}
      />
      <A_SimpleDialog
        open={isPublishedPopup}
        dialogContent=""
        closePopUp={() => setIsPublishedPopup(false)}
        title={`You cannot edit this question as it is present in the published survey.`}
        dialogActions={getActionItemsIsPublished()}
        onClose={() => setIsPublishedPopup(false)}
      />
    </div>
  );
};

ESG_QuestionnaireSetupView.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  setEdit: PropTypes.any,
  singleSurvey: PropTypes.any,
  setSingleSurvey: PropTypes.any,
};

export default ESG_QuestionnaireSetupView;
