import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import "../ESG.css";
import {  CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as esgApi from "../ESG_API/index";
import {color} from "../ESG_Config"

const useStyles = makeStyles(() => ({
  leftPanel: {
    width: "50%",
    marginLeft: "30px",
    maxHeight: "calc(100vh - 20px)", // Adjust the max height as needed
    fontSize: "13px", 
  },
  heading: {
    fontSize: "15px", 
    marginBottom: "5px", // Adjust spacing between heading and statements
    display: "flex",
    alignItems: "center" 
  },
  statement: {
    margin: 0,
    fontSize: "12px", // Adjust text size as needed
    fontWeight:"600",
    lineHeight: "1.2", // Adjust line height as needed
  },
  setContainer:{
  maxHeight:"20ch",
  overflow:"hidden",
  overflowY:"auto",
  whiteSpace: "normal",
  wordWrap: "break-word",
  padding:"12px",
  textOverflow: "ellipsis",
  },
  bullet: {
    display: "inline-block",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "black",
    marginRight: "8px", 
  },
  dashline: {
    display:"flex",
    alignItems:"center",
   fontSize: "20px",
   fontWeight:"bold"
  },
  rightPanel:{
    width: "50%",
    marginLeft: "20px",
    maxHeight: "calc(100vh - 20px)", 
    fontSize: "14px",
    marginBottom: "20px",
    display:"flex",
    alignItems:"center"
  },
  chartCss:{
    margin:"10px",
    width:"40%"
  }
}));

const getColorForMaturityId = (id) => {
  switch (id) {
    case 1:
      return "purple";
    case 2:
      return color.yellow;
    case 3:
      return color.skyblue;
    default:
      return "grey"; 
  }
};

const ESG_CurrentPracticesView = ({ selectedSurveyId, selectedSubPillarId, selectedSectorId}) => {
  const classes = useStyles();
  const [currentPracticeResponse, setCurrentPracticeResponse] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [showError, setShowError]=useState(false);
  const [showErrorChart, setShowErrorChart]=useState(false);
 
  const BarChart = ({data }) => {
    const assetCount=data.totalInvestment;
    const list=data.maturityAssessmentResponses;
    const options = {
      chart: {
        type: "column",
        height:300,
      },
      title: {
        text: null
      },
      xAxis: {
        categories: ["Total Asset Count"].concat(list.map(item => item.maturityAssessmentName)),
      },
      yAxis: {
        title:{text:null},
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        min: 0, // Set the maximum scale value for the yAxis
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            color: "black",
            style: {
              textOutline: "none"
            },
            formatter: function () {
              return this.y; 
            },
            y: -12 
          }
        }
      },
      series:[{
        name: "Count",
        data:[{
          y: parseInt(assetCount),
          name: "Total Asset Count",
          color: color.blue
            }].concat( list.map(item => ({
              y: parseInt(item.count),
              name: item.maturityAssessmentName,
              color: getColorForMaturityId(item.investmentid)
            }))),
         }],
         credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        }
    }
    return <HighchartsReact highcharts={Highcharts} options={options} oneToOne={true} />;
  }


  useEffect(() => {
    // load current practice data
    if (selectedSurveyId != "" && selectedSubPillarId != "") {
      const getCurrentPracticesResponses = async () => {
        setLoadingTable(true);
        let response = await esgApi.getCurrentPracticeData(selectedSurveyId, selectedSubPillarId);
        if(response && response.data){
          response.data=response.data.sort((a, b) => a?.maturityAssessmentId - b?.maturityAssessmentId);
          setCurrentPracticeResponse(response.data);
        }else{
          setShowError(true);
          setCurrentPracticeResponse([]);
        }
        setLoadingTable(false);
      };
      getCurrentPracticesResponses();
    }
  }, [selectedSurveyId, selectedSubPillarId]);

  useEffect(()=>{
    // load chart Data
    if (selectedSurveyId != "" && selectedSubPillarId != "") {
      const getChartDataResponses = async () => {
        setLoadingChart(true);
        let response = await esgApi.getCurrentPracticeChartData(selectedSurveyId,selectedSubPillarId,selectedSectorId);
      // Calculate the count for each maturity assessment
       if(response &&  response.maturityAssessmentResponses){
        response.maturityAssessmentResponses.forEach((item) => {
         item.count = item.currentPracticesInvestmentResponses.filter((investment) => parseFloat(investment.averageResponseScore) > 0).length; 
        });
         setChartData(response);
        }else{
          setShowErrorChart(true)
          setChartData(null);
        }
       setLoadingChart(false);
      };
      getChartDataResponses();
    }
}, [selectedSurveyId, selectedSubPillarId, selectedSectorId]);


  return (
    <div>
     { selectedSurveyId?(
      <div style={{ display: "flex", width: "100%" ,overflowY:"auto" }}>

       <div className={classes.leftPanel}>
          {!loadingTable && currentPracticeResponse.length !==0 ? (
           currentPracticeResponse.map((item) => (
            <div key={item.maturityAssessmentId} style={{ marginBottom: "20px" }}>
              <h4 className={classes.heading}>
              <span className={classes.bullet}></span>
                <span style={{marginLeft: "5px",color: getColorForMaturityId(item.maturityAssessmentId)}} >
                  {item.maturityAssessmentName}
                </span><div className={classes.dashline}> - </div>
              </h4>
              <div className={classes.setContainer}>
                 {item?.questions.filter((question) => question.dashboardStatements !== null && question.dashboardStatements !== "" )
                 .map((question) => (
                  <p className={classes.statement} key={question.questionId}>
                    {question.dashboardStatements}</p>
                 ))}
              </div>
           </div> 
           ))
         ):( loadingTable?(
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
             <CircularProgress />
             </div>
                 ):( showError &&
                    <Alert severity="error" 
                      style={{
                      width: "auto",
                      marginBottom: "10px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                     }}>
                   There is no response to show as no rated questions found.
                   </Alert>
                 ))}
        </div>
        <div className={classes.rightPanel}>
       {!loadingChart && chartData? (
         <div>
            <BarChart data={chartData?chartData:[]} />
         </div>
          ):(
            loadingChart?(
               <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
               <CircularProgress />
               </div>
                ):( showErrorChart &&
                  <Alert severity="error" 
                   style={{
                   width: "auto",
                   marginBottom: "10px",
                   textAlign: "center",
                   display: "flex",
                   justifyContent: "center",
                  }}>
                   There is no response to show,no Rating questions are found for this survey.
                 </Alert>
               ))}
        </div>   
          
      </div>
    
    ):( 
      <Alert severity="info">
        All active surveys are either live or unpublished.
       </Alert>
      )}
  
  </div>
  );
};

ESG_CurrentPracticesView.propTypes = {
  selectedSurveyId:PropTypes.any, 
  selectedSubPillarId:PropTypes.any, 
  selectedSectorId:PropTypes.any
}
export default ESG_CurrentPracticesView;
