import React from "react";
import "./layout.scss";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card.jsx";
import ExcelTemplate from "../ExcelTemplate/ExcelTemplate.jsx";
import ReportEditor from "../ReportEditor/MainPage/ReportEditor.jsx";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary.jsx";
import O_Pipeline from "../../../Organisms/Common/Pipeline/O_Pipeline.jsx";
import PipeLineFilter from "../../../Organisms/Common/Pipeline/PipeLineFilter.jsx";
import KpiCallBox from "../../../Organisms/Common/KpiCallBox/KpiCallBox.jsx";
import M_DealStatusBar from "../../../Molecules/Common/StatusBar/M_StatusBar.jsx";
import EsgHeader from "../Esg/EsgHeader.jsx";
import EsgResponsePage from "../Esg/EsgResponse/EsgResponsePage.jsx";
import { articleTypes, contentTypes } from "./layout.Helper.js";
import O_ConsentRequest from "../../../Organisms/Common/ConsentRequest/O_ConsentRequest.jsx";
import KpiSqlBox from "../../../Organisms/Common/KpiCallBox/KpiSqlBox.jsx";
import M_CustomCards from "./CustomCards/M_CustomCards.jsx";
import showContentConditional from "./showContentConditional.jsx";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button.jsx";
import { externalTypes } from "./layout.Helper.js";

import { CardContext } from "./Card_Context.jsx";
function GetArticle({ item, device, index, isReadOnly = false, parentThis }) {
  // Manage context for all child
  const [context, setContext] = React.useState({});
  const alterGlobalContext = (contextValues) => {
    let updatedContext = { ...context, ...contextValues };
    setContext(updatedContext);
    console.log("current context is:", updatedContext);
  };
  const removeContextValue = (keyToRemove) => {
    let tempContext = context;
    delete tempContext[keyToRemove];
    setContext(tempContext);
  };

  const {
    title,
    toolbar,
    showPic,
    chartFilter,
    addFiles,
    boldTitle,
    show = true,
    syncSlot = false,
  } = item.header;
  const { type, content } = item.content;
  if (
    item.content &&
    item.content.customCardName &&
    item.content.customCardName == "meetingSync"
  ) {
    if (
      parentThis.props.userid !== parentThis.props.params.crmId ||
      !parentThis.props.isMinervaUser
    ) {
      return "";
    }
  }
  if (
    item.content &&
    item.content.customCardName &&
    item.content.customCardName == "meetingSyncEA"
  ) {
    if (
      parentThis.props.userid !== parentThis.props.params.crmId ||
      !parentThis.props.isEA
    ) {
      return "";
    }
  }
  if (
    type === contentTypes.APPROVE_AND_REJECT &&
    !parentThis.state.shouldShowApproveRejectWindow
  ) {
    return "";
  }
  if (
    type === contentTypes.PORTFOLIOAPPROVAL &&
    !parentThis.state.shouldShowApprovePortfolio
  ) {
    return "";
  }
  if (
    type === contentTypes.SUBMISSION_REVIEW &&
    !parentThis.state.shouldShowsubmissionWindow
  ) {
    return "";
  }
  const renderCardStyle = (item, device) => {
    const resultObject = item.position[device];
    if (
      device &&
      (device === "sm" || device === "xs") &&
      resultObject &&
      resultObject["grid-column"]
    ) {
      let mobileConfig = { "grid-column": "span 12" };
      return mobileConfig;
    }
    return resultObject;
  };

  const handleFooter = (footer) => {
    if (footer.type === externalTypes.BUTTON) {
      return footer.content.map((button, index) => {
        return <A_Button {...button} key={index} />;
      });
    } else {
      return footer.content;
    }
  };
  const handleComponentRendering = () => {
    switch (type.toLowerCase()) {
      case articleTypes.DEALSTATUSBAR.toLowerCase():
        return <M_DealStatusBar content={content} />;
      case articleTypes.REPORT_EDITOR.toLowerCase():
        return <ReportEditor />;
      case articleTypes.ESG.toLowerCase():
        return <EsgHeader />;
      case articleTypes.ESGRESPONSE.toLowerCase():
        return <EsgResponsePage />;
      case articleTypes.EXCEL_EDITOR.toLowerCase():
        return (
          <ExcelTemplate
            isExcelTemplateDownload={parentThis.props.isExcelTemplateDownload}
            handleClosePopup={parentThis.props.handleClosePopup}
          />
        );
      case articleTypes.KPI_CALL_BOX.toLowerCase():
        return <KpiCallBox item={item} />;
      case articleTypes.KPI_SQL_BOX.toLowerCase():
        return <KpiSqlBox item={item} />;
      case articleTypes.PIPE_LINE_FILTER.toLowerCase():
        return (
          <PipeLineFilter
            item={item}
            pipeLineDealsControll={parentThis.pipeLineDealsControll}
          />
        );
      case articleTypes.DEALS_PIPE_LINE.toLowerCase():
        return (
          <O_Pipeline
            item={item}
            dealData={parentThis.state.pipelineData}
            imageData={parentThis.state.pipelineImages}
          />
        );
      case articleTypes.CONSENTREQUEST.toLowerCase():
        return (
          <O_ConsentRequest
            title={title ? title : ""}
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
          />
        );
      case articleTypes.CUSTOMCARD.toLowerCase():
        return (
          <M_CustomCards
            content={content}
            item={item}
            stateObj={parentThis.state}
          />
        );
      default:
        return (
          <A_Card
            currentPage={
              parentThis.props &&
              parentThis.props.currentPage &&
              parentThis.props.currentPage.data
            }
            elevation={2}
            width={50}
            header={{
              title: title ? title : "",
              boldTitle: boldTitle ? boldTitle : "",
              chartFilter: chartFilter ? chartFilter : "",
              addFiles: addFiles ? addFiles : "",
              showPic: showPic ? showPic : false,
              show,
              isReadOnly,
              // zoom: toolbar && toolbar.zoom ? toolbar.zoom : false,
              // parentThis feature is not require now, but i am keeping it commented as it may require in future
              secondaryMenu: false,
              optionAdd: parentThis.state.optionOnAdd,
              addParameterOption:
                item &&
                item.header &&
                item.header.toolbar &&
                item.header.toolbar.optionOnAdd
                  ? item.header.toolbar.optionOnAdd.parameters
                  : {},
              callBack: parentThis.handleClickOptionAdd,
              add:
                toolbar && toolbar.add && toolbar.add.show
                  ? toolbar.add.show
                    ? isReadOnly !== true
                    : false
                  : false,
              optionOnAdd:
                toolbar && toolbar.optionOnAdd && toolbar.optionOnAdd.show
                  ? toolbar.optionOnAdd.show
                  : false,
              edit:
                toolbar && toolbar.edit && toolbar.edit.show
                  ? toolbar.edit.show
                    ? isReadOnly !== true
                    : false
                  : false,
              export:
                toolbar && toolbar.export && toolbar.export.show
                  ? toolbar.export.show
                  : false,
              syncSlot,
            }}
            action={
              item.footer && item.footer.content
                ? handleFooter(item.footer)
                : ""
            }
            ApproveButtons={
              item.footer && item.footer.approvalButtons === true
                ? item.footer.approvalButtons
                : ""
            }
            content={
              <ErrorBoundary>
                {content
                  ? showContentConditional(
                      type,
                      content,
                      item,
                      chartFilter,
                      parentThis
                    )
                  : item.fallbackMsg}
              </ErrorBoundary>
            }
            handleZoom={() => parentThis.handleZoom(toolbar, item)}
            handleEdit={() => parentThis.handleEdit(toolbar, item, content)}
            handleAdd={() => parentThis.handleAdd(toolbar, item, content)}
            handleOptionOnAdd={() =>
              parentThis.handleOptionOnAdd(toolbar, item, content)
            }
            handleDeleteOnAdd={() =>
              parentThis.handleDeleteOnAdd(toolbar, item, content)
            }
            handleExport={() => parentThis.handleExport(toolbar, item, content)}
            handleClickOptionAdd={parentThis.handleClickOptionAdd}
          />
        );
    }
  };
  return (
    <article
      key={index}
      style={
        item && item.position
          ? renderCardStyle(item, device)
          : { gridColumn: "span 3" }
      }
    >
      <ErrorBoundary>
        <CardContext.Provider
          value={{
            cardContentConfig: content,
            context,
            alterGlobalContext,
            removeContextValue,
          }}
        >
          <>{handleComponentRendering()}</>
        </CardContext.Provider>
      </ErrorBoundary>
    </article>
  );
}

GetArticle.propTypes = {};

export default GetArticle;
