import React from "react";
import { Box, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import { css } from "./Helper/M_MultiValueList.css";
import M_ActionGroup from "../ActionGroup/M_ActionGroup";
import propTypes from "prop-types";
import { conditionBasedRendering } from "../../../Organisms/Common/GenericList/Helper/GenericList.Helper";
import A_Chip from "../../../Atoms/DataDisplay/Chips/A_Chip";
const useStyles = makeStyles(css);
function M_MultiValueList(props) {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.ircHead}  ${
        props.selected ? classes.selected : ""
      }`}
      onClick={() => {
        props.onClick && props.clickOnMore !== false
          ? props.onClick(props.data)
          : () => {};
      }}
    >
      <Box
        className={classes.itemCenter}
        onClick={() => {
          props.onClick && props.clickOnMore === false
            ? props.onClick(props.data)
            : () => {};
        }}
      >
        {props.title ? (
          <Box className={classes.innerBox}>
            <div>
              {props.title === "document" ? (
                <Avatar className={classes.icon}>
                  {props.heading.substring(0, 1)}
                </Avatar>
              ) : (
                <img src={props.title} className={classes.icon} />
              )}
            </div>
          </Box>
        ) : (
          ""
        )}
        <Box className={classes.content}>
          {props.parentHeading && (
            <Typography className={classes.link}>
              {props.parentHeading}
            </Typography>
          )}
          <Typography className={classes.link}>
            {props.redirectionURL ? (
              <a
                className={classes.link}
                href={props.redirectionURL}
                target="_blank"
                rel="noreferrer"
              >
                <span>{props.heading}</span>
              </a>
            ) : (
              <span>
                {props.heading &&
                (typeof props.heading === "string" ||
                  Array.isArray(props.heading))
                  ? props.heading
                  : conditionBasedRendering(props.heading)}
              </span>
            )}{" "}
            {props.suffix && <span> {props.suffixSeparator || ""} {props.suffix}</span>}
          </Typography>
          <Typography style={{ color: "grey" }}>
            <span>
              {props.subHeading &&
              (typeof props.subHeading === "string" ||
                Array.isArray(props.subHeading))
                ? props.subHeading
                : conditionBasedRendering(props.subHeading)}
            </span>
          </Typography>

          <Typography style={{ color: "grey", margin: "0.2rem 0" }}>
            <span>
              {props.tags && typeof props.tags === "string" ? (
                <A_Chip label={props.tags} />
              ) : Array.isArray(props.tags) ? (
                props.tags.map((tag) =>
                  conditionBasedRendering(tag) ? (
                    <A_Chip
                      label={
                        conditionBasedRendering(tag) == true
                          ? "Active"
                          : conditionBasedRendering(tag) == true
                      }
                    />
                  ) : (
                    ""
                  )
                )
              ) : (
                conditionBasedRendering(props.tags)
              )}
            </span>
          </Typography>
        </Box>
      </Box>

      {props.moreOptions && (
        <M_ActionGroup
          {...props}
          label={
            props.deleteLabel
              ? props.deleteLabel
              : props.label
              ? props.label
              : ""
          }
        ></M_ActionGroup>
      )}
    </Box>
  );
}
M_MultiValueList.propTypes = {
  selected: propTypes.bool,
  onClick: propTypes.func,
  clickOnMore: propTypes.any,
  data: propTypes.any,
  title: propTypes.any,
  heading: propTypes.any,
  subHeading: propTypes.any,
  redirectionURL: propTypes.any,
  deleteLabel: propTypes.any,
  label: propTypes.any,
  moreOptions: propTypes.any,
  tags: propTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_MultiValueList);
