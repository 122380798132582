/* eslint-disable */
import React from "react";
import "./layout.scss";
import _ from "lodash";
import throttle from "lodash/throttle";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card";
import InlineRecords from "../../../../../Assets/Images/Defaults/inline-records.JPG";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog.jsx";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button.jsx";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import { connect } from "react-redux";
import { addDynamicValueforHeader } from "../../../../../Utils/Helpers/generciParamHelper";
import PropTypes from "prop-types";
import { clearChartKeys } from "../PageLayout/P_PageLayout.Helper";
import { deviceTypes, externalTypes } from "./layout.Helper";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
import { portfolioapproval } from "../../../Molecules/Common/PageHeaders/Toolbar/M_ToolbarFilterhelper.js";
import { resetFiltersValue } from "../../../../../store/actions/manageFilterValueAction.js";
import GetArticle from "./getArticle.jsx";
class Layout extends React.Component {
  constructor(props) {
    super(props);
    let device = "";
    if (window.innerWidth < 600) {
      device = "xs";
    } else if (window.innerWidth >= 600) {
      device = "md";
    }
    this.state = {
      device: device,
      openCardZoom: false,
      openEditForm: false,
      extraEditParams: "",
      extraAddParams: "",
      currentEditParams: "",
      openAddForm: false,
      openExport: false,
      formEntity: {},
      paramEntity: {},
      templateId: {},
      recordId: null,
      isIRCEdit: false,
      optionOnAdd: false,
      deleteOnAdd: false,
      searchPipeLine: null,
      pipelineData: null,
      pipelineImages: null,
      shouldShowApproveRejectWindow: false,
      shouldShowApprovePortfolio: true,
      shouldShowsubmissionWindow: false,
      approverstage: null,
      approversubmission: null,
      fundaccountantVal: true,
      submissionVal: true,
      addEdge: null,
      editData: {},
      selectedTab: "",
      newSavedData: [],
      isEditing: false,
      QuarterCode: "",
      currentPathName: localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
        ? localStorage.getItem(portfolioapproval.CURRENTPATHNAME)
        : "",
    };
    this.pipeLineDealsControll = this.pipeLineDealsControll.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleOptionOnAdd = this.handleOptionOnAdd.bind(this);
    this.handleDeleteOnAdd = this.handleDeleteOnAdd.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleRecordID = this.handleRecordID.bind(this);
    this.handleClickOptionAdd = this.handleClickOptionAdd.bind(this);
    this.onChangeNextApprover = this.onChangeNextApprover.bind(this);
    this.onChangeSubmission = this.onChangeSubmission.bind(this);
  }

  pipeLineDealsControll(obj) {
    if (obj.type == externalTypes.PIPELINE_DATA) {
      this.setState({ pipelineData: obj.data });
    } else if (obj.type == externalTypes.IMAGE_DATA) {
      this.setState({ pipelineImages: obj.data });
    } else {
      this.setState({ searchPipeLine: obj.data });
    }
  }

  handleZoom(card, item) {
    this.setState((prevState) => {
      return {
        openCardZoom: !prevState.openCardZoom,
        cardProps: prevState.openCardZoom === true ? null : item,
      };
    });
  }
  updateEditData = (newEditData) => {
    this.setState({ editData: newEditData });
  };
  updateSelectedTab = (newTab) => {
    this.setState({ selectedTab: newTab });
  };
  updateQuarterCode = (newQuarter) => {
    this.setState({ QuarterCode: newQuarter });
  };
  updateSavedData = (newData) => {
    this.setState({ newSavedData: newData });
  };
  startEditing = () => {
    this.setState({ isEditing: true });
  };
  endEditing = () => {
    this.setState({ isEditing: false });
  };
  handleEdit(card, item) {
    const templateID = card.edit.parameters.templateId;
    let type = card.edit && card.edit.type ? card.edit.type : "";
    const { toolbarSelections } = this.props;
    let FilterParams = {};
    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }
    FilterParams["id"] = this.props.params ? this.props.params.crmId : "";
    let recId = this.state.isIRCEdit ? this.state.recordId : null;
    const NewData = {
      templateId: templateID,
      recordId: recId,
      type: type.toLowerCase() == "sql" ? "sql" : "",
      FilterParams: FilterParams,
    };
    let addEdgeRequired =
      card && card.edit && card.edit.parameters && card.edit.parameters.addEdge
        ? card.edit.parameters.addEdge
        : null;

    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateID
    ) {
      this.props.resetManagePage();
    }

    if (type.toLowerCase() == "sql") {
      if (
        this.props.prevEntity &&
        this.props.prevEntity.recordId &&
        this.props.prevEntity.recordId !== this.props.params.crmId
      ) {
        this.props.resetManagePage();
      }
    }
    this.setState((prevState) => {
      return {
        openEditForm: !prevState.openEditForm,
        cardProps: prevState.openEditForm === true ? null : item,
        paramEntity: NewData,
        addEdge: addEdgeRequired,
        extraEditParams: card && card.edit && card.edit.parameters,
      };
    });
  }
  handleAdd(card, item) {
    let type = card.add && card.add.type ? card.add.type : "";
    const { toolbarSelections } = this.props;
    let FilterParams = {};
    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }
    const data = this.props.currentPage.pageConfig;
    const templateId = card.add.parameters.templateId;
    const parameters = addDynamicValueforHeader(
      card.add.parameters,
      this.props.params
    );
    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateId
    ) {
      this.props.resetManagePage();
    }

    let recordId = "";
    FilterParams["id"] = this.props.params ? this.props.params.crmId : "";
    const NewData = {
      templateId,
      data,
      parameters,
      recordId,
      type: type.toLowerCase() == "sql" ? "sql" : "",
      FilterParams: FilterParams,
    };

    this.setState((prevState) => {
      return {
        openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : item,
        templateId: templateId,
        paramEntity: NewData,
        extraAddParams: card && card.edit && card.edit.parameters,
      };
    });
  }
  handleOptionOnAdd() {
    this.setState(() => {
      return {
        optionOnAdd: true,
      };
    });
  }
  handleDeleteOnAdd() {
    this.setState(() => {
      return {
        optionOnAdd: false,
      };
    });
  }

  handleCloseForm() {
    this.setState(() => {
      return {
        openEditForm: false,
        openAddForm: false,
        // cardProps: null,
        // paramEntity: null,
      };
    });
  }
  handleExport(card, item) {
    this.setState((prevState) => {
      return {
        openExport: !prevState.openExport,
        cardProps: prevState.openExport === true ? null : item,
      };
    });
  }
  handleRecordID(recId) {
    this.setState(() => {
      return {
        recordId: recId,
        isIRCEdit: true,
      };
    });
  }

  handleClickOptionAdd(item) {
    let type = item && item.type ? item.type : "";
    const { toolbarSelections } = this.props;

    let FilterParams = {};

    if (
      type &&
      type.toLowerCase() == "sql" &&
      toolbarSelections &&
      toolbarSelections.filters &&
      Array.isArray(toolbarSelections.filters) &&
      toolbarSelections.filterLength > 0 &&
      toolbarSelections.filterLength === toolbarSelections.filters.length &&
      toolbarSelections.filters.length > 0
    ) {
      toolbarSelections.filters.forEach((item) => {
        FilterParams[item.filterName] = item.value;
      });
    }

    const data =
      this.props.currentPage && this.props.currentPage.pageConfig
        ? this.props.currentPage.pageConfig
        : {};
    const templateId = item.templateId;
    const parameters = addDynamicValueforHeader(item, this.props.params);
    let recordId = "";
    const NewData = {
      templateId,
      data,
      parameters,
      recordId,
    };
    if (type.toLowerCase() == "sql") {
      NewData["id"] = this.props.params ? this.props.params.crmId : "";
      NewData["type"] = type;
      NewData["FilterParams"] = FilterParams;
    }
    if (
      this.props.prevEntity &&
      this.props.prevEntity.templateId !== templateId
    ) {
      this.props.resetManagePage();
    }

    this.setState((prevState) => {
      return {
        openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : this.props.cards[0],
        templateId: templateId,
        paramEntity: NewData,
      };
    });
  }

  onChangeNextApprover(
    nextApprover,
    indexval,
    fundaccountant,
    rejectCase,
    Invoicereadystatus
  ) {
    const { currentUser: { given_name = "", family_name = "" } = {} } =
      this.props;
    const currentUserName = `${given_name} ${family_name}`;
    this.setState({
      // shouldShowApproveRejectWindow: currentUserName === nextApprover, validates next approver based on family name for ex - Laksh Mishra
      shouldShowApproveRejectWindow:
        this.props.currentUser.email === nextApprover &&
        rejectCase != false &&
        Invoicereadystatus != false,
      approverstage: indexval,
      fundaccountantVal: fundaccountant,
    });
  }

  onChangeSubmission(
    nextApprover,
    indexval,
    fundaccountant,
    rejectCase,
    Invoicereadystatus
  ) {
    const { currentUser: { given_name = "", family_name = "" } = {} } =
      this.props;
    const currentUserName = `${given_name} ${family_name}`;
    this.setState({
      // shouldShowApproveRejectWindow: currentUserName === nextApprover, validates next approver based on family name for ex - Laksh Mishra
      shouldShowsubmissionWindow:
        this.props.currentUser.email === nextApprover &&
        rejectCase != false &&
        Invoicereadystatus != false,
      approversubmission: indexval,
      submissionVal: fundaccountant,
    });
  }

  componentDidMount() {
    clearChartKeys();
  }
  componentDidMount() {
    this.checkPathName();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPathName !== this.state.currentPathName) {
      this.checkPathName();
    }
  }
  checkPathName() {
    var pathname = window.location.pathname;
    var parts = pathname.split("/");
    if (
      this.state.currentPathName !== parts[1] &&
      this.state.currentPathName !== ""
    ) {
      this.props.resetFiltersValue();
    } else localStorage.removeItem("chartStorageKeys");
  }
  render() {
    var doit;
    const getDeviceConfig = (width) => {
      if (width < 600) {
        return deviceTypes.XS;
      } else if (width >= 600) {
        return deviceTypes.MD;
      }
    };
    const calcInnerWidth = throttle(() => {
      let deviceType = getDeviceConfig(window.innerWidth);

      if (deviceType == deviceTypes.MD || deviceType == deviceTypes.XS) {
        this.setState({ device: deviceType });
      }
    }, 500);

    window.onresize = function () {
      clearTimeout(doit);
      doit = setTimeout(calcInnerWidth, 100);
    };

    return (
      <>
        <article className="page-content-inner">
          {this.props.cards && this.props.cards.length > 0
            ? this.props.cards.map((item, index) => {
                {
                 return <GetArticle
                    item={item}
                    device={this.state.device}
                    index={index}
                    isReadOnly={this.props.readOnlyTemplate}
                    parentThis={this}
                  />;
                }
              }, this)
            : ""}
        </article>

        <A_SimpleDialog
          open={this.state.openCardZoom}
          //title="Dialog Title"
          fullScreen={false}
          fullWidth={true}
          maxWidth={deviceTypes.MD}
          dialogContent={
            this.state.cardProps ? (
              <A_Card
                elevation={2}
                width={50}
                header={{
                  title: this.state.cardProps.header.title
                    ? this.state.cardProps.header.title
                    : "",
                  zoom: false,
                  secondaryMenu: false,
                  add: false,
                  edit: false,
                  export: false,
                }}
                content={
                  <React.Fragment>
                    {this.state.cardProps.content.content ? (
                      this.state.cardProps.content.type ==
                      externalTypes.TEXT ? (
                        this.state.cardProps.content.content
                      ) : (
                        <img src={InlineRecords} />
                      )
                    ) : (
                      this.state.cardProps.fallbackMsg
                    )}
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )
          }
          dialogActions={
            <A_Button
              label="Close"
              size="small"
              onClick={() => this.handleZoom("close")}
            />
          }
        />
        {this.state.openEditForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              extraParams={this.state.extraEditParams}
              getViewData={
                this.state.isIRCEdit
                  ? this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.data
                    ? this.props.managePage.data.data
                    : ""
                  : this.props.currentPage.data
              }
              crmData={
                this.state.isIRCEdit
                  ? this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.data
                    ? this.props.managePage.data.data
                    : ""
                  : this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.template &&
                    this.props.managePage.data.template.templateType &&
                    this.props.managePage.data.template.templateType ==
                      "SQL form"
                  ? this.props.managePage.data.data
                  : this.props.currentPage.data
              }
              isEditable={true}
              refresh={false}
              entity={this.state.paramEntity}
              addEdge={this.state.addEdge}
              open={this.state.openEditForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
        {this.state.openAddForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              extraParams={this.state.extraAddParams}
              showPopup={true}
              refresh={false}
              templateId={this.state.templateId}
              //crmData={this.props.currentPage.data}
              entity={this.state.paramEntity}
              open={this.state.openAddForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
      </>
    );
  }
}

Layout.propTypes = {
  currentPage: PropTypes.object,
  params: PropTypes.any,
  cards: PropTypes.any,
  currentUser: PropTypes.object,
  handleClosePopup: PropTypes.any,
  isExcelTemplateDownload: PropTypes.any,
  managePage: PropTypes.object,
  resultData: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  parameters: PropTypes.any,
  configurations: PropTypes.any,
  data: PropTypes.any,
  handleAddButtonClick: PropTypes.func,
  handleClickableCell: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  handleEditButtonClick: PropTypes.func,
  resetManagePage: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    prevEntity: state.previousEntity.prevEntity,
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
    managePage: state.managePage,
    currentUser: _.get(state, "oidc.user.profile", {}),
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetManagePage: () => dispatch(resetManagePage()),
    resetFiltersValue: () => dispatch(resetFiltersValue()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
