import React from "react";
import "./layout.scss";
import M_NewsCard from "../../../Molecules/Common/Renderers/Views/NewsCards/M_NewsCard.jsx";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard.jsx";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard.jsx";
import ViewFields from "../Fields/ViewFields.jsx";
import DisplaySections from "../DisplaySection/DisplaySection.jsx";
import DisplayTabs from "../DisplayTabs/DisplayTabs.jsx";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard.jsx";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload.jsx";
import PreviousTemplate from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousTemplate.jsx";
import PreviousUploads from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousUploads.jsx";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary.jsx";
import O_ChartDataEntry from "../../../Organisms/Common/ChartData/O_ChartDataEntry.jsx";
import O_DealTimeline from "../../../Organisms/Common/DealTimeline/O_DealTimeline.jsx";
import O_Historychart from "../../../Organisms/Common/Historychart/O_Historychart.jsx";
import O_IRCDetails from "../../../Organisms/Common/IRCDetails/O_IRCDetails.jsx";
import O_MeetingSlotCard from "../../../Organisms/Common/MeetingSlotCard/O_MeetingSlotCard.jsx";
import Guidelines from "../../../Organisms/Common/Guidelines/Guidelines.jsx";
import ChecklistMaster from "../Checklist/ChecklistMaster.jsx";
import DealTeam from "../DealTeam/DealTeam.jsx";
import O_CheckList from "../../../Organisms/Common/Checklist/O_Checklist.jsx";
import MapGoogle from "../../../Atoms/Map/A_GoogleMap.jsx";
import O_activeFacility from "../../../Organisms/Common/ActiveFacility/O_activeFacility.jsx";
import O_LendersApproached from "../../../Organisms/Common/LendersApproached/O_lendersApproached.jsx";
import M_MeetingPreference from "../MeetingPreferences/M_MeetingPreference.jsx";
import Image from "../../../Atoms/Image/A_Image.jsx";
import ManageInvoices from "../../../Molecules/Common/PDFReader/ManageInvoices.jsx";
import M_ListDetails from "../../../Molecules/Common/DealAdministartion/M_ListDetails.jsx";
import SubmissionApproval from "../MonthlySubmission/submissionApproval.jsx";
import SubmissionReview from "../MonthlySubmission/submissionReview.jsx";
import PortfolioApproval from "../PortfolioManagement/PortfolioApproval.jsx";
import BudgetApproval from "../Budget&Approval/budgetApproval.jsx";
import ApproveAndReject from "../Budget&Approval/approveAndReject.jsx";
import M_PortfolioFileUpload from "../../../Molecules/Common/File Upload/M_PortfolioFileUpload.jsx";
import ImageGallery from "../../../Organisms/Common/ImageGallery/ImageGallery.jsx";
import Annoucment from "../../../Molecules/Announcment/Annoucement.jsx";
import GenericList from "../../../Organisms/Common/GenericList/GenericList.jsx";
import QuickLinks from "../../../Molecules/QuickLink/QuickLinks.jsx";
import ImageCard from "../../../Molecules/ImageCard/ImageCard.jsx";
import O_NewsPortal from "../../../Organisms/Common/NewsPortal/O_NewsPortal.jsx";
import M_PDFReader from "../../../Molecules/Common/PDFReader/M_PDFReader.jsx";
import O_InvestmentCase from "../../../Organisms/Common/InvestmentCase/O_InvestmentCase.jsx";
import { contentTypes, externalTypes } from "./layout.Helper.js";
import ManualUpdate from "../../../Molecules/Common/Renderers/Views/ManualUpdate/ManualUpdate.jsx";
import IRReport from "../../../Organisms/Common/IRReport/IRReport.jsx";
import QuarterlyCodeMapping from "../../../Organisms/Common/QuarterlyCodeMapping/QuarterlyCodeMapping.js";
import QuarterlyKtTable from "../../../Organisms/Common/QuarterlyCodeMapping/QuartelyKtTable.js";
import O_GSTTabs from "../../../Organisms/Common/GST/O_GSTTabs.jsx";
import A_Tearsheet from "../../../Atoms/DataDisplay/Tearsheet/A_Tearsheet.jsx";
import {
  ESGView,
  ESGSubmissionSummary,
  ESG_Report,
  ESG_Submission,
  ESG_Approval,
  ESG_ExistingQuestions,
  ESG_adminLayout,
  QuestionnaireScore,
  ESGRespondentActionPlan,
  ESGAdminCurrentPractice,
  ESGAdminBestPractice,
  ESGScoreSummary,
  MaturityMatrixLayout,
  MaturityMatrixPreviewTemplate,
  ESG_MaturityMatrixPreviewLegend,
  ESG_MaturityMatrixDashBoardLegend,
  ESG_Score,
} from "../../../Organisms/Common/ESG/index.js";
import MasterMappingData from "../../../Organisms/Common/QuarterlyCodeMapping/MasterMappingData/MasterMappingData.jsx";
import KtCodesMain from "../../../Organisms/Common/QuarterlyCodeMapping/KtCodesMain/KtCodesMain.jsx";
import M_CustomView from "./CustomCards/M_CustomView.jsx";
import ImpactData from "../ImpactData.jsx";
import ProposalTracker from "../../../Organisms/Common/ProposalTracker/ProposalTracker.jsx";
import Comment from "../../../Atoms/Inputs/DropdownFields/Comment.jsx";
import M_MediaUpload from "../../../Organisms/Common/Summarix/M_MediaUpload.jsx";

export default function showContentConditional(
  contentType,
  content,
  item,
  chartFilter,
  parentThis
) {
  const { syncEdgeLabel } = item.header;
  const { toolbarSelections } = parentThis.props;
  switch (contentType) {
    case contentTypes.CRMDATA:
      if (content && content.tabs) {
        return (
          <ErrorBoundary>
            <DisplayTabs
              tabs={content.tabs}
              chartFilter={chartFilter}
              data={parentThis.props.data}
              CurrentCard={item}
            />
          </ErrorBoundary>
        );
      } else if (content && content.sections) {
        return (
          <ErrorBoundary>
            <DisplaySections sections={content.sections} />
          </ErrorBoundary>
        );
      } else if (content && content.fields) {
        return (
          <ErrorBoundary>
            <ViewFields
              fields={content.fields}
              syncEdgeLabel={syncEdgeLabel}
              content={content}
              CurrentCard={item}
              {...parentThis.props}
            />
          </ErrorBoundary>
        );
      }
      break;
    case contentTypes.SQLVIEW:
      if (toolbarSelections && toolbarSelections.filterLength > 0) {
        return (
          toolbarSelections.filters &&
          Array.isArray(toolbarSelections.filters) &&
          toolbarSelections.filterLength === toolbarSelections.filters.length &&
          toolbarSelections.filters.length > 0 && (
            <ErrorBoundary>
              <M_CustomView
                tabs={content.tabs}
                chartFilter={chartFilter}
                data={parentThis.props.data}
                CurrentCard={item}
                sections={content.sections}
                fields={content.fields}
                syncEdgeLabel={syncEdgeLabel}
                content={content}
                toolbarSelectionsFilter={{ ...toolbarSelections }}
                spName={
                  item && item.content && item.content.spName
                    ? item.content.spName
                    : ""
                }
                {...parentThis.props}
              />
            </ErrorBoundary>
          )
        );
      } else {
        return (
          <ErrorBoundary>
            <M_CustomView
              tabs={content.tabs}
              chartFilter={chartFilter}
              data={parentThis.props.data}
              CurrentCard={item}
              sections={content.sections}
              fields={content.fields}
              syncEdgeLabel={syncEdgeLabel}
              content={content}
              toolbarSelectionsFilter={""}
              spName={
                item && item.content && item.content.spName
                  ? item.content.spName
                  : ""
              }
              {...parentThis.props}
            />
          </ErrorBoundary>
        );
      }
    case contentTypes.CHART:
      return (
        <ErrorBoundary>
          <M_ChartCard
            Height={!item.minHeight ? "300px" : `${item.minHeight}px`}
            {...parentThis.props}
            chartFilter={chartFilter}
            content={
              content && content.chart
                ? {
                    ...content.chart,
                    chartId: content.chart.id,
                    id: undefined,
                  }
                : {}
            }
          />
        </ErrorBoundary>
      );
    case contentTypes.MAPS:
      return (
        <ErrorBoundary>
          <M_MapCard content={content} />
        </ErrorBoundary>
      );
    case contentTypes.MANUAL_UPDATE:
      return (
        <ErrorBoundary>
          <ManualUpdate content={content} />
        </ErrorBoundary>
      );
    case contentTypes.IR_REPORT:
      return (
        <ErrorBoundary>
          <IRReport content={content} />
        </ErrorBoundary>
      );
    case contentTypes.NEWS:
      return (
        <ErrorBoundary>
          <M_NewsCard content={content} />
        </ErrorBoundary>
      );
    case contentTypes.RESULT_TEMPLATE:
      return (
        <ErrorBoundary>
          <M_ResultCard
            content={content}
            stateObj={parentThis.state}
            item={item}
          />
        </ErrorBoundary>
      );
    case contentTypes.DOCUMENTS:
      return (
        <ErrorBoundary>
          <M_DropableFileUpload
            content={content}
            currentPage={parentThis.props.currentPage}
          />
        </ErrorBoundary>
      );
    case contentTypes.PORTFOLIO_FILE_UPLOAD:
      return (
        <ErrorBoundary>
          <M_PortfolioFileUpload
            content={content}
            currentPage={parentThis.props.currentPage}
          />
        </ErrorBoundary>
      );
    case contentTypes.PREVIOUS_TEMPLATE:
      return (
        <ErrorBoundary>
          <PreviousTemplate content={content} />
        </ErrorBoundary>
      );
    case contentTypes.PREVIOUS_UPLOADS:
      return (
        <ErrorBoundary>
          <PreviousUploads content={content} />
        </ErrorBoundary>
      );
    case contentTypes.CHART_ENTRY:
      return (
        <ErrorBoundary>
          <O_ChartDataEntry chart={content.chart} />
        </ErrorBoundary>
      );
    case contentTypes.TIMELINE:
      return (
        <ErrorBoundary>
          <O_DealTimeline content={content} />
        </ErrorBoundary>
      );
    case contentTypes.GUIDELINES:
      return (
        <ErrorBoundary>
          <Guidelines content={content} />
        </ErrorBoundary>
      );
    case contentTypes.MAPGOOGLE:
      return (
        <ErrorBoundary>
          <MapGoogle content={content} data={parentThis.props.data} />
        </ErrorBoundary>
      );
    case contentTypes.IRC_DETAIL:
      return (
        <ErrorBoundary>
          <O_Historychart
            maxHeight={content.maxHeight}
            content={content}
            callBack={parentThis.handleRecordID}
          />
        </ErrorBoundary>
      );
    case contentTypes.DEAL_ADMINISTRATION:
      return (
        <ErrorBoundary>
          <O_IRCDetails
            content={content}
            callBack={parentThis.handleRecordID}
          />
        </ErrorBoundary>
      );
    case contentTypes.BOOKING_SLOT:
      return (
        <ErrorBoundary>
          <O_MeetingSlotCard maxHeight={content.maxHeight} content={content} />
        </ErrorBoundary>
      );
    case contentTypes.IC_VIEW:
      return (
        <M_ListDetails
          type={externalTypes.IRC}
          id={parentThis.state.recordId}
          resPayload={content && content.resPayload ? content.resPayload : ""}
          config={content && content.ircTabs ? content.ircTabs : []}
          content={content}
        />
      );
    case contentTypes.GENERIC_RESULT_TEMPLATE:
      return <GenericList content={content}></GenericList>;
    case contentTypes.CHECKLIST_MASTER:
      return (
        <ErrorBoundary>
          <ChecklistMaster
            content={content}
            data={
              parentThis.props.data && parentThis.props.data.checklistResponse
                ? parentThis.props.data.checklistResponse
                : null
            }
            percentComplete={
              parentThis.props.data && parentThis.props.data.checklistStatistics
                ? parentThis.props.data.checklistStatistics
                : null
            }
            dealData={parentThis.props.data ? parentThis.props.data : null}
          />
        </ErrorBoundary>
      );
    case contentTypes.ACTIVE_FACILITY:
      return (
        <ErrorBoundary>
          <O_activeFacility />
        </ErrorBoundary>
      );
    case contentTypes.IMPACT_DATA:
      return (
        <ErrorBoundary>
          <ImpactData content={content} />
        </ErrorBoundary>
      );
    case contentTypes.LENDERS_APPROACHED:
      return (
        <ErrorBoundary>
          <O_LendersApproached
            {...parentThis.props}
            label={
              content && content.content && content.content.label
                ? content.content.label
                : null
            }
          />
        </ErrorBoundary>
      );
    case contentTypes.MANAGED_BY:
      return (
        <ErrorBoundary>
          <DealTeam
            content={content}
            currentPage={parentThis.props.currentPage}
          />
        </ErrorBoundary>
      );
    case contentTypes.CHECKLIST:
      return (
        <ErrorBoundary>
          <O_CheckList ircID={parentThis.state.recordId} config={content} />
        </ErrorBoundary>
      );
    case contentTypes.MANAGE_INVOICES:
      return (
        <ErrorBoundary>
          <ManageInvoices content={content} data={parentThis.props.data} />
        </ErrorBoundary>
      );
    case contentTypes.MEETING___PREFERENCE:
      return (
        <ErrorBoundary>
          <M_MeetingPreference content={content} />
        </ErrorBoundary>
      );
    case contentTypes.IMAGE:
      return (
        <ErrorBoundary>
          <Image content={content} {...parentThis.props}></Image>
        </ErrorBoundary>
      );
    case contentTypes.TEARSHEET:
      return (
        <ErrorBoundary>
          <A_Tearsheet></A_Tearsheet>
        </ErrorBoundary>
      );
    // case contentTypes.DEALSEARCHBAR:
    //   return <SearchBar  />
    case contentTypes.BUDGET_APPROVAL:
      return (
        <ErrorBoundary>
          <BudgetApproval
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
            data={parentThis.props}
            onChangeNextApprover={parentThis.onChangeNextApprover}
          />
        </ErrorBoundary>
      );
    case contentTypes.SUBMISSION_REVIEW:
      return (
        <ErrorBoundary>
          <SubmissionReview
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
            approvalData={parentThis.state.approversubmission}
            fundaccountantstatus={parentThis.state.submissionVal}
          />
        </ErrorBoundary>
      );
    case contentTypes.SUBMISSION_APPROVAL:
      return (
        <ErrorBoundary>
          <SubmissionApproval
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
            data={parentThis.props}
            onChangeSubmission={parentThis.onChangeSubmission}
          />
        </ErrorBoundary>
      );
    case contentTypes.PORTFOLIOAPPROVAL:
      return (
        <ErrorBoundary>
          <PortfolioApproval
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
            approvalData={parentThis.state.approversubmission}
          />
        </ErrorBoundary>
      );
    case contentTypes.APPROVE_AND_REJECT:
      return (
        <ErrorBoundary>
          <ApproveAndReject
            content={content}
            getCurrentPage={parentThis.props.getCurrentPage}
            approvalData={parentThis.state.approverstage}
            fundaccountantstatus={parentThis.state.fundaccountantVal}
          />
        </ErrorBoundary>
      );
    // Will used later As per discussion
    // case contentTypes.EXTERNAL_COMMITTEE:
    //   return (
    //     <ErrorBoundary>
    //       <ExternalCommittee content={content}/>
    //     </ErrorBoundary>
    //   );
    case contentTypes.IMAGE_GALLERY:
      return (
        <ErrorBoundary>
          <ImageGallery content={content} {...parentThis.props} />
        </ErrorBoundary>
      );
    case contentTypes.ANNOUCMENT:
      return (
        <ErrorBoundary>
          <Annoucment content={content} />
        </ErrorBoundary>
      );
    case contentTypes.QUICK_LINKS:
      return (
        <ErrorBoundary>
          <QuickLinks content={content} {...parentThis.props} />
        </ErrorBoundary>
      );
    case contentTypes.IMAGE_CARD:
      return (
        <ErrorBoundary>
          <ImageCard content={content} {...parentThis.props} />
        </ErrorBoundary>
      );
    case contentTypes.NEWS_PORTAL:
      return (
        <ErrorBoundary>
          <O_NewsPortal content={content} {...parentThis.props} />
        </ErrorBoundary>
      );
    case contentTypes.STATIC_PDF:
      return (
        <ErrorBoundary>
          <M_PDFReader
            pdf={content.filename ? content.filename : ""}
            showDefaultMessage={true}
            container={content.type ? content.type : externalTypes.GENERIC}
          />
        </ErrorBoundary>
      );
    case contentTypes.INVESTMENT_CASE:
      return (
        <ErrorBoundary>
          <O_InvestmentCase content={content} />
        </ErrorBoundary>
      );
    case contentTypes.QUARTERLY_KT_CODE_MAPPING:
      return (
        <ErrorBoundary>
          <QuarterlyKtTable
            updateEditData={parentThis.updateEditData}
            startEditing={parentThis.startEditing}
            setSelectedTab={parentThis.updateSelectedTab}
            newSavedData={parentThis.state.newSavedData}
            updateSavedData={parentThis.updateSavedData}
            QuarterCode={parentThis.state.QuarterCode}
            isEditing={parentThis.state.isEditing}
            chartFilter={chartFilter}
            content={content}
            {...parentThis.props}
          />
          <QuarterlyCodeMapping
            editData={parentThis.state.editData}
            isEditing={parentThis.state.isEditing}
            selectedTab={parentThis.state.selectedTab}
            newSavedData={parentThis.state.newSavedData}
            updateSavedData={parentThis.updateSavedData}
            updateQuarterCode={parentThis.updateQuarterCode}
            endEditing={parentThis.endEditing}
            chartFilter={chartFilter}
            content={content}
          />
        </ErrorBoundary>
      );
    case contentTypes.GST:
      return (
        <ErrorBoundary>
          <O_GSTTabs content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESG:
      return (
        <ErrorBoundary>
          <ESG_adminLayout content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGUserView:
      return (
        <ErrorBoundary>
          <ESGView content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGExistingQuestion:
      return (
        <ErrorBoundary>
          <ESG_ExistingQuestions />
        </ErrorBoundary>
      );
    case contentTypes.ESGSubmissionSummary:
      return (
        <ErrorBoundary>
          <ESGSubmissionSummary content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGReport:
      return (
        <ErrorBoundary>
          <ESG_Report />
        </ErrorBoundary>
      );
    case contentTypes.ESGRespondentActionPlan:
      return (
        <ErrorBoundary>
          <ESGRespondentActionPlan content={content} />
        </ErrorBoundary>
      );
    case contentTypes.MaturityMatrixPreviewTemplate:
      return (
        <ErrorBoundary>
          <MaturityMatrixPreviewTemplate content={content} />
        </ErrorBoundary>
      );

    case contentTypes.ESG_MaturityMatrixPreviewLegend:
      return (
        <ErrorBoundary>
          <ESG_MaturityMatrixPreviewLegend content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESG_MaturityMatrixDashBoardLegend:
      return (
        <ErrorBoundary>
          <ESG_MaturityMatrixDashBoardLegend content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGScore:
      return (
        <ErrorBoundary>
          <ESG_Score content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGSubmission:
      return (
        <ErrorBoundary>
          <ESG_Submission />
        </ErrorBoundary>
      );
    case contentTypes.ESGApproval:
      return (
        <ErrorBoundary>
          <ESG_Approval />
        </ErrorBoundary>
      );
    case contentTypes.QuestionnaireScore:
      return (
        <ErrorBoundary>
          <QuestionnaireScore content={content} />
        </ErrorBoundary>
      );
    case contentTypes.MASTERMAPPINGDATA:
      return (
        <ErrorBoundary>
          <MasterMappingData content={content} />
        </ErrorBoundary>
      );
    case contentTypes.QUARTERLY_KT_CODE:
      return (
        <ErrorBoundary>
          <KtCodesMain content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGAdminCurrentPractice:
      return (
        <ErrorBoundary>
          <ESGAdminCurrentPractice content={content} />
        </ErrorBoundary>
      );
    case contentTypes.ESGAdminBestPractice:
      return (
        <ErrorBoundary>
          <ESGAdminBestPractice content={content} />
        </ErrorBoundary>
      );

    case contentTypes.ESGScoreSummary:
      return (
        <ErrorBoundary>
          <ESGScoreSummary content={content} />
        </ErrorBoundary>
      );

    case contentTypes.ESGMaturityMatrixLayout:
      return (
        <ErrorBoundary>
          <MaturityMatrixLayout content={content} />
        </ErrorBoundary>
      );
    case contentTypes.PROPOSAL_TRACKER:
      return (
        <ErrorBoundary>
          <ProposalTracker content={content} />
        </ErrorBoundary>
      );
    case contentTypes.COMMENT:
      return (
        <ErrorBoundary>
          <Comment content={content} />
        </ErrorBoundary>
      );
    case contentTypes.SUMMARIX:
      return (
        <ErrorBoundary>
          <M_MediaUpload content={content} />
        </ErrorBoundary>
      );
    default:
      return "";
  }
}
