import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Edit, Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { getUsersList, updateUser, getExcel } from "./UserManagementHelper";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import AddUsers from "./AddUsers";
import UserDetails from "./Users_Details";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import Skeleton from "@material-ui/lab/Skeleton";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import PropTypes from "prop-types";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { message } from "../../../../../Utils/config/messages";
import "./UserManagement.css";
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from "@material-ui/core/TablePagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    padding: theme.spacing(1),
    boxSizing: "border-box",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    backgroundColor: "#ecf0f1",
    padding: theme.spacing(2),
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  actions: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    justifyContent: "flex-end",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      alignItems: "right",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      gap: 10,
    },
  },
  searchContainer: {
    backgroundColor: "white",
    borderRadius: "25px",
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      alignItems: "left",
      width: "75%",
    },
  },

  addUserButton: {
    padding: "6px 10px 6px 10px",
    backgroundColor: "#3498db",
    color: "white",
    borderRadius: "5px",
    fontSize: "14px",
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    "&:hover": {
      backgroundColor: "#2980b9",
    },
  },
  userTable: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: theme.spacing(0.3),
  },
  userTableHeader: {
    backgroundColor: "#ecf0f1",
  },
  userTableCellHeader: {
    color: "#7F8C8D",
    fontWeight: "bold",
  },
  userInfo: {
    fontWeight: "bold",
    color: "#2980B9",
  },
  actionButtons: {
    fontSize: "18px",
    padding: "5px",
  },
  editButton: {
    color: "#2980b9",
    "&:hover": {
      color: "#1true9c",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "right",
    margin: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-3px",
      fontSize: "13px",
      width: "5.5ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  searchIcon: {
    left: theme.spacing(1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-5px",
    },
  },
  stripedRow: {
    backgroundColor: theme.palette.grey[100],
  },
  customTableCell: {
    "&.MuiTableCell-sizeSmall": {
      padding: "0px 15px 0px 15px",
    },
  },
  wordWrap: {
    wordWrap: "anywhere",
  },
  downloadButton: {
    padding: "6px",
    [theme.breakpoints.down("sm")]: {
      padding: "2px",
    },
  },
}));

function UserDashboard(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [formOpen, setFormOpen] = useState(false);
  const [disablePopup, setDisablePopup] = useState(false);
  const [editform, setEditform] = useState({});
  const [userNavigateActive, setUserNavigateActive] = useState(false);
  const [disableButtonLoading, setDisableButtonLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  let header = props?.content?.item?.header?.title || "Account Management";
  let filterArr = props?.content?.item?.content?.content?.RemoveDomains || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  async function fetchData() {
    try {
      setLoading(true);
      let data = await getUsersList();
      let newArray = [];
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const filterSet = new Set(filterArr);
        newArray = data.filter((item) => {
          return ![...filterSet].some((val) => item.email.includes(val));
        });
      } else {
        newArray = data;
      }
      setUsers(newArray.length > 0 ? newArray : []);
      setLoading(false);
    } catch (error) {
      setUsers([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveUser = () => {
    setFormOpen(false);
    fetchData();
  };
  const handledisablePopUp = () => {
    setDisablePopup(!disablePopup);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };
  const handleCloseform = () => {
    setFormOpen(!formOpen);
  };
  const handleAddButtonClick = () => {
    setFormOpen(!formOpen);
    setEditform({});
  };
  const NavigateUser = (user) => {
    let data = users.filter((item) => item.id == user.id);
    setEditform(data[0]);
    setUserNavigateActive(!userNavigateActive);
  };
  const handleEditform = (user) => {
    setFormOpen(!formOpen);
    let data = users.filter((item) => item.id == user.id);
    setEditform(data[0]);
  };
  const handleDisableUser = (user) => {
    setDisablePopup(!disablePopup);
    let data = users.filter((item) => item.id == user.id);
    setEditform(data[0]);
  };
  const disableUser = async () => {
    setDisableButtonLoading(true);
    let flag = editform.isEnabled ? false : true;
    let payload = {
      ...editform,
      isEnabled: flag,
      phoneNumberConfirmed: flag,
      emailConfirmed: flag,
    };
    let res = await updateUser(payload);
    if (res.status >= 200 && res.status < 300) {
      if (flag) {
        props.setToast({
          type: toastSeverity.SUCCESS,
          severity: toastSeverity.SUCCESS,
          message: "Account Enabled",
          status: true,
        });
      } else {
        props.setToast({
          type: toastSeverity.SUCCESS,
          severity: toastSeverity.SUCCESS,
          message: "Account Disabled",
          status: true,
        });
      }
    } else {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setDisablePopup(false);
    setDisableButtonLoading(false);
    fetchData();
  };

  const filteredUsers = users?.filter((user) => {
    const { email, phoneNumber, lastName, firstName } = user;
    const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

    return (
      email?.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      phoneNumber?.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      lastName?.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      firstName?.trim().toLowerCase().includes(lowerCaseSearchTerm) ||
      `${firstName.trim().toLowerCase()} ${lastName
        .trim()
        .toLowerCase()}`.includes(lowerCaseSearchTerm)
    );
  });

  const handleDownload = async () => {
    setExcelLoading(true);
    let payload = {
      domains:
        Array.isArray(filterArr) && filterArr.length > 0 ? filterArr : [],
    };
    await getExcel(payload);
    setExcelLoading(false);
  };
  let newPage = filteredUsers.length > 10 ? page : 0;
  if (userNavigateActive)
    return (
      <ErrorBoundary>
        <UserDetails
          data={editform}
          UserNavigateActive={setUserNavigateActive}
          header={header}
        />
      </ErrorBoundary>
    );
  return (
    <>
      <div className={classes.dashboard}>
        <div className={classes.content}>
          <div className={classes.actions}>
            <div className={classes.searchContainer}>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.searchBar,
                  input: classes.inputInput,
                }}
                value={searchTerm}
                onChange={handleSearch}
                startAdornment={
                  <Search
                    fontSize={"small"}
                    style={{ margin: "3px 0 2px 3px" }}
                    className={classes.searchIcon}
                  />
                }
              />
            </div>
            <Button
              size="small"
              className={classes.addUserButton}
              onClick={() => handleAddButtonClick()}
            >
              Create new Minerva Account
              <AddIcon fontSize={"small"} />
            </Button>
            {excelLoading ? (
              <IconButton size="small">
                <CircularProgress size={25} style={{ color: "#3498db" }} />
              </IconButton>
            ) : (
              <IconButton size="small" className={classes.downloadButton}>
                <GetAppIcon
                  size="small"
                  style={{ color: "#3498db" }}
                  onClick={handleDownload}
                />
              </IconButton>
            )}
          </div>
          {loading ? (
            <>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </>
          ) : !filteredUsers ? (
            <Typography variant="h6">{message.NO_CONTENT}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.userTable} size={"small"}>
                <TableHead className={classes.userTableHeader}>
                  <TableRow>
                    <TableCell className={classes.userTableCellHeader}>
                      <span></span>
                    </TableCell>
                    <TableCell className={classes.userTableCellHeader}>
                      Name
                    </TableCell>
                    <TableCell className={classes.userTableCellHeader}>
                      Email (Login Id)
                    </TableCell>
                    <TableCell className={classes.userTableCellHeader}>
                      Contact
                    </TableCell>
                    <TableCell
                      className={
                        (classes.userTableCellHeader, classes.customTableCell)
                      }
                    >
                      <span>Account Status</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.length > 0 ? (
                    filteredUsers
                      .slice(
                        newPage * rowsPerPage,
                        newPage * rowsPerPage + rowsPerPage
                      )
                      .map((user, index) => (
                        <TableRow
                          key={user.id}
                          className={index % 2 === 0 ? classes.stripedRow : ""}
                        >
                          <TableCell className={classes.customTableCell}>
                            <Tooltip title="Update Account" placement="right">
                              <IconButton
                                onClick={() => handleEditform(user)}
                                className={`${classes.actionButtons} ${classes.editButton}`}
                              >
                                <Edit fontSize={"small"} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ cursor: "pointer" }}>
                            <div
                              className={classes.userInfo}
                              onClick={() => NavigateUser(user)}
                            >
                              <Typography
                                variant="body2"
                                className={classes.wordWrap}
                              >
                                {`${user.firstName} ${user.lastName}`}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.customTableCell}>
                            <span className={classes.wordWrap}>
                              {user.email}
                            </span>
                          </TableCell>
                          <TableCell className={classes.customTableCell}>
                            <span className={classes.wordWrap}>
                              {user.phoneNumber}
                            </span>
                          </TableCell>
                          <TableCell className={classes.customTableCell}>
                            <div style={{ margin: "0px 0px -25px 8ch" }}>
                              <Tooltip
                                title={
                                  !user?.isEnabled
                                    ? "Disabled. Click to Enable"
                                    : "Enabled. Click to Disable"
                                }
                                placement="right"
                              >
                                <label className="switch">
                                  <input
                                    onClick={() => handleDisableUser(user)}
                                    type="checkbox"
                                    checked={user?.isEnabled ? true : false}
                                  />

                                  <span className="slider"></span>
                                </label>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <>
                      <TableCell>
                        <span>{message.NO_DATA_FOUND}</span>
                      </TableCell>
                      <TableCell></TableCell> <TableCell></TableCell>
                      <TableCell></TableCell> <TableCell></TableCell>
                    </>
                  )}
                </TableBody>
              </Table>
              <div className={classes.pagination}>
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  page={newPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </TableContainer>
          )}
        </div>
      </div>
      <A_SimpleDialog
        id="1"
        open={formOpen}
        title={editform?.email ? "Update Account" : "Create Account"}
        fullScreen={false}
        height="600px"
        fullwidth={true}
        maxWidth={"sm"}
        onClose={handleCloseform}
        dialogContent={
          <ErrorBoundary>
            <AddUsers
              data={editform}
              handleSaveUser={handleSaveUser}
              allUsers={users}
            />
          </ErrorBoundary>
        }
      />
      <A_SimpleDialog
        id="1"
        open={disablePopup}
        title={editform?.isEnabled ? "Disable Account" : "Enable Account"}
        fullScreen={false}
        height="600px"
        fullwidth={true}
        maxWidth={"sm"}
        onClose={() => handledisablePopUp()}
        dialogContent={
          <>
            Do you confirm the
            {editform?.isEnabled ? " deactivation" : " activation"} of this
            account?
          </>
        }
        dialogActions={
          <>
            <A_Button
              variant="contained"
              label={!disableButtonLoading ? "YES" : "SAVING..."}
              color="primary"
              onClick={() => disableUser()}
              disabled={disableButtonLoading}
              endIcon={
                disableButtonLoading ? (
                  <A_CircularIndeternment color="secondary" size={20} />
                ) : (
                  <></>
                )
              }
            />
            <A_Button
              label="No"
              color={"inherit"}
              onClick={() => setDisablePopup(false)}
            />
          </>
        }
      />
    </>
  );
}
UserDashboard.propTypes = {
  setToast: PropTypes.func,
  content: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
