import * as httpCall from "../../../../../Utils/apis/apis";

export const AuditMeeting = async (pageUrl, crmId, MeetingAuditSpName) => {
  try {
    let url = `${process.env.API_URL}/CRMData/meeting?pageURL=${pageUrl}&crmId=${crmId}&sp=${MeetingAuditSpName}`;
    const result = await httpCall.httpDelete(url);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
