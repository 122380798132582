import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { utils, writeFile } from "xlsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {  CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import emitter from "../components/Emitter";
import * as esgApi from "../ESG_API/index"
import { color } from "../ESG_Config";



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#757474",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      textDecoration:"none",
      border:"0px",
      whiteSpace: "wrap",
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
      position: "relative"
    },
    button: {
      background: "#d7d7d7",
    },
    cell: {
     maxWidth: 400,
     whiteSpace: "normal",
     wordWrap: "break-word",
     overflow: "hidden",
     textOverflow: "ellipsis",
    },
    tableContainer: {
      height: 400,
      overflowY: "scroll",
    },
    textarea:{
        border: "1px solid #ccc", 
        width: '100%',
        height:"12ch",
        overflowY:"auto",
        padding: "6px 6px 6px 6px",
         margin: "8px 8px 8px 8px",
    }
  });

const ESG_AdminPractice = () => {
  const classes = useStyles();
  const [bestPracticesResponses, setBestPracticesResponses] = useState([
  ]);
  const [surveyId, setSurveyId] = useState("");
  const [selectedSubPillarId, setSelectedSubPillarId] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadSetupView,setReloadSetupView]=useState(false);
 const getmaturityStyle=(id)=>{
  switch (id) {
    case 1:
      return {color:"white",backgroundColor:"purple",padding:"5px", margin:"5px"};
    case 2:
      return {color:"white",backgroundColor:color.yellow,padding:"5px", margin:"5px"};
    case 3:
      return {color:"white",backgroundColor:color.skyblue,padding:"5px", margin:"5px"};
    default:
      return {color:"white",backgroundColor:"grey",padding:"5px", margin:"5px"}; 
  }
  }
  
useEffect(() => {

  if(reloadSetupView){
   
  const surveyId = localStorage.getItem("Survey")!= undefined?JSON.parse(localStorage.getItem("Survey")):"";
   const selectedSubPillarId= localStorage.getItem("subPillarId")!= undefined?JSON.parse(localStorage.getItem("subPillarId")):"";
   let sectorId =localStorage.getItem("sectorId")!="undefined"?JSON.parse(localStorage.getItem("sectorId")):0;
   setSurveyId(surveyId);
   setSelectedSubPillarId(selectedSubPillarId);
   setSelectedSectorId(sectorId?sectorId:0);
   setReloadSetupView(false)  
  }
  }, [reloadSetupView]);


  useEffect(()=>{
    if (surveyId != "" && selectedSubPillarId != "") {
      const getBestPracticesResponses = async () => {
        setLoading(true);
        let response = await esgApi.getBestPracticeData(surveyId,selectedSubPillarId,selectedSectorId);
        if(response && response.length!==0){
        response.sort((a, b) => a.portfolioinvestmentName.localeCompare(b.portfolioinvestmentName));
        }
        setBestPracticesResponses(response && response.length!==0?response:[]);
        setLoading(false);
      };
      getBestPracticesResponses();
    }

  }, [surveyId,selectedSubPillarId,selectedSectorId])


  useEffect(()=>{
    const onSetupViewReloadEvent = (receivedData) => {
      setReloadSetupView(receivedData);
    };
  
    emitter.on("reloadBestPracticeTable", onSetupViewReloadEvent);
  },[])


  return (
  <div>
    <div>
     {!loading && bestPracticesResponses.length !==0 ? (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead
              style={{
                zIndex: 0,
                position: "sticky",
                top: "0px",
              }}
            >
              <TableRow>
                <StyledTableCell style={{width:"30ch"}}>Assets</StyledTableCell>
                <StyledTableCell style={{width:"30ch"}} align="center">Maturity Assessment</StyledTableCell>
                <StyledTableCell align="center">Current Practices</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "auto", maxHheight: "250px" }}>
              { bestPracticesResponses.map((row, idx) => (
                  <>
                    <StyledTableRow  key={row.portfolioinvestmentId}>
                      <StyledTableCell className={classes.cell} style={{fontWeight:"400"}} component="th" scope="row">
                       {row.portfolioinvestmentName}
                      </StyledTableCell>
                      <StyledTableCell className={classes.cell} style={{fontWeight:"400",textAlign:"center"}} component="th" scope="row">
                     {row.maturityTag.sort((a, b) => a?.maturityAssessmentId - b?.maturityAssessmentId).map((item)=>{
                       return <div style={getmaturityStyle(item.maturityAssessmentId)}>
                         {item.maturityAssessmentName}
                         </div>
                     })}
                      </StyledTableCell>
                      <StyledTableCell className={classes.cell}>
                       <div className={classes.textarea} >
                      {row.bestpractices && row.bestpractices.split('\n').map((line, index) => (
                        <div key={index} style={{ marginBottom: "5px" }}>{line}</div>
                            ))}
                       </div>
                      </StyledTableCell> 
                    </StyledTableRow>
                  </> 
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : ( loading ? (
          <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
           >
          <CircularProgress />
        </div>): ( !surveyId?(
                  <Alert severity="info">
                   All active surveys are either live or unpublished.
                  </Alert>):(
                  <Alert severity="info">
                   There is no response to show as no rated questions found.
                  </Alert>)
                 )
      )}
     </div>
  </div>
  );
};
ESG_AdminPractice.propTypes = {
  content: PropTypes,
};

export default ESG_AdminPractice;
