import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  makeStyles,
  Input,
  CircularProgress,
  TextField
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import {
  getPreviousPublishedSurvey,
  getQuestionsJsonFromSurvey,
  getResponseToQuestion,
} from "../ESG_API";
import {
  organizeQuestions,
  getTypeFromId,
} from "../utils";
import CopyableTooltip from "../components/CopyableTooltip";

const useStyles = makeStyles(() => ({
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  submitButtonContainer: {
    display: "flex",
    marginTop: "20px",
    marginRight: "20px",
    justifyContent: "flex-end",
    padding: "10px 0 50px 0",
  },
  disabledField: {
    backgroundColor: "#cbd5e1",
  },
  text: {
    "& input": {
      textAlign:"center"
    },
  },
}));

const ActionPlanPopUp = (props) => {
  const { selectedRow, onFormClose } = props;


  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  const classes = useStyles();
//   const [changedFormData, setChangedFormData] = useState([]);
//   const [successPost, setSuccessPost] = React.useState(false);
  const [isDisable, setIsDisable] = useState(true);
//   const [failure, setFailure] = useState(false);

  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [fullFormData, setFullFormData] = useState({});
  const [totalListQuestion, setTotalQuestionList] = useState();
  const [loading, setIsLoading] = useState(true);
  const [previousSurveyAnswers, setPreviousSurveyAnswers] = useState([]);




  const convertMappedQuestionsToLayout = (mappedQuestions) => {
  
    const questionsDataLayoutForm = [];

    if (mappedQuestions && mappedQuestions.length > 0) {
      mappedQuestions.forEach((question) => {
        let type = [];
        if (question.hasSubQuestions && question.subQuestion.length > 0) {
          question.subQuestion.forEach((sub) => type.push(sub.text));
        }
      
        questionsDataLayoutForm.push({
          guidance: question.guidance,
          id: question.questionId,
          question: question.questionText,
          type: type.length === 0 ? ["-"] : type,
          questionAnswerTypeId: question.questionAnswerTypeId,
          unit: question.measurementUnitId,
          children: convertMappedQuestionsToLayout(question.children) || [],
          importHistoricData: question.importHistoricData,
          isStatic: question.isStatic,
          subQuestion: question.subQuestion,
          isBooleanResponse: question.questionAnswerTypeId == 3 ? true : false,
    
        });
      });
    }
    return questionsDataLayoutForm;
  };

  const setDefaultValue = (data, allAnswers, questions, allQuestions) => {
    const tempData = {};

    if (allAnswers) {
      data.forEach((item) => {
        tempData[`${item.questionId}_answer`] = item.responseText;
        tempData[`${item.questionId}_comment`] = item.comment;
        item.eSGSubResponseDtos.forEach((subItem) => {
          tempData[`${item.questionId}_sub_${subItem.subQuestionId}_answer`] =
            subItem.responseText;
        });
      });
    } else if (allQuestions) {
      data.forEach((item) => {
        if (item.current == true) {
          tempData[`${item.questionId}_answer`] = item.responseText;
          tempData[`${item.questionId}_comment`] = item.comment;
          item.eSGSubResponseDtos.forEach((subItem) => {
            tempData[`${item.questionId}_sub_${subItem.subQuestionId}_answer`] =
              subItem.responseText;
          });
        } else {
          let question = questions.find(
            (data) =>
              data.questionId == item.questionId && data.isStatic == true
          );
          if (question) {
            tempData[`${item.questionId}_answer`] = item.responseText;
            item.eSGSubResponseDtos.forEach((subItem) => {
              tempData[
                `${item.questionId}_sub_${subItem.subQuestionId}_answer`
              ] = subItem.responseText;
            });
          }
        }
      });
    } else {
      data.forEach((item) => {
        let question = questions.find(
          (data) => data.questionId == item.questionId && data.isStatic == true
        );
        if (question) {
          tempData[`${item.questionId}_answer`] = item.responseText;
          item.eSGSubResponseDtos.forEach((subItem) => {
            tempData[`${item.questionId}_sub_${subItem.subQuestionId}_answer`] =
              subItem.responseText;
          });
        }
      });
    }
    setFullFormData(tempData);
  };


  const getMappedQuestions = async () => {
    // get current answers 
    const answers = await getResponseToQuestion(
      props.selectedSurveyId,
      props.investmentId
      );
    const questionSurveyJson = await getQuestionsJsonFromSurvey(props.selectedSurveyId);
    const previousSurvey = await getPreviousPublishedSurvey(props.selectedSurveyId);
    let previousSurveyRes = [];
    if (previousSurvey) {
      previousSurveyRes = await getResponseToQuestion(
        previousSurvey && previousSurvey.surveyId,
        props.investmentId
      );
    }
    setPreviousSurveyAnswers(previousSurveyRes);
    const organizedQues = organizeQuestions(questionSurveyJson);
    setTotalQuestionList(questionSurveyJson);
    const filteredQuestionList = organizedQues.filter((item) => {
      return (
        item.questionCategoryId === selectedRow.questioncategoryid &&
        item.questionSubCategoryId === selectedRow.questionsubcategoryid
      );
    });
    const questionLayoutMap =convertMappedQuestionsToLayout(filteredQuestionList,props.subPillarQuestionAndScoreData);
    setMappedQuestions(questionLayoutMap);
    //TODO: To set Default Values here data should be Array
   // console.log({ questionLayoutMap });
    if (answers && answers.length && !previousSurveyRes.length) {
      setDefaultValue(answers, true, questionSurveyJson, false);
    } else if (
      previousSurveyRes &&
      previousSurveyRes.length &&
      !answers.length
    ) {
      setDefaultValue(previousSurveyRes, false, questionSurveyJson, false);
    } else {
      let allQuestions = previousSurveyRes.reduce(
        (result, item1) => {
          let existing = result.find(
            (item2) => item2.questionId === item1.questionId
          );
          if (!existing) {
            result.push({ ...item1, current: false, previous: true });
          } else {
            existing.previous = true;
          }
          return result;
        },
        answers.map((answer) => ({ ...answer, current: true, previous: false }))
      );

      setDefaultValue(allQuestions, false, questionSurveyJson, true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if(props.selectedSurveyId && props.investmentId){
     getMappedQuestions();
    }else{
      setIsLoading(false);
    }
  }, [props.selectedSurveyId,props.investmentId]);



  return (
    <div>
      
      <TableContainer
        component={Paper}
        style={{ display: "flex", flexDirection: "column" }}
      >
          {loading ? (
          <div style={{ textAlign: "center", paddingBottom: "10px" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Table style={{ marginLeft: "10px", marginRight: "10px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Guidance
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Question
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Breakdown
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Answer
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Comments
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Previous Year
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                
              {mappedQuestions.length !== 0 && (
                  <>
                    {mappedQuestions.map((question, questionIndex) => (
                      <MultiRowTableRowHandler
                        key={question.id}
                        fullFormData={fullFormData}
                        question={question}
                        previousAnswers={previousSurveyAnswers}
                        questionIndex={questionIndex}
                        HtmlTooltip={HtmlTooltip}
                        
                      />
                    ))}
                  </>
                )}
             
              </TableBody>
            </Table>
            {!mappedQuestions.length && (
              <Alert
                severity="error"
                style={{
                  width: "auto",
                  marginLeft: "10px",
                  marginRight: "-10px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No question response found for this pillar and subpillar.
              </Alert>

            )}
        
        </>   
        )}
      </TableContainer>
    </div>
  );
};

ActionPlanPopUp.propTypes = {
  data:PropTypes.any,
  selectedSurveyId:PropTypes.any,
  investmentId:PropTypes.any,
  selectedRow: PropTypes.object,
  onFormClose: PropTypes.func,
  child: PropTypes.bool,
  surveyFreeze:PropTypes.bool,
  setChild: PropTypes.func,
};

export default ActionPlanPopUp;







const MultiRowTableRowHandler = (props) => {
  const {
    question,
    questionIndex,
    previousAnswers,
    fullFormData,
    HtmlTooltip,
  } = props;
  const classes = useStyles(()=>({
    text: {
      "& input": {
        textAlign:"center"
      }
    }
  }));

  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={question.type.length > 0 ? question.type.length : 1}
          align="center"
        >
          <HtmlTooltip
            title={
              <React.Fragment>
                {question.guidance
                  ? question.guidance
                  : "No guidance available"}
              </React.Fragment>
            }
          >
            <InfoIcon />
          </HtmlTooltip>
        </TableCell>
        <TableCell
          rowSpan={question.type.length > 0 ? question.type.length : 1}
          align="center"
        >
          <div style={{ width: "260px" }}>{question.question}</div>
        </TableCell>
        {question.questionAnswerTypeId === 3 ? (
          <>
            <TableCell align="center">-</TableCell>
            <TableCell align="center">-</TableCell>
            <CustomTableCell
              name={`${question.id}_answer`}
              index={questionIndex}
              value={fullFormData[`${question.id}_answer`]}
              type={"boolean"}
              isDisabled={true}
              multiline
            />
            <CustomTableCell
              name={`${question.id}_comment`}
              index={questionIndex}
              multiline
              isDisabled={true}
              type={"text"}
              value={fullFormData[`${question.id}_comment`]}
              numRows={question.type.length > 0 ? question.type.length : 1}
            />
            <TableCell align="center">
              <CopyableTooltip
                comment={
                  question.importHistoricData &&
                  previousAnswers &&
                  previousAnswers.length
                    ? previousAnswers.map((res) => {
                        if (res.questionId == question.id) {
                          return res.comment;
                        }
                        return "";
                      })
                    : "-"
                }
                answer={
                  question.importHistoricData &&
                  previousAnswers &&
                  previousAnswers.length
                    ? previousAnswers.map((res) => {
                        if (res.questionId == question.id) {
                          return res.responseText === "true" ? "Yes" : "No";
                        }
                        return "";
                      })
                    : "-"
                }
              />
            </TableCell>
             


               </>
        ) : (
          <>
            {question.type && (
              <>
                <TableCell align="center">
                  {question.subQuestion ? question.subQuestion[0]?.text : "-"}
                </TableCell>
                <TableCell align="center">{question.unit}</TableCell>
                <CustomTableCell
                  name={`${
                    question.subQuestion
                      ? `${question.id}_sub_${question.subQuestion[0]?.subQuestionId}`
                      : question.id
                  }_answer`}
                  index={questionIndex}
                  multiline
                  isDisabled={true}
                  type={getTypeFromId(
                    question.unit === "" ? 2 : question.questionAnswerTypeId
                  )}
                  value={
                    fullFormData[
                      `${
                        question.subQuestion
                          ? `${question.id}_sub_${question.subQuestion[0]?.subQuestionId}`
                          : question.id
                      }_answer`
                    ] == "false"
                      ? ""
                      : fullFormData[
                          `${
                            question.subQuestion
                              ? `${question.id}_sub_${question.subQuestion[0]?.subQuestionId}`
                              : question.id
                          }_answer`
                        ]
                  }
                />
                <CustomTableCell
                  name={`${question.id}_comment`}
                  index={questionIndex}
                  multiline
                  isDisabled={true}
                  type={"text"}
                  value={fullFormData[`${question.id}_comment`]}
                  numRows={question.type.length > 0 ? question.type.length : 1}
                />
                <TableCell align="center">
                  <CopyableTooltip
                    comment={
                      question.importHistoricData &&
                      previousAnswers &&
                      previousAnswers.length
                        ? previousAnswers.map((res) => {
                            if (res.questionId == question.id) {
                              return res.comment;
                            }
                            return "";
                          })
                        : "-"
                    }
                    answer={
                      question.importHistoricData && question.subQuestion
                        ? previousAnswers && previousAnswers.length
                          ? previousAnswers.map((res) => {
                              let text = "";
                              res.eSGSubResponseDtos &&
                                res.eSGSubResponseDtos.map((subres) => {
                                  if (
                                    question.subQuestion &&
                                    subres.subQuestionId ==
                                      question.subQuestion[0]?.subQuestionId
                                  ) {
                                    text = subres.responseText;
                                  }
                                });
                              return text;
                            })
                          : "-"
                        : previousAnswers && previousAnswers.length
                        ? previousAnswers.map((res) => {
                            let text = "";
                            res.eSGSubResponseDtos &&
                              res.eSGSubResponseDtos.map((subres) => {
                                if (
                                  question.subQuestion &&
                                  subres.subQuestionId ==
                                    question.subQuestion[0].subQuestionId
                                ) {
                                  text = subres.responseText;
                                }
                              });
                            return text;
                          })
                        : "-"
                    }
                  />
                </TableCell>
              </>
            )}
          </>
        )}
      </TableRow>
      {question.type.length > 1 ? (
        question.subQuestion.slice(1).map((tpe, idx) => (
          <TableRow key={tpe.subQuestionId}>
            <TableCell key={idx} align="center">
              {tpe.text}
            </TableCell>
            <TableCell align="center">{question.unit}</TableCell>
            <CustomTableCell
              name={`${question.id}_sub_${tpe.subQuestionId}_answer`}
              index={questionIndex}
              booleanType={false}
              multiline
              isDisabled={true}
              value={
                fullFormData[`${question.id}_sub_${tpe.subQuestionId}_answer`]
              }
              type={getTypeFromId(
                question.unit === "" ? 2 : question.questionAnswerTypeId
              )}
            />
            <TableCell align="center">
              <CopyableTooltip
                comment={
                  question.importHistoricData &&
                  previousAnswers &&
                  previousAnswers.length
                    ? previousAnswers.map((res) => {
                        if (res.questionId == question.id) {
                          return res.comment;
                        }
                        return "";
                      })
                    : "-"
                }
                answer={
                  question.importHistoricData &&
                  previousAnswers &&
                  previousAnswers.length
                    ? previousAnswers.map((res) => {
                        let text = "";
                        res.eSGSubResponseDtos &&
                          res.eSGSubResponseDtos.map((subres) => {
                            if (subres.subQuestionId == tpe.subQuestionId) {
                              text = subres.responseText;
                            }
                          });
                        return text;
                      })
                    : "-"
                }
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <></>
      )}

      {question.children.map((subQuestionItem, itemIndex) => (
        <MultiRowTableRowHandler
          key={subQuestionItem.subQuestionId}
          previousAnswers={previousAnswers}
          {...props}
          question={subQuestionItem}
          questionIndex={itemIndex}
          
        />
      ))}
    </>
  );
};

MultiRowTableRowHandler.propTypes = {
  question: PropTypes.any,
  questionIndex: PropTypes.number,
  previousAnswers: PropTypes.array,
  handleFormInputChange: PropTypes.func,
  fullFormData: PropTypes.object,
  HtmlTooltip: PropTypes.func,
  onFormClose: PropTypes.func,
};





const CustomTableCell = ({
  name,
  multiline,
  numRows,
  type = "text",
  value = "",
}) => {
  const classes = useStyles(()=>({
    text: {
      "& input": {
        textAlign:"center"
      }
    }
  }));

  return (
    <TableCell
      component="th" 
      align="center"
      className={classes.tableCell}
      rowSpan={numRows}
    >
      {type === "boolean" ? (
        <Input
          className={classes.text}
          value={(value== true || value == "true")?"Yes":(((value== false || value == "false") && value !="")?"No":"-")}
          disabled={true}
        />
      ) : (
        <TextField
         InputProps={{
            inputProps: {
                style: { textAlign: "center" },
            }
          }}
          value={value}
          name={name}
          type={type}
          disabled={true}
          multiline={multiline}
          numrows={numRows}
          className={classes.text}
          style={{
            maxWidth: "80%",
            height: "auto",
            overflowWrap: "break-word",
            overflowY: "hidden",
            width: "400px",
            margin: "5px",
          }}
        />
      )}
    </TableCell>
  );
};

CustomTableCell.propTypes = {
  booleanType: PropTypes.bool,
  multiline: PropTypes.bool,
  numRows: PropTypes.number,
  name: PropTypes.string | PropTypes.object,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};