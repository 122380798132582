import React, { useState } from "react";
import LoadingMessage from "./LoadingMessage/LoadingMessage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../Utils/Oidc/userManager";
import { useHistory } from "react-router-dom";
import * as loggedInUserIdActions from "../../../store/actions/loggedInUserIdActions";
import PropTypes from "prop-types";
//TODO:For the future use Case
// import RedirectingMessage from "../RedirectingMessage/RedirectingMessage";
import "../RedirectingMessage/RedirectingMessage.css";
import { flushBreadCrumps } from "../../Factory/Molecules/Common/PageHeaders/TemplatePageTitle/M_BreadCrumbs.helper";
import {
  getGlobalSearchPermission,
  getRecordByProperty,
  validSubdomainUser,
  getColourScheme,
  getDefaultRedirectURLForUser,
} from "./apiService";
const CallbackPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  React.useEffect(() => {
    flushBreadCrumps();
  });

  const getTheme = async (user) => {
    let UserDefaultURL = localStorage.getItem("UserDefaultURL");
    let showSeach = localStorage.getItem("showSeach");

    //Dispatch LoggedIn User Data
    await props.getLoggedInUser();
    setIsLoading(true);
    if (!UserDefaultURL || showSeach == null) {
      // Calling global search permission API
      getGlobalSearchPermission(user);

      let email =
        props.state &&
        props.state.oidc &&
        props.state.oidc.user &&
        props.state.oidc.user.profile &&
        props.state.oidc.user.profile.email;

      if (email) {
        let apiPayload = {
          label: "contact",
          properties: {
            email: email,
          },
        };
        // Calling getRecordByProperty API
        getRecordByProperty(apiPayload, email);
      }
      await getColourScheme();
    }

    localStorage.removeItem("manageMenus");
    // Calling Valid validsubdomainuser API
    const validSubdomainUserResponse = await validSubdomainUser();

    //user validation for subdomain
    let redirectUrl = null;
    if (validSubdomainUserResponse) {
      if (user.state.pathname != "") {
        redirectUrl = user.state.pathname;
      } else {
        //user validation for get user landing url
        redirectUrl = await getDefaultRedirectURLForUser();
      }
    } else {
      redirectUrl = "/securelogout";
    }

    setIsLoading(false);
    history.push(redirectUrl ? redirectUrl : "/dashboard");
    localStorage.setItem(
      "UserDefaultURL",
      redirectUrl ? redirectUrl : "/dashboard"
    );
  };

  const successCallback = (user) => {
    getTheme(user);
  };

  const errorCallback = () => {
    history.push("/");
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <div>
        {isLoading && <LoadingMessage />}
        {!isLoading && <LoadingMessage />}
      </div>
    </CallbackComponent>
  );
};
function mapStateToProps(state) {
  return { state, loggedIn: state.getLoggedInUserReducer };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}
CallbackPage.propTypes = {
  getLoggedInUser: PropTypes.func,
  loggedinUser: PropTypes.func,
  loggedIn: PropTypes.array,
  currentPageId: PropTypes.string,
  content: PropTypes.any,
  state: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
