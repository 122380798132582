import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SurveyFilter, SurveyFilterYear } from "../components";
import * as esgApi from "../ESG_API/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@material-ui/core";
import { TextField, CircularProgress } from "@material-ui/core";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { Alert } from "@material-ui/lab";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { SurveyStatusMap } from "../ESG_Config";
import { addHeaderHightForESGPages } from "../utils";
import { useDispatch } from "react-redux";
import { getCurrentEventValue } from "../../../../../../store/actions/getEventValueAction";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: "8px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  active: {
    color: "white !important",
    "& .MuiTableSortLabel-icon": {
      color: "white !important",
    },
  },
  table: {
    position: "relative",
    overflowX: "auto",
    overflowY: "scroll",
  },
  button: {
    background: "#d7d7d7",
  },
  cell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionItem: {
    overflow: "auto",
    width: "98%",
    border: "1px solid #ccc",
    margin: "10px",
    padding: "4px 8px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
}));

const ESG_ScoreSummary = (props) => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [scoreSummaryResponses, setScoreSummaryResponses] = useState([]);
  const [isScoreFreezed, setIsScoreFreezed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [erromsg, setErrorMsg] = useState("");
  const [editActionPlanForm, setEditActionPlanForm] = useState([]);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [reloadCheck, setReloadCheck] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("portfolioInvestmentName");

  const dispatch = useDispatch();
  const [checkCurrentPageBool, setCheckCurrentPageBool] = useState(true);

  useEffect(() => {
    let commonFilter = window.document.getElementsByClassName(
      "MuiFormControl-root"
    );
    let titleHeading = window.document.getElementById("PageTitleWrapper");
    titleHeading.style.marginTop = "-0.4em";
    if (commonFilter[0] && commonFilter[0].classList.length > 1) {
      commonFilter[0].style.visibility = "hidden";
    }
  });
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = [...scoreSummaryResponses].sort((a, b) => {
    if (orderBy === "portfolioInvestmentName") {
      return order === "asc"
        ? a.portfolioInvestmentName.localeCompare(b.portfolioInvestmentName)
        : b.portfolioInvestmentName.localeCompare(a.portfolioInvestmentName);
    } else if (orderBy === "calculatedScore") {
      return order === "asc"
        ? a.calculatedScore - b.calculatedScore
        : b.calculatedScore - a.calculatedScore;
    }
    return 0;
  });

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setErrorMsg("");
  };

  const navigateToAsset = (row) => {
    let templateId = props.content.ESGRatingTemplateId;
    window.open(
      `/${props.content.ASSETS_PATH}/${row.crmid}/${templateId}`,
      "_blank"
    );
  };

  const navigateToMaturity = (row) => {
    let templateId = props.content.ESGMaturityTemplateId;
    window.open(
      `/${props.content.ASSETS_PATH}/${row.crmid}/${templateId}`,
      "_blank"
    );
  };

  const handleScoreFreeze = async () => {
    try {
      const response = await esgApi.setScoreFreezeBySurveyId(surveyId);
      if (response && response.statusCode != 200) {
        setFailure(true);
        setErrorMsg(
          response && response.errorMsg
            ? response && response.errorMsg
            : "Some issue occured while freezing Score"
        );
        setIsScoreFreezed(false);
      } else {
        setReloadCheck(true);
        setSuccess(true);
        setIsScoreFreezed(true);
        setErrorMsg(response.data);
      }
    } catch (error) {
      setErrorMsg("Some issue occured while freezing Score");
    }
  };

  // to save nextsteps
  const handleFormChange = (row, value) => {
    dispatch(getCurrentEventValue(setCheckCurrentPageBool));
    const newFormData = editActionPlanForm.map((item) => {
      if (item.investmentScoreSummaryId === row.investmentScoreSummaryId) {
        item.nextSteps = value;
      }
      return item;
    });
    setEditActionPlanForm(newFormData);
    setIsFormEdited(true);
  };

  const handleSaveActionPlanData = async () => {
    try {
      if (isFormEdited) {
        setIsFormEdited(false);
        const response = await esgApi.saveScoreNextPlan(editActionPlanForm);
        if (response && response.statusCode != 200) {
          setFailure(true);
          setErrorMsg(
            response && response.errorMsg
              ? response && response.errorMsg
              : "Some issue occured while saving data"
          );
        } else {
          setReloadCheck(true);
          setSuccess(true);
          setErrorMsg("Data saved successfully");
        }
      }
    } catch (error) {
      setErrorMsg("Some issue occured while saving data");
    }
  };

  const setExistingFormData = (data) => {
    let formData = data.map((item) => {
      return {
        investmentScoreSummaryId: item.investmentScoreSummaryId,
        nextSteps: item.nextSteps,
      };
    });
    setEditActionPlanForm(formData);
  };

  const scoreSummaryDataShow = () => {
    setReloadCheck(false);
    if (surveyId != "") {
      const scoreSummaryResponses = async () => {
        // check survey response freeze for score freeze
        setLoading(true);
        let survey = await esgApi.getSurveyBySurveyId(surveyId);
        setIsScoreFreezed(
          survey.status == SurveyStatusMap.ScoreFreeze ? true : false
        );
        const assetData = await esgApi.getAllAsset();
        let scoreData = await esgApi.getScoreSummary(surveyId);
        if (scoreData && assetData && scoreData.length !== 0) {
          scoreData.forEach((item) => {
            const asset = assetData.find(
              (asset) => asset.investmentId == item.portfolioInvestmentId
            );
            item.portfolioInvestmentName = asset?.projectName
              ? asset.projectName
              : "";
            item.crmid = asset.crmid ? asset.crmid : null;
          });
          scoreData.sort((a, b) =>
            a.portfolioInvestmentName.localeCompare(b.portfolioInvestmentName)
          );
        }
        setScoreSummaryResponses(scoreData ? scoreData : []);
        // set existing form data
        setExistingFormData(scoreData ? scoreData : []);
        setLoading(false);
      };
      scoreSummaryResponses();
    }
  };
  useEffect(() => {
    scoreSummaryDataShow();
  }, [surveyId]);

  useEffect(() => {
    if (reloadCheck) {
      scoreSummaryDataShow();
    }
  }, [reloadCheck]);

  useEffect(() => {
    const cleanupFunction = addHeaderHightForESGPages();
    return cleanupFunction;
  }, []);

  return (
    <div>
      <SurveyFilter
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        surveyClosetoggle={true}
        heading={"Closed Survey"}
        style={{ styleTop: "112px" }}
      />
      <SurveyFilterYear
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        setSurveyFilterYear={setSurveyYear}
        surveyClosetoggle={true}
        heading={"Years"}
        style={{ styleTop: "112px" }}
      />
      {!loading && scoreSummaryResponses.length !== 0 ? (
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: "150px" }}>
                    <TableSortLabel
                      active={orderBy === "portfolioInvestmentName"}
                      className={classes.active}
                      direction={
                        orderBy === "portfolioInvestmentName" ? order : "asc"
                      }
                      onClick={() =>
                        handleRequestSort("portfolioInvestmentName")
                      }
                    >
                      Assets
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === "calculatedScore"}
                      className={classes.active}
                      direction={orderBy === "calculatedScore" ? order : "asc"}
                      onClick={() => handleRequestSort("calculatedScore")}
                    >
                      ESG Rating
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "400px" }} align="center">
                    Key Notes
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "auto" }}>
                {sortedRows.map((row, idx) => (
                  <>
                    <StyledTableRow key={row.investmentScoreSummaryId}>
                      <StyledTableCell
                        className={classes.cell}
                        component="th"
                        scope="row"
                        style={{ paddingLeft: "15px", color: "blue" }}
                      >
                        <div
                          onClick={() => navigateToAsset(row)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {row.portfolioInvestmentName}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.cell}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <div
                          onClick={() => navigateToMaturity(row)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {parseFloat(row.calculatedScore).toFixed(3) || 0}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.cell}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <TextField
                          id="outlined-multiline-static"
                          InputProps={{ disableUnderline: true }}
                          minRows={3}
                          maxRows={3}
                          multiline
                          defaultValue={row.nextSteps}
                          className={classes.actionItem}
                          disabled={isScoreFreezed ? true : false}
                          onChange={(e) =>
                            handleFormChange(
                              row,
                              e.target.value ? e.target.value : ""
                            )
                          }
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ textAlign: "right" }}>
            <A_Button
              textAlign={"right"}
              label={"Save"}
              style={{ margin: "6px 0px" }}
              color={"primary"}
              onClick={handleSaveActionPlanData}
              disabled={isScoreFreezed ? true : isFormEdited ? false : true}
            ></A_Button>
            <A_Button
              textAlign={"right"}
              label={"Freeze"}
              style={{ margin: "6px 0px" }}
              color={"primary"}
              onClick={handleScoreFreeze}
              disabled={isScoreFreezed}
            ></A_Button>
          </div>
        </div>
      ) : loading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </div>
      ) : !surveyId ? (
        <Alert severity="info">
          All active surveys are either live or unpublished.
        </Alert>
      ) : (
        <Alert severity="error">
          There is no response to show,no rating questions are found for this
          survey.
        </Alert>
      )}
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }}
      ></div>
      <A_Snackbar
        open={success}
        message={erromsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
    </div>
  );
};
ESG_ScoreSummary.propTypes = {
  content: PropTypes,
};

export default ESG_ScoreSummary;
