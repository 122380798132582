import * as httpCall from "../../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../Utils/apis/apis";

export const getUsersList = async () => {
  try {
    let url = `${process.env.IS4_SERVICE}/AspNetUsers/getwithTenantIdAlongWithClaims`;
    const result = await httpCall.httpGet(url);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};

export const getUserGroupList = async (userId) => {
  try {
    let url = `${process.env.IS4_SERVICE}/GroupToUserMapping/getGroupsOfAUser?userId=${userId}`;
    const result = await httpCall.httpGet(url);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const getAllGroups = async () => {
  try {
    let url = `${process.env.IS4_SERVICE}/Group/get`;
    const result = await httpCall.httpGet(url);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};

export const getAllUsersBasedOnGroupId = async (id) => {
  try {
    let url = `${process.env.IS4_SERVICE}/Group/getUsersInAGroup/${id}`;
    const result = await httpCall.httpGet(url);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const addUserToGroups = async (payload) => {
  try {
    let url = `${process.env.IS4_SERVICE}/GroupToUserMapping/InsertGroupToUserMapping?sendEmail=true`;
    const result = await httpCall.httpPost(url, payload, "", true);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const removeUserToGroups = async (userId, groupId) => {
  try {
    let url = `${process.env.IS4_SERVICE}/GroupToUserMapping/DeleteGroupToUserMapping/${groupId}/${userId}`;
    const result = await httpCall.httpDelete(url, "", true);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const creatUser = async (userDetails) => {
  try {
    let url = `${process.env.IS4_SERVICE}/AspNetUsers/insertV2`;
    const result = await httpCall.httpPost(url, userDetails, "", true);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const updateUser = async (userDetails) => {
  try {
    let url = `${process.env.IS4_SERVICE}/AspNetUsers/updateV2`;
    const result = await httpCall.httpPut(url, userDetails, "", true);
    return result ? result : [];
  } catch (error) {
    return [];
  }
};
export const getExcel = async (payload) => {
  try {
    let config = commonFunctions.getHttpConfig();
    let url = `${process.env.IS4_SERVICE}/AspNetUsers/getUserListinExcel`;
    const response = await httpPost(url, payload, {
      ...config,
      responseType: "blob",
    });
    const file = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", "UserList.xlsx");
    link.click();
  } catch (error) {
    console.error("Error downloading the Excel file:", error);
  }
};
