/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogContent,
  DialogActions,
  Chip,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import "../ESG.css";
import A_SummerNote from "../../../../Atoms/Inputs/SummerNote/A_SummerNote";
import { logicData } from "../ESG_Config";
import Backdrop from "@material-ui/core/Backdrop";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles(() => ({
  addQuestionnaire: {
    position: "fixed",
    top: "106px",
    right: "26px",
    zIndex: "2",
  },
  customWidth: {
    width: "40.5ch",
  },
  dialog: {
    width: "100%",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
  questionText: {
    width: "350px",
    marginRight: "30px",
  },
  logic: {
    width: "150px",
  },
  type: {
    width: "496px",
  },
  addIcon: {
    marginTop: "20px",
    marginLeft: "13px",
  },
  unitOfMeasure: {
    marginLeft: "5px",
    width: "136px",
  },
  chipData: {
    marginRight: "5px",
  },
  chip: {
    marginTop: "5px",
  },
  static: {
    marginTop: "-15px",
  },
  rating: {
    marginTop: "5px",
  },
  menuitemtext: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    display: "flex",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%,0%)",
    zIndex: "1",
  },
  loading_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: "100%",
    height: "150px",
  },
}));

const QuestionForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const {
    pillar,
    subpillar,
    maturityassessment,
    paiframework,
    dataconvergenceframework,
    questionanstype,
    setGuidance,
    loading,
    formDataLoading,
    parentquestion,
    filterparentquestion,
    edit,
    isButtonDisabled,
    alertvalidation,
    radio,
    setRadio,
  } = props;
  const handleGuidanceChange = (e) => {
    let guidanceText = e.replace(/<[^>]*>/g, "");

    const entityMap = {
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&apos;": "'",
      "&nbsp;": " ",
      "&amp;": "&",
      "&copy;": "©",
      "&reg;": "®",
      "&trade;": "™",
      "&euro;": "€",
    };

    for (const entity in entityMap) {
      if (guidanceText.includes(entity)) {
        guidanceText = guidanceText.replace(
          new RegExp(entity, "g"),
          entityMap[entity]
        );
      }
    }

    props.formValues.guidance = guidanceText;
    setGuidance(guidanceText);
  };

  const handleRadioChange = (e) => {
    const trigger = e.target.value;
    setRadio(trigger);
  };
  useEffect(() => {
    if (edit == true) {
      setIsLoading(true);
    }
  }, [edit]);
  useEffect(() => {
    if (props.formValues.pillar) {
      setIsLoading(false);
    }
  }, [props.formValues]);
  return (
    <div>
      {loading && (
        <div className="loading-container">
          <CircularProgress
            className="circular-progress"
            size={20}
            color="inherit"
          />
        </div>
      )}
      {formDataLoading && (
        <div className={classes.loading_container}>
          <CircularProgress
            className="circular-progress"
            size={20}
            color="inherit"
          />
        </div>
      )}
      {alertvalidation == false && (
        <Alert severity="error" className={classes.errorMessage}>
          <strong>Please fill out the inputs</strong>
        </Alert>
      )}
      {!formDataLoading && (
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel required error={props.codeError.pillar}>
              Pillar
            </InputLabel>
            <Select
              name="pillar"
              value={props.formValues.pillar}
              onChange={props.handleChange}
              error={props.codeError.pillar}
              helperText={"This field is Required"}
            >
              {pillar &&
                pillar.map((item, index) => {
                  return (
                    <MenuItem value={item.name} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel required error={props.codeError.subPillar}>
              Sub-Pillar
            </InputLabel>
            <Select
              name="subPillar"
              value={props.formValues.subPillar}
              onChange={props.handleChange}
              error={props.codeError.subPillar}
            >
              {subpillar &&
                subpillar.map((item, index) => {
                  return (
                    <MenuItem value={item.name} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel required error={props.codeError.maturityAssessment}>
              Maturity Assessment
            </InputLabel>
            <Select
              name="maturityAssessment"
              value={props.formValues.maturityAssessment}
              onChange={props.handleChange}
              error={props.codeError.maturityAssessment}
            >
              {maturityassessment &&
                maturityassessment.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.maturityassessmentid}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            required
            error={props.codeError.isConditionalQuestion}
          >
            <InputLabel>Is Conditional Question ?</InputLabel>
            <Select
              name="isConditionalQuestion"
              value={props.formValues.isConditionalQuestion}
              onChange={props.handleChange}
              error={props.codeError.isConditionalQuestion}
            >
              {parentquestion && parentquestion.length > 0 && (
                <MenuItem value="Yes">Yes</MenuItem>
              )}
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          {props.formValues.isConditionalQuestion == "Yes" && (
            <FormControl
              fullWidth
              required
              error={props.codeError.parentQuestion}
            >
              <InputLabel>Parent Question</InputLabel>
              <Select
                name="parentQuestion"
                value={props.formValues.parentQuestion}
                onChange={props.handleChange}
                error={props.codeError.parentQuestion}
              >
                {filterparentquestion && filterparentquestion.length > 0 ? (
                  filterparentquestion.map((item) => {
                    return (
                      <MenuItem
                        value={item.questionText}
                        key={item.questionId}
                        className="menuitemtext"
                        style={{
                          width: "537px",
                          whiteSpace: "normal",
                          overflowWrap: "break-word",
                        }}
                      >
                        {item.questionText}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value="" disabled>
                    No parent question found for pillar and subpillar
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          {props.formValues.isConditionalQuestion && (
            <TextField
              label="Question Text"
              name="question"
              value={props.formValues.question}
              onChange={props.handleChange}
              required
              error={props.codeError.question}
              className={
                props.formValues.isConditionalQuestion == "Yes"
                  ? classes.questionText
                  : ""
              }
              fullWidth={props.formValues.isConditionalQuestion == "No"}
            />
          )}
          {props.formValues.isConditionalQuestion === "Yes" && (
            <FormControl
              className={classes.logic}
              required
              error={props.codeError.logic}
            >
              <InputLabel error={props.codeError.logic}>Logic</InputLabel>
              <Select
                name="logic"
                value={props.formValues.logic}
                onChange={props.handleChange}
              >
                {logicData &&
                  logicData.map((item) => {
                    return (
                      <MenuItem value={item.name} key={item.logicId}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
          <br />

          {props.formValues.isConditionalQuestion && (
            <A_SummerNote
              onChange={handleGuidanceChange}
              disabled={false}
              label={"Guidance"}
              defaultValue={props.formValues.guidance || ""}
            ></A_SummerNote>
          )}
          {props.formValues.isConditionalQuestion && (
            <TextField
              label="Unit of Measure"
              name="unit"
              value={props.formValues.unit}
              onChange={props.handleChange}
              fullWidth
            />
          )}
          {props.formValues.isConditionalQuestion && (
            <>
              <TextField
                label="Breakdown"
                name="type"
                value={props.itemtype}
                onChange={props.handleChange}
                className={classes.type}
              />
              <AddIcon
                className={classes.addIcon}
                onClick={props.handleAddType}
              />{" "}
            </>
          )}
          {/* {props.formValues.isConditionalQuestion && (
          <FormControl className={classes.unitOfMeasure}>
            <InputLabel>Unit</InputLabel>
            <Select
              name="unitOfMeasure"
              value={props.formValues.unitOfMeasure}
              onChange={props.handleChange}
              disabled={props.unittoggle ? true : false}
            >
              {unitJsonData &&
                unitJsonData.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.measurementunitid}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )} */}
          <br />
          <div>
            {props.listtype.map((element) => {
              return (
                <span className={classes.chipData}>
                  <Chip
                    className={classes.chip}
                    label={element.text}
                    variant="outlined"
                    onDelete={() => props.handleDelete(element.text)}
                    style={{ maxWidth: "200px", wordWrap: "break-word" }}
                  />
                </span>
              );
            })}
          </div>
          <br />
          {props.formValues.isConditionalQuestion && (
            <FormControl
              fullWidth
              style={{
                marginTop: props.formValues.isConditionalQuestion
                  ? "-25px"
                  : "0",
              }}
            >
              <InputLabel required error={props.codeError.questionAnswerType}>
                Question Answer Type
              </InputLabel>
              <Select
                name="questionAnswerType"
                value={props.formValues.questionAnswerType}
                onChange={props.handleChange}
                error={props.codeError.questionAnswerType}
                fullWidth
              >
                {questionanstype && props.listtype && props.listtype.length > 0
                  ? questionanstype.slice(0, -1).map((item) => {
                      if (item.name === "Boolean") {
                        return (item = { ...item, name: "" });
                      }

                      return (
                        <MenuItem
                          value={item.name}
                          key={item.questionanswertypeid}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })
                  : questionanstype.map((item) => {
                      return (
                        <MenuItem
                          value={item.name}
                          key={item.questionanswertypeid}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          )}

          <FormControl
            fullWidth
            required
            error={props.codeError.paiFramework}
            style={{
              marginTop: !props.formValues.isConditionalQuestion
                ? "-48px"
                : "0",
            }}
          >
            <InputLabel>PAI Framework</InputLabel>
            <Select
              name="paiFramework"
              value={props.formValues.paiFramework}
              onChange={props.handleChange}
              error={props.codeError.paiFramework}
            >
              {paiframework &&
                paiframework.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.paiFrameworkid}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            required
            error={props.codeError.dataConvergenceFramework}
          >
            <InputLabel>Data Convergence Framework</InputLabel>
            <Select
              name="dataConvergenceFramework"
              value={props.formValues.dataConvergenceFramework}
              onChange={props.handleChange}
              error={props.codeError.dataConvergenceFramework}
            >
              {dataconvergenceframework &&
                dataconvergenceframework.map((item) => {
                  return (
                    <MenuItem
                      key={item.dataConvergenceFrameworkid}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            required
            error={props.codeError.newQuestionOrImportData}
          >
            <InputLabel>Import Historic Data</InputLabel>
            <Select
              name="newQuestionOrImportData"
              value={props.formValues.newQuestionOrImportData}
              onChange={props.handleChange}
              error={props.codeError.newQuestionOrImportData}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ marginTop: "20px" }}
            required
            error={props.codeError.staticOrUpdatedYearly}
          >
            <InputLabel className={classes.static}>
              Inherit &#40;if answer given in a prior year, do not ask question
              again/Updated yearly&#41;
            </InputLabel>
            <Select
              name="staticOrUpdatedYearly"
              value={props.formValues.staticOrUpdatedYearly}
              onChange={props.handleChange}
              error={props.codeError.staticOrUpdatedYearly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.rating}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Rating
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={
                props.edit
                  ? props.formValues.rating === "Yes"
                    ? "Yes"
                    : "No"
                  : radio
              }
              onChange={handleRadioChange}
            >
              <FormControlLabel value="No" control={<Radio />} label="No" />
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={props.handleSubmit}
          color="primary"
          variant="contained"
          style={{ marginTop: "-20px", marginRight: "12px" }}
          disabled={isButtonDisabled}
        >
          {props.edit ? "Update" : "Finish"}
        </Button>
      </DialogActions>
      <Backdrop style={{ color: "#fff", zIndex: 999 }} open={isLoading}>
        <CircularProgress
          color="inherit"
          style={{ color: "#fff", zIndex: 999 }}
        />
      </Backdrop>
    </div>
  );
};
QuestionForm.propTypes = {
  codeError: PropTypes.any,
  handleChange: PropTypes.any,
  handleClose: PropTypes.any,
  formValues: PropTypes.any,
  itemtype: PropTypes.any,
  sectortoggle: PropTypes.any,
  listtype: PropTypes.any,
  handleDelete: PropTypes.any,
  unittoggle: PropTypes.any,
  unitFixed: PropTypes.any,
  handleAddType: PropTypes.any,
  handleSubmit: PropTypes.any,
  pillar: PropTypes.any,
  subpillar: PropTypes.any,
  maturityassessment: PropTypes.any,
  paiframework: PropTypes.any,
  dataconvergenceframework: PropTypes.any,
  questionanstype: PropTypes.any,
  setGuidance: PropTypes.any,
  loading: PropTypes.any,
  formDataLoading: PropTypes.any,
  edit: PropTypes.any,
  parentquestion: PropTypes.any,
  filterparentquestion: PropTypes.any,
  isButtonDisabled: PropTypes.any,
  alertvalidation: PropTypes.any,
  setAlertValidation: PropTypes.any,
  radio: PropTypes.any,
  setRadio: PropTypes.any,
};

export default QuestionForm;
