import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import Axios from "axios";
import {
  FromEpochFormat,
  convertCurrentEpochToDateFormat,
} from "../../../../../../Utils/Helpers/dateUtils";
import { httpPost } from "../../../../../../Utils/apis/apis";
import Slide from "@material-ui/core/Slide/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Paper from "../../../../Atoms/Surfaces/Papers/A_Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import { resetToolbarFilters } from "../../../../../../store/actions/manageToolbarSelectionsActions";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_DatePicker from "../../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_Select from "../../../../Atoms/Inputs/Selects/A_Select";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePowerpoint, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ChangeLog from "../../ChangeLog/ChangeLog.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HistoryIcon from "@material-ui/icons/History";
import DeleteIcon from "@material-ui/icons/Delete";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import RecordMerge from "../../../../Pages//RecordMerge/RecordMerge";
import PropTypes from "prop-types";
import DeleteCRMRecord from "./Tools/DeleteCRMRecord";
import * as toastActions from "../../../../../../store/actions/toastActions";
import { pageConfig } from "../../../../../../Utils/config/config";
import FolderIcon from "@material-ui/icons/Folder";
import { useMediaQuery } from "@material-ui/core";
import O_IRCSlots from "../../../../Organisms/Common/IRCSlots/O_IRCSlots";
import M_ManageEntityForm from "../../Navigations/AddEntity/M_ManageEntityForm";
import { addDynamicValueforHeader } from "../../../../../../Utils/Helpers/generciParamHelper";
import { getObjectFromJSONString } from "../../Navigations/AddEntity/M_ManageEntityForm.helper";
import JlvaluationReport from "./Tools/JlvaluationReport";
import { message } from "../../../../../../Utils/config/messages";
import {
  toastSeverity,
  toastType,
} from "../../../../../../Utils/config/toastConfig";
import { hasPageFilter } from "../../Renderers/Views/Charts/M_ChartCard.Helper";
import * as commonFunctions from "../../../../../../Utils/Helpers/common";
import { enhanceEvalString } from "../../Renderers/Views/ResultCards/M_ResultCardHelper.js";

const useStyles = makeStyles((theme) => ({
  secondaryIcon: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "20",
    },
  },
  dropdowns: {
    display: "flex",
    flexDirection: "row",
    gridColumnGap: "15px",
  },
  secondaryMenuButton: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-3em",
    },
    marginLeft: "-19px",
  },

  listspacing: {
    padding: "2px",
  },
  PaperHeight: {
    padding: "8px 0px",
  },
  hiddenDrawer: { visibility: "hidden" },
  visibleDrawer: { visibility: "visible" },
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    zIndex: 999,
  },
  paddingButton: {
    padding: "3px",
  },
}));
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const M_PageToolbar = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const spacing = 1;
  const [pageFilters, setPageFilters] = React.useState(undefined);
  const { templateId, crmId, pageUrl } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [pdfButtonLoading, setPdfButtonLoading] = React.useState(false);
  const [pdfButtonLabel, setPdfButtonLabel] = React.useState("");
  const [pptLoading, setPptLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [bookIRC, setBookIRC] = React.useState(false);
  const [changeLog, setChangeLog] = React.useState(false);
  const [deleteCRM, setDeleteCRM] = React.useState(false);
  const [isdownloadExcelPopup, setIsdownloadExcelPopup] = React.useState(false);
  const [itemData, setItemData] = React.useState([]);
  const [visibility, setVisibility] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [formTemplateId, setFormTemplateId] = React.useState("");
  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const [entity, setEntity] = React.useState({});
  const [investmentName, setInvestmentName] = React.useState("");
  const [triggerLoading, setTriggerLoading] = React.useState(false);
  const [cancelT, setCancelT] = React.useState(null);
  const dispatch = useDispatch();
  const toolbar = props.toolbar;
  var selectedSeries = null;
  const { currentPage, user, onReload } = props;
  var email = user.profile && user.profile.email ? user.profile.email : null;
  var emailData =
    currentPage && currentPage.data && currentPage.data.email
      ? currentPage.data.email
      : null;
  var show = true;
  if (email == emailData) {
    show = false;
  } else {
    show = true;
  }
  React.useEffect(() => {
    setAnchorEl(null);
  }, [templateId]);
  // Triple Dots Popup START
  const handleOptions = (event) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };
  const openOptions = Boolean(anchorElement);

  const OptionId = openOptions ? "simple-popper" : undefined;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBookIRC = () => {
    setBookIRC(true);
  };
  const handleTriggerCall = async (callDetails) => {
    try {
      setTriggerLoading(true);
      if (callDetails) {
        let responseData = await httpPost(
          enhanceEvalString(callDetails.url || "", { crmId }),
          callDetails.payload ? { ...callDetails.payload } : {}
        );
        if (getObjectFromJSONString(responseData, ".message")) {
          props.setToast({
            type: toastType.DELETE_CRM,
            severity: toastSeverity.ERROR,
            message: message.SOMETHING_WENT_WRONG,
            status: true,
          });
        } else {
          props.setToast({
            type: toastType.DELETE_CRM,
            severity: toastSeverity.SUCCESS,
            message: message.SUCCESS,
            status: true,
          });
          setTimeout(() => props.getCurrentPage({ pageUrl, crmId }), 1000);
        }
      }
    } catch (e) {
      //TODO: log details in time of debugging if needed
      // console.log(e);
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setTriggerLoading(false);
  };
  const checkTemplate = (templateList) => {
    return (
      templateList &&
      Array.isArray(templateList) &&
      templateList.includes(templateId)
    );
  };
  const handleClickChangeLog = () => {
    setChangeLog(true);
  };
  const handleClickDelete = () => {
    setDeleteCRM(true);
  };
  const handleDeleteEvent = () => {
    props.setToast({
      type: "",
      severity: "",
      message: "",
      status: false,
    });
    setDeleteCRM(false);
  };

  let handlePopup = () => {
    setOpen(false);
  };
  let handleChangelogPopup = () => {
    setChangeLog(false);
  };
  //Conditions closed

  React.useEffect(() => {
    let titleFieldName = "";
    const availableFields = props.currentPage.pageConfig.fields;
    titleFieldName = availableFields.filter((field) => {
      return field.isPageTitle;
    });
    titleFieldName.length > 0 && props.currentPage && props.currentPage.data
      ? setInvestmentName(props.currentPage.data[titleFieldName[0].name])
      : "";
  }, []);

  React.useEffect(() => {
    if (toolbar && toolbar.primary && toolbar.primary.filters) {
      const inScopeFilters = toolbar.primary.filters.filter(
        (item) => item.templateIds && item.templateIds.indexOf(templateId) > -1
      );
      if (inScopeFilters && inScopeFilters.length > 0) {
        setPageFilters(inScopeFilters);
      } else {
        setPageFilters(undefined);
      }
    } else {
      setPageFilters(undefined);
    }
  }, [templateId]);

  const openPopup = Boolean(anchorEl);
  // React.useLayoutEffect(() => {
  //   // dispatch(resetToolbarFilters());
  // }, [pageFilters]);

  const id = open ? "simple-popper" : undefined;
  const getObjectBasedProperties = (data = [], modify) => {
    if (modify) {
      let arr = [];
      data.length > 0 &&
        data.map((item) => {
          arr.push({
            id: item,
            crmId: crmId,
          });
        });
      return arr;
    } else {
      let obj = {};
      data.length > 0 &&
        data.map((item) => {
          obj[item] = crmId;
        });
      return obj;
    }
  };
  const handlePptDownload = async (ppt, name) => {
    setVisibility(true);
    setPptLoading(true);
    let params = {};
    name && name.parameters
      ? Object.keys(name.parameters).map((item) => {
          if (name.parameters[item].charAt(0) === `=`) {
            let propertyName = name.parameters[item].substr(
              1,
              name.parameters[item].length - 1
            );
            params[item] =
              props.currentPage && props.currentPage.data
                ? props.currentPage.data[propertyName]
                : "";
          } else {
            params[item] = name.parameters[item];
          }
        })
      : "";
    const pptName = name.label;
    const pptId = name.id;
    let convertedFormData = {};
    if (pptId !== null) {
      if (ppt.parameters !== null) {
        convertedFormData = {
          exportAsPDF: name && name.exportAsPDF ? name.exportAsPDF : undefined,
          parameters: params,
        };
      } else {
        convertedFormData = {};
      }

      if (name && name.modifiedPayload) {
        convertedFormData = {
          parameters: params,
          map: getObjectBasedProperties(
            name.modifiedPayload && name.modifiedPayload.keyNames,
            true
          ),
          commonParameters: getObjectBasedProperties(
            name.modifiedPayload && name.modifiedPayload.commonParameters,
            false
          ),
        };
      }
      if (name && name.usePageLevelFilter) {
        let pageLevelFilter = {};
        props.filters.forEach((item) => {
          pageLevelFilter[item.filterName] = item.value;
        });
        convertedFormData["commonParameters"] = {
          ...(convertedFormData["commonParameters"] || {}),
          ...pageLevelFilter,
        };
        if (ppt.filterTextPlaceholders || name.filterTextPlaceholders) {
          let filterTextPlaceholders = [];
          filterTextPlaceholders = props.filters.map((item) => ({
            id: item.filterName,
            label: item.data?.label || item.FilterText,
            value: item.data?.value || item.value,
          }));
          convertedFormData["filterTextPlaceholders"] = filterTextPlaceholders;
        }
      }
      let url = `${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`;

      const response = await httpPost(url, convertedFormData, {
        responseType: "blob",
      });
      setPptLoading(false);
      setVisibility(false);
      const url1 = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url1;
      if (name && name.exportAsPDF) {
        link.setAttribute("download", pptName + ".pdf");
      } else {
        link.setAttribute("download", pptName + ".pptx");
      }
      link.click();
    }
  };
  let cancelToken;
  const handlePDFDownload = async (template, templateId) => {
    if (
      (toolbar &&
        toolbar.primary &&
        toolbar.primary.downloadPdfButton &&
        toolbar.primary.downloadPdfButton.tearsheet) ||
      template.tearsheet
    ) {
      setPdfButtonLoading(true);
      if (templateId) setPdfButtonLabel(templateId);

      setButtonDisabled(true);
      try {
        let customHtml =
          document.getElementById("TearsheetPDF") &&
          document.getElementById("TearsheetPDF").innerHTML
            ? document.getElementById("TearsheetPDF").innerHTML
            : "";

        let url = `${process.env.MINERVA_CHART_PDF}pdf?`;

        let payload = {
          filename: "TearsheetPDF",
          html: customHtml,
        };
        // TODO Dupliacte need to be removed
        const response = await httpPost(url, payload, {
          responseType: "blob",
        });
        const url1 = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url1;
        const fileName = "Name";
        let subDomain = fileName
          ? props.currentPage.data &&
            props.currentPage.data[fileName] &&
            props.currentPage.data[fileName]
          : new URL(window.location.href).hostname.split(".")[0];
        subDomain = capitalizeFirstLetter(subDomain);
        link.setAttribute(
          "download",
          subDomain + "_Reports " + new Date() + ".pdf"
        );
        link.click();
        setPdfButtonLoading(false);
        setButtonDisabled(false);
      } catch {
        setPdfButtonLoading(false);
        setButtonDisabled(false);
      }
    } else {
      const reportId =
        toolbar &&
        toolbar.primary &&
        toolbar.primary.downloadPdfButton &&
        toolbar.primary.downloadPdfButton.reportID
          ? toolbar.primary.downloadPdfButton.reportID
          : template.reportID;
      const fileName =
        toolbar &&
        toolbar.primary &&
        toolbar.primary.downloadPdfButton &&
        toolbar.primary.downloadPdfButton.fileName
          ? toolbar.primary.downloadPdfButton.fileName
          : template.fileName;
      if (reportId !== null) {
        setPdfButtonLoading(true);
        if (templateId) setPdfButtonLabel(templateId);
        setButtonDisabled(true);
        try {
          let storedChartKeys = localStorage.getItem("chartStorageKeys");
          let parseArray = [];
          let chartKeysArr = storedChartKeys ? storedChartKeys.split("__") : [];
          chartKeysArr.shift();
          chartKeysArr.map((item) => {
            parseArray.push(JSON.parse(item));
          });

          let pdfUrl = `${process.env.PDF_DOWNLOAD_URL}PDF/download/${reportId}`;
          const pageFilters = props.toolbarSelections.filters;
          let parameters = {};
          if (pageFilters && hasPageFilter(props.currentPage)) {
            pageFilters.forEach((pageFilter) => {
              parameters[pageFilter.filterName] = pageFilter.value;
            });
          }
          let pdfpayload = {
            parameters,
            jsonParameters: {},
            chartPayloads: parseArray ? parseArray : [],
          };
          if (cancelT) {
            cancelT.cancel("Operation canceled due to new request.");
          }
          cancelToken = Axios.CancelToken.source();
          setCancelT(cancelToken);
          const res = await httpPost(pdfUrl, pdfpayload, {
            cancelToken: cancelToken.token,
          });

          if (Axios.isCancel(res)) {
            // console.log("Request canceled:", res.message);
            return;
          }

          let url = `${process.env.MINERVA_CHART_PDF}pdf?`;
          //TODO This function is not working as expected need to change this method
          // res.html = regexMatchAndReplaceFromDocument(res.html, "/<<(.+?)>>/g");
          while (res.html.match(/<<(.+?)>>/) !== null) {
            let chartIds = res.html.match(/<<(.+?)>>/);
            let chartId = chartIds && chartIds[1] ? chartIds[1] : null;
            let customHtml =
              chartId &&
              (document.getElementById(chartId).innerHTML
                ? document.getElementById(chartId).innerHTML
                : "");
            if (customHtml) {
              res.html = res.html.replace(chartIds[0], customHtml);
            }
          }
          let payload = {
            filename: res.filename,
            html: res.html,
            landscape: toolbar.primary.downloadPdfButton.landscape
              ? toolbar.primary.downloadPdfButton.landscape
              : false,
          };

          const response = await httpPost(url, payload, {
            responseType: "blob",
          });

          const url1 = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url1;
          let subDomain = fileName
            ? props.currentPage.data &&
              props.currentPage.data[fileName] &&
              props.currentPage.data[fileName]
            : new URL(window.location.href).hostname.split(".")[0];
          subDomain = capitalizeFirstLetter(subDomain);
          link.setAttribute(
            "download",
            subDomain + "_Reports " + new Date() + ".pdf"
          );
          link.click();

          setPdfButtonLoading(false);
          setButtonDisabled(false);
        } catch (error) {
          setPdfButtonLoading(false);
          setButtonDisabled(false);
        }
      }
    }
  };
  // JL Excel Download
  const handleJLExcelDownlod = async () => {
    setOpen(true);
    let config = commonFunctions.getHttpConfig();
    const fileName =
      toolbar &&
      toolbar.primary &&
      toolbar.primary.JLdownloadExcelButton &&
      toolbar.primary.JLdownloadExcelButton.fileName
        ? toolbar.primary.JLdownloadExcelButton.showDate
          ? `${convertCurrentEpochToDateFormat()} ${
              toolbar.primary.JLdownloadExcelButton.fileName
            }`
          : toolbar.primary.JLdownloadExcelButton.fileName
        : "Summary Report";
    const response = await httpPost(
      `/ChartData/download-jl-pipeline-report`,
      {},
      {
        ...config,
        responseType: "blob",
      }
    );
    setOpen(false);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    link.click();
  };
  const handlePptList = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleButtonClicked = async (
    item,
    templateId,
    crmId,
    params,
    callBack
  ) => {
    setButtonLoading(true);
    let newParam = Object.assign(item.parameters);
    if (newParam) {
      for (const key in newParam) {
        if (
          newParam[key] &&
          typeof newParam[key] == "string" &&
          newParam[key].charAt(0) == "="
        ) {
          if (newParam[key] == "=crmId") {
            newParam[key] = crmId;
          }
        }
      }
      newParam["seriesList"] = params["seriesList"];
      newParam["series"] = params["series"];
      newParam["year"] = params["fromYear"] ? params["fromYear"] : Date();
      newParam["InvestmentName"] = params["InvestmentName"];
    }
    let newName =
      props &&
      props.currentPage &&
      props.currentPage.data &&
      props.currentPage.data.projectName
        ? props.currentPage.data.projectName
        : "ExcelFile";
    let fileName = `${newName}.xlsx`;

    let url = `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/GetExcelTemplate`;
    const response = await httpPost(url, newParam, {
      responseType: "blob",
      headers: {
        "x-functions-key": process.env.EXCEL_UPLOAD_API_KEY,
      },
    });
    setIsdownloadExcelPopup(false);
    setButtonLoading(false);
    callBack(false);
    const url1 = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", fileName);
    link.click();
  };
  const renderDownloadExcelPopup = () => {
    let configutedOptions = itemData.series;
    const dateRange = "singleYear";
    const [isDownloadable, setIsDownloadable] = React.useState(false);
    let downloading = false;
    const filterObject = {
      dateRange: dateRange,
      singleYear: new Date(),
      startMonth: new Date(),
      endMonth: new Date(),
      series: selectedSeries
        ? selectedSeries
        : configutedOptions && configutedOptions.length > 0
        ? configutedOptions[0]
        : "Actual",
    };
    const requestPayload = {
      Tabs:
        props.manageExcelTemplates && props.manageExcelTemplates.length > 0
          ? props.manageExcelTemplates.map((item) => item.tabTitle)
          : [],
    };

    const seriesOptions = [
      { label: "Actual", value: "Actual" },
      { label: "Forecast", value: "Forecast" },
      { label: "Budget", value: "Budget" },
    ];
    const handleDateChange = (date, id) => {
      const dateInStdForm = FromEpochFormat(date);
      filterObject[id] = dateInStdForm;
    };
    const handleSeriesChange = (val, id) => {
      filterObject[id] = val.value;
      selectedSeries = val.value;
    };
    const getDateRange = (data) => {
      let end = data["startMonth"];
      let start = data["endMonth"];
      var months;
      months = (start.getFullYear() - end.getFullYear()) * 12;
      months -= end.getMonth();
      months += start.getMonth();
      return months <= 0 ? 0 : months;
    };
    const handleDownloadExcel = (e) => {
      setIsDownloadable(true);
      let year = null;
      let startMonth = null;
      let incrementMonth = null;
      if (filterObject["dateRange"] == "singleYear") {
        startMonth = 1;
        incrementMonth = 11;
        let d = new Date(filterObject.singleYear);
        year = d.getFullYear();
      } else {
        filterObject["singleYear"] = undefined;
        let d = new Date(filterObject.startMonth);
        incrementMonth = getDateRange(filterObject);
        year = d.getFullYear();
        startMonth = 1 + d.getMonth();
      }
      let param = {};
      param["seriesList"] =
        configutedOptions && configutedOptions.length > 0
          ? configutedOptions
          : seriesOptions.map((i) => i.label);
      param["series"] = filterObject["series"];
      param["fromYear"] = year;
      param["incrementMonth"] = incrementMonth + 1;
      param["dealId"] = crmId;
      param["startMonth"] = startMonth;
      param["InvestmentName"] = investmentName;
      e.target["innerText"] = "Downloading....";
      e.currentTarget["style"]["opacity"] = 0.4;
      e.currentTarget["disabled"] = true;
      downloading = true;
      param["requestPayload"] = requestPayload;

      handleButtonClicked(
        itemData,
        templateId,
        crmId,
        param,
        setIsDownloadable
      );
    };
    return (
      <div>
        <A_DatePicker
          id="singleYear"
          views={["year"]}
          defaultValue={filterObject.singleYear}
          onChange={handleDateChange}
          format="yyyy"
          label="Financial Year"
        />

        <div>
          <A_Select
            className={classes.selctBoxFilter}
            id="series"
            options={
              configutedOptions && configutedOptions.length > 0
                ? configutedOptions.map((i) => {
                    return { label: i, value: i };
                  })
                : seriesOptions
            }
            defaultValue={
              configutedOptions && configutedOptions.length > 0
                ? configutedOptions[0]
                : "Actual"
            }
            onChange={handleSeriesChange}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={handleDownloadExcel}
            color="primary"
            label="Download Template"
            disabled={isDownloadable}
            endIcon={
              downloading ? (
                <A_CircularIndeternment color="secondary" size={20} />
              ) : (
                <></>
              )
            }
          />
        </div>
      </div>
    );
  };
  const renderBookIRCSlot = () => {
    return (
      toolbar &&
      toolbar.bookIRC &&
      toolbar.bookIRC.show &&
      toolbar.bookIRC.templateId &&
      toolbar.bookIRC.templateId === templateId
    );
  };
  const renderForms = () => {
    let formList =
      toolbar && toolbar.forms && Array.isArray(toolbar.forms)
        ? toolbar.forms
        : [];

    let buttonList = formList
      .filter((item) => {
        if (
          item.templateIds &&
          Array.isArray(item.templateIds) &&
          item.templateIds.length > 0 &&
          item.templateIds.includes(templateId)
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((form) => (
        <A_Button
          key={form.label}
          color="primary"
          label={form.label}
          className={classes.globalbuttonStyle}
          onClick={() => {
            handleForm(
              true,
              form.formTemplateId ? form.formTemplateId : "",
              form.parameters ? form.parameters : {}
            );
          }}
          style={{ textTransform: "none" }}
        ></A_Button>
      ));
    return buttonList;
  };
  const handleForm = (value, formId, params) => {
    const parameters = addDynamicValueforHeader(params, props.params);
    let entity = {
      templateId: formId,
      data: "",
      recordId: "",
      parameters: parameters,
    };
    setFormTemplateId(formId);
    setEntity(entity);
    setIsFormOpen(value);
  };
  const handleClosePopup = () => {
    setIsdownloadExcelPopup(false);
  };
  const handleIRCForm = () => {
    setBookIRC(false);
  };
  function pptButtonVisibility() {
    const allTemplateids =
      toolbar &&
      toolbar.primary &&
      toolbar.primary.downloadPptButton &&
      toolbar.primary.downloadPptButton.templateIds
        ? toolbar.primary.downloadPptButton.templateIds
        : undefined;
    var status = false;
    if (allTemplateids != undefined && allTemplateids.length > 0) {
      for (let index = 0; index < allTemplateids.length; index++) {
        if (allTemplateids[index] == templateId) {
          status = true;
          break;
        } else {
          status = false;
        }
      }
    }
    return status;
  }
  try {
    var dealFolderLink =
      props.currentPage &&
      props.currentPage.data &&
      props.currentPage.data.ana_dealfolder
        ? props.currentPage.data.ana_dealfolder
        : props.currentPage.data.folderlink;
  } catch {
    dealFolderLink = undefined;
  }
  const dealFolder = (link) => {
    if (link) window.open(link, "_blank");
  };
  const [InitialBooleanValue, setInitialBooleanValue] = React.useState(false);
  const toggleBooleanValue = () => {
    InitialBooleanValue
      ? setInitialBooleanValue(false)
      : setInitialBooleanValue(true);
  };
  return isMobile ? (
    <>
      {" "}
      <div className="grid-item ">
        {/*Toolbar starts here */}
        <MoreVertIcon
          onClick={function () {
            toggleBooleanValue();
          }}
          style={{ marginLeft: "90%", marginTop: "-7%" }}
        ></MoreVertIcon>
        <div
          className={
            InitialBooleanValue ? classes.visibleDrawer : classes.hiddenDrawer
          }
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              position: "fixed",
              background: "rgb(101 115 123)",
              width: "44%",
              marginLeft: "56%",
              marginTop: "1.5%",
              borderRadius: "3%",
            }}
            spacing={spacing}
            className={`page-toolbar ${classes.paddingButton}`}
          >
            {/* <Grid item className={classes.dropdowns} key={templateId}>
      {toolbar &&
        pageFilters &&
        pageFilters.map((pageFilter, index) => {
          return (
            <M_ToolbarFilter
              key={pageFilter.ptionSetName}
              indexValue={index}
              toolbar={toolbar}
              {...pageFilter}
              optionSets={optionSets}
              templateId={templateId}
              investmentId={crmId}
            />
          );
        })}
    </Grid> */}

            <Grid item>
              {toolbar &&
                toolbar.primary &&
                toolbar.primary.downloadButton &&
                toolbar.primary.downloadButton.show && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <A_Button
                      style={{
                        textTransform: "none",
                        marginTop: "7px",
                        marginBottom: "7px",
                      }}
                      className={classes.globalbuttonStyle}
                      edge="start"
                      color="primary"
                      aria-label="add records"
                      label="Download"
                      endIcon={<GetAppIcon />}
                    />
                  </Box>
                )}
            </Grid>

            {toolbar &&
            toolbar.primary &&
            toolbar.primary.buttons &&
            toolbar.primary.buttons.length > 0
              ? toolbar.primary.buttons.map((item, index) =>
                  item && item.show && templateId == item.templateId ? (
                    <Grid key={index} item>
                      {" "}
                      <A_Button
                        edge="start"
                        color="primary"
                        aria-label="add records"
                        className={classes.globalbuttonStyle}
                        label={item.label}
                        onClick={() => {
                          setItemData(item);
                          setIsdownloadExcelPopup(true);
                        }}
                        endIcon={
                          buttonLoading ? (
                            <A_CircularIndeternment
                              color="secondary"
                              size={20}
                            />
                          ) : (
                            <GetAppIcon />
                          )
                        }
                      ></A_Button>
                    </Grid>
                  ) : (
                    ""
                  )
                )
              : ""}

            {toolbar && toolbar.primary && toolbar.primary.downloadPdfButton
              ? Array.isArray(toolbar.primary.downloadPdfButton)
                ? toolbar.primary.downloadPdfButton
                    .filter((item) => item.templateIds.includes(templateId))
                    .map((item) => (
                      <Grid item key={item.label}>
                        <A_Button
                          color="primary"
                          label={item.label}
                          disabled={
                            buttonDisabled && templateId == pdfButtonLabel
                              ? true
                              : false
                          }
                          className={classes.globalbuttonStyle}
                          onClick={() => handlePDFDownload(item, templateId)}
                          endIcon={
                            pdfButtonLoading && templateId == pdfButtonLabel ? (
                              <A_CircularIndeternment
                                color="secondary"
                                size={20}
                              />
                            ) : (
                              <GetAppIcon />
                            )
                          }
                        ></A_Button>
                      </Grid>
                    ))
                : (
                    (toolbar &&
                      toolbar.primary &&
                      toolbar.primary.downloadPdfButton &&
                      toolbar.primary.downloadPdfButton.templateIds) ||
                    []
                  ).includes(templateId) &&
                  toolbar.primary.downloadPdfButton.show && (
                    <Grid item>
                      <A_Button
                        color="primary"
                        label={toolbar.primary.downloadPdfButton.label}
                        disabled={buttonDisabled}
                        className={classes.globalbuttonStyle}
                        onClick={handlePDFDownload}
                        endIcon={
                          pdfButtonLoading ? (
                            <A_CircularIndeternment
                              color="secondary"
                              size={20}
                            />
                          ) : (
                            <GetAppIcon />
                          )
                        }
                      ></A_Button>
                    </Grid>
                  )
              : ""}

            {toolbar &&
              toolbar.primary &&
              toolbar.primary.downloadExcelButton &&
              toolbar.primary.downloadExcelButton.show &&
              toolbar.primary.downloadExcelButton.templateId === templateId && (
                <>
                  <Grid item>
                    <A_Button
                      color="primary"
                      className={classes.globalbuttonStyle}
                      label={toolbar.primary.downloadExcelButton.label}
                      onClick={() => {
                        props.downloadExcelTemplatePopUp(
                          "ExceltemplateDownload"
                        );
                      }}
                    ></A_Button>
                  </Grid>
                </>
              )}
            {toolbar &&
              toolbar.primary &&
              toolbar.primary.JLdownloadExcelButton &&
              toolbar.primary.JLdownloadExcelButton.show &&
              toolbar.primary.JLdownloadExcelButton.templateId ===
                templateId && (
                <>
                  <Grid item>
                    <A_Button
                      color="primary"
                      className={classes.globalbuttonStyle}
                      label={toolbar.primary.JLdownloadExcelButton.label}
                      onClick={handleJLExcelDownlod}
                      disabled={open}
                      endIcon={
                        open ? (
                          <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                          <GetAppIcon />
                        )
                      }
                    ></A_Button>
                  </Grid>
                </>
              )}
            {props.currentPage &&
            props.currentPage.pageConfig &&
            props.currentPage.pageConfig.pageSecurity &&
            (props.currentPage.pageConfig.pageSecurity.Full === true ||
              props.currentPage.pageConfig.pageSecurity.Update === true) ? (
              toolbar &&
              toolbar.primary &&
              toolbar.primary.downloadPptButton &&
              toolbar.primary.downloadPptButton.show &&
              pptButtonVisibility() ? (
                <>
                  <Grid item>
                    <A_Button
                      edge="start"
                      color="primary"
                      className={classes.globalbuttonStyle}
                      aria-label="add records"
                      label={toolbar.primary.downloadPptButton.label}
                      onClick={handlePptList}
                      endIcon={
                        pptLoading ? (
                          <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                          <GetAppIcon />
                        )
                      }
                    ></A_Button>
                  </Grid>

                  <Popper
                    className={classes.paper}
                    id={id}
                    open={anchorEl}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                  >
                    <Slide
                      direction="down"
                      in={openPopup}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <ClickAwayListener
                          onClose={(e) => handlePptList(e)}
                          onClickAway={(e) => handlePptList(e)}
                        >
                          <div>
                            <A_Paper
                              className={classes.PaperHeight}
                              width={30}
                              height={"auto"}
                              content={
                                toolbar &&
                                toolbar.primary &&
                                toolbar.primary.downloadPptButton &&
                                toolbar.primary.downloadPptButton
                                  .presentation &&
                                toolbar.primary.downloadPptButton.presentation.map(
                                  (item) => (
                                    <List
                                      key={item}
                                      component="nav"
                                      aria-label="user area"
                                      className={classes.listspacing}
                                    >
                                      <ListItem
                                        button
                                        onClick={() =>
                                          handlePptDownload(
                                            toolbar.primary.downloadPptButton,
                                            item
                                          )
                                        }
                                        disabled={visibility}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            item.exportAsPDF
                                              ? faFilePdf
                                              : faFilePowerpoint
                                          }
                                          style={{ marginRight: "10px" }}
                                        />
                                        <ListItemText
                                          className="List-Font"
                                          primary={item.label}
                                        />
                                      </ListItem>

                                      <A_Divider />
                                    </List>
                                  )
                                )
                              }
                              size="large"
                            />
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Slide>
                  </Popper>
                </>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {props.toolbar &&
              props.toolbar.primary &&
              props.toolbar.primary.valuationPPT &&
              props.toolbar.primary.valuationPPT.show &&
              props.toolbar.primary.valuationPPT.templateId === templateId && (
                <Grid item>
                  <JlvaluationReport
                    lable={props.toolbar.primary.valuationPPT.label}
                  />{" "}
                </Grid>
              )}
            {props.toolbar &&
            props.toolbar.primary &&
            props.toolbar.primary.folderLinkButton &&
            props.toolbar.primary.folderLinkButton.show ? (
              <Grid item>
                <A_Button
                  label="Open Deal Folder"
                  disabled={dealFolderLink ? false : true}
                  color="primary"
                  className={classes.globalbuttonStyle}
                  startIcon={<FolderIcon />}
                  onClick={() => dealFolder(dealFolderLink)}
                />
              </Grid>
            ) : (
              ""
            )}

            {props.readOnlyTemplate !== true && renderBookIRCSlot() ? (
              <>
                <Grid item>
                  <A_Button
                    color="primary"
                    className={classes.globalbuttonStyle}
                    label={toolbar?.bookIRC?.buttonLabel || "+Book IRC"}
                    onClick={handleBookIRC}
                  ></A_Button>
                </Grid>
              </>
            ) : (
              ""
            )}
            {renderForms()}
            <Grid item>
              {toolbar && toolbar.primary && toolbar.primary.print && (
                <A_IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Print"
                  icon={<PrintIcon />}
                  className="page-header-print-button"
                ></A_IconButton>
              )}
            </Grid>
            <Grid item className={classes.secondaryMenuButton}>
              {crmId != pageConfig.CRMID &&
                (getObjectFromJSONString(
                  toolbar,
                  ".moreOption.showMergeRecord",
                  false
                ) ||
                  getObjectFromJSONString(
                    toolbar,
                    ".moreOption.showDelete",
                    false
                  ) ||
                  getObjectFromJSONString(
                    toolbar,
                    ".moreOption.showRecordAudit",
                    true
                  ) !== false) && (
                  <A_IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleOptions}
                    aria-label="secondaryMenuPage"
                    icon={<MoreVertIcon className={classes.secondaryIcon} />}
                  ></A_IconButton>
                )}
              <Popper
                className={classes.paper}
                id={OptionId}
                open={openOptions}
                anchorEl={anchorElement}
                placement="bottom-end"
              >
                <Slide
                  direction="down"
                  in={openOptions}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <ClickAwayListener onClickAway={(e) => handleOptions(e)}>
                      <div>
                        <A_Paper
                          className={classes.PaperHeight}
                          width={30}
                          height={"auto"}
                          content={
                            <List
                              component="nav"
                              aria-label="user area"
                              className={classes.listspacing}
                            >
                              {getObjectFromJSONString(
                                toolbar,
                                ".moreOption.showMergeRecord",
                                false
                              ) &&
                                (getObjectFromJSONString(
                                  props,
                                  ".currentPage.pageConfig.pageSecurity.Update",
                                  false
                                ) == true ||
                                  getObjectFromJSONString(
                                    props,
                                    ".currentPage.pageConfig.pageSecurity.Full",
                                    false
                                  )) == true && (
                                  <>
                                    <ListItem
                                      button
                                      onClick={() => handleClickOpen()}
                                    >
                                      <ListItemIcon>
                                        <MergeTypeIcon />
                                      </ListItemIcon>

                                      <ListItemText
                                        className="List-Font"
                                        primary="Merge Records"
                                      />
                                    </ListItem>

                                    <A_Divider />
                                  </>
                                )}
                              {getObjectFromJSONString(
                                toolbar,
                                ".moreOption.showRecordAudit",
                                true
                              ) !== false &&
                              (getObjectFromJSONString(
                                props,
                                ".currentPage.pageConfig.pageSecurity.Update",
                                false
                              ) == true ||
                                getObjectFromJSONString(
                                  props,
                                  ".currentPage.pageConfig.pageSecurity.Full",
                                  false
                                ) == true) ? (
                                <ListItem
                                  button
                                  onClick={() => handleClickChangeLog()}
                                >
                                  <ListItemIcon>
                                    <HistoryIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    className="List-Font"
                                    primary="Record Audit Trail"
                                  />
                                </ListItem>
                              ) : (
                                ""
                              )}
                              {getObjectFromJSONString(
                                toolbar,
                                ".moreOption.showDelete",
                                false
                              ) &&
                              (getObjectFromJSONString(
                                props,
                                ".currentPage.pageConfig.type",
                                false
                              ) === pageConfig.CRM ||
                                getObjectFromJSONString(
                                  props,
                                  ".currentPage.pageConfig.pageSecurity.Full",
                                  false
                                ) == true) ? (
                                <>
                                  {show ? (
                                    <>
                                      <A_Divider />
                                      <ListItem
                                        button
                                        onClick={() => handleClickDelete()}
                                      >
                                        <ListItemIcon>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          className="List-Font"
                                          primary="Delete"
                                        />
                                      </ListItem>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </List>
                          }
                          size="large"
                        />

                        {open && (
                          <A_SimpleDialog
                            //entity={entity}
                            fullScreen={false}
                            height="400px"
                            fullWidth={true}
                            maxWidth={"md"}
                            open={open}
                            //handlePopup
                            onClose={handlePopup}
                            closePopUp={handlePopup}
                            dialogContent={
                              <RecordMerge
                                searchOn={
                                  toolbar &&
                                  toolbar.secondary &&
                                  toolbar.secondary.searchOn
                                }
                                handleClosePopup={handlePopup}
                                open
                              />
                            }
                            //onClose={handleClose}
                          />
                        )}
                        {changeLog && (
                          <A_SimpleDialog
                            //entity={entity}
                            title={"Record Audit Trail"}
                            fullScreen={false}
                            height="400px"
                            fullWidth={true}
                            maxWidth={"lg"}
                            open={changeLog}
                            onClose={handleChangelogPopup}
                            closePopUp={handleChangelogPopup}
                            dialogContent={
                              <ChangeLog
                                currentPage={props.currentPage}
                                templateId={templateId}
                              />
                            }
                            //onClose={handleClose}
                          />
                        )}
                        {deleteCRM && (
                          <DeleteCRMRecord
                            handleDeleteEvent={handleDeleteEvent}
                            confirmDelete={deleteCRM}
                          />
                        )}
                      </div>
                    </ClickAwayListener>
                  </div>
                </Slide>
              </Popper>
            </Grid>
          </Grid>
        </div>

        <A_SimpleDialog
          open={isdownloadExcelPopup}
          title="Download Excel Template"
          fullScreen={false}
          height="400px"
          fullwidth={true}
          maxWidth={"xs"}
          onClose={handleClosePopup}
          closePopUp={handleClosePopup}
          dialogContent={renderDownloadExcelPopup(function () {
            handleClosePopup();
          })}
        />
        <A_SimpleDialog
          open={bookIRC}
          formTemplateId
          title={toolbar?.bookIRC?.headerLabel || "Add a new IRC Meeting"}
          fullScreen={false}
          height="400px"
          maxWidth={"sm"}
          onClose={handleIRCForm}
          closePopUp={() => {
            handleIRCForm;
          }}
          dialogContent={
            <O_IRCSlots
              onClose={handleIRCForm}
              purposeLabel={toolbar?.bookIRC?.purposeLabel}
              otherPurposeLabel={toolbar?.bookIRC?.otherPurposeLabel}
              onReload={onReload}
              purposeLabel={toolbar?.bookIRC?.purposeLabel}
              otherPurposeLabel={toolbar?.bookIRC?.otherPurposeLabel}
              noOfDays={toolbar && toolbar.bookIRC && toolbar.bookIRC.noOfDays}
              closedAfter={
                toolbar && toolbar.bookIRC && toolbar.bookIRC.closedAfter
              }
              warningAfter={
                toolbar && toolbar.bookIRC && toolbar.bookIRC.warningAfter
              }
              idLabel={toolbar && toolbar.bookIRC && toolbar.bookIRC.id}
              ircConfig={toolbar && toolbar.bookIRC}
            />
          }
        />
        {isFormOpen && (
          <M_ManageEntityForm
            showPopup={true}
            refresh={false}
            templateId={formTemplateId}
            entity={entity}
            crmData={[]}
            open={isFormOpen}
            onClose={() => {
              handleForm(false);
            }}
          />
        )}
      </div>
    </>
  ) : (
    <>
      <div className="grid-item ">
        {/*Toolbar starts here */}
        <Grid
          container
          spacing={spacing}
          className={`page-toolbar ${classes.paddingButton}`}
        >
          {/* <Grid item className={classes.dropdowns} key={templateId}>
          {toolbar &&
            pageFilters &&
            pageFilters.map((pageFilter, index) => {
              return (
                <M_ToolbarFilter
                  key={pageFilter.ptionSetName}
                  indexValue={index}
                  toolbar={toolbar}
                  {...pageFilter}
                  optionSets={optionSets}
                  templateId={templateId}
                  investmentId={crmId}
                />
              );
            })}
        </Grid> */}

          <Grid item>
            {toolbar &&
              toolbar.primary &&
              toolbar.primary.downloadButton &&
              toolbar.primary.downloadButton.show && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <A_Button
                    style={{
                      textTransform: "none",
                      marginTop: "7px",
                      marginBottom: "7px",
                    }}
                    className={classes.globalbuttonStyle}
                    edge="start"
                    color="primary"
                    aria-label="add records"
                    label="Download"
                    endIcon={<GetAppIcon />}
                  />
                </Box>
              )}
          </Grid>

          {toolbar &&
          toolbar.primary &&
          toolbar.primary.buttons &&
          toolbar.primary.buttons.length > 0
            ? toolbar.primary.buttons.map((item, index) =>
                item && item.show && templateId == item.templateId ? (
                  <Grid key={index} item>
                    {" "}
                    <A_Button
                      edge="start"
                      color="primary"
                      aria-label="add records"
                      className={classes.globalbuttonStyle}
                      label={item.label}
                      onClick={() => {
                        setItemData(item);
                        setIsdownloadExcelPopup(true);
                      }}
                      endIcon={
                        buttonLoading ? (
                          <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                          <GetAppIcon />
                        )
                      }
                    ></A_Button>
                  </Grid>
                ) : (
                  ""
                )
              )
            : ""}

          {toolbar && toolbar.primary && toolbar.primary.downloadPdfButton
            ? Array.isArray(toolbar.primary.downloadPdfButton)
              ? toolbar.primary.downloadPdfButton
                  .filter((item) => item.templateIds.includes(templateId))
                  .map((item) => (
                    <Grid item key={item.label}>
                      <A_Button
                        color="primary"
                        label={item.label}
                        disabled={
                          buttonDisabled && templateId == pdfButtonLabel
                            ? true
                            : false
                        }
                        className={classes.globalbuttonStyle}
                        onClick={() => handlePDFDownload(item, templateId)}
                        endIcon={
                          pdfButtonLoading && templateId == pdfButtonLabel ? (
                            <A_CircularIndeternment
                              color="secondary"
                              size={20}
                            />
                          ) : (
                            <GetAppIcon />
                          )
                        }
                      ></A_Button>
                    </Grid>
                  ))
              : (
                  (toolbar &&
                    toolbar.primary &&
                    toolbar.primary.downloadPdfButton &&
                    toolbar.primary.downloadPdfButton.templateIds) ||
                  []
                ).includes(templateId) &&
                toolbar.primary.downloadPdfButton.show && (
                  <Grid item>
                    <A_Button
                      color="primary"
                      label={toolbar.primary.downloadPdfButton.label}
                      disabled={buttonDisabled}
                      className={classes.globalbuttonStyle}
                      onClick={handlePDFDownload}
                      endIcon={
                        pdfButtonLoading ? (
                          <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                          <GetAppIcon />
                        )
                      }
                    ></A_Button>
                  </Grid>
                )
            : ""}

          {toolbar &&
            toolbar.primary &&
            toolbar.primary.downloadExcelButton &&
            toolbar.primary.downloadExcelButton.show &&
            toolbar.primary.downloadExcelButton.templateId === templateId && (
              <>
                <Grid item>
                  <A_Button
                    color="primary"
                    className={classes.globalbuttonStyle}
                    label={toolbar.primary.downloadExcelButton.label}
                    onClick={() => {
                      props.downloadExcelTemplatePopUp("ExceltemplateDownload");
                    }}
                  ></A_Button>
                </Grid>
              </>
            )}
          {toolbar &&
            toolbar.primary &&
            toolbar.primary.JLdownloadExcelButton &&
            toolbar.primary.JLdownloadExcelButton.show &&
            toolbar.primary.JLdownloadExcelButton.templateId === templateId && (
              <>
                <Grid item>
                  <A_Button
                    color="primary"
                    className={classes.globalbuttonStyle}
                    label={toolbar.primary.JLdownloadExcelButton.label}
                    onClick={handleJLExcelDownlod}
                    disabled={open}
                    endIcon={
                      open ? (
                        <A_CircularIndeternment color="secondary" size={20} />
                      ) : (
                        <GetAppIcon />
                      )
                    }
                  ></A_Button>
                </Grid>
              </>
            )}
          {props.currentPage &&
          props.currentPage.pageConfig &&
          props.currentPage.pageConfig.pageSecurity &&
          (props.currentPage.pageConfig.pageSecurity.Full === true ||
            props.currentPage.pageConfig.pageSecurity.Update === true) ? (
            toolbar &&
            toolbar.primary &&
            toolbar.primary.downloadPptButton &&
            toolbar.primary.downloadPptButton.show &&
            pptButtonVisibility() ? (
              <>
                <Grid item>
                  <A_Button
                    edge="start"
                    color="primary"
                    className={classes.globalbuttonStyle}
                    aria-label="add records"
                    label={toolbar.primary.downloadPptButton.label}
                    onClick={handlePptList}
                    endIcon={
                      pptLoading ? (
                        <A_CircularIndeternment color="secondary" size={20} />
                      ) : (
                        <GetAppIcon />
                      )
                    }
                  ></A_Button>
                </Grid>

                <Popper
                  className={classes.paper}
                  id={id}
                  open={anchorEl}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                >
                  <Slide
                    direction="down"
                    in={openPopup}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <ClickAwayListener
                        onClose={(e) => handlePptList(e)}
                        onClickAway={(e) => handlePptList(e)}
                      >
                        <div>
                          <A_Paper
                            className={classes.PaperHeight}
                            width={30}
                            height={"auto"}
                            content={
                              toolbar &&
                              toolbar.primary &&
                              toolbar.primary.downloadPptButton &&
                              toolbar.primary.downloadPptButton.presentation &&
                              toolbar.primary.downloadPptButton.presentation.map(
                                (item) => (
                                  <List
                                    key={item}
                                    component="nav"
                                    aria-label="user area"
                                    className={classes.listspacing}
                                  >
                                    <ListItem
                                      button
                                      onClick={() =>
                                        handlePptDownload(
                                          toolbar.primary.downloadPptButton,
                                          item
                                        )
                                      }
                                      disabled={visibility}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          item.exportAsPDF
                                            ? faFilePdf
                                            : faFilePowerpoint
                                        }
                                        style={{ marginRight: "10px" }}
                                      />
                                      <ListItemText
                                        className="List-Font"
                                        primary={item.label}
                                      />
                                    </ListItem>

                                    <A_Divider />
                                  </List>
                                )
                              )
                            }
                            size="large"
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Slide>
                </Popper>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {props.toolbar &&
            props.toolbar.primary &&
            props.toolbar.primary.valuationPPT &&
            props.toolbar.primary.valuationPPT.show &&
            props.toolbar.primary.valuationPPT.templateId === templateId && (
              <Grid item>
                <JlvaluationReport
                  lable={props.toolbar.primary.valuationPPT.label}
                />{" "}
              </Grid>
            )}
          {props.toolbar &&
          props.toolbar.primary &&
          props.toolbar.primary.folderLinkButton &&
          props.toolbar.primary.folderLinkButton.show ? (
            <Grid item>
              <A_Button
                label="Open Deal Folder"
                disabled={dealFolderLink ? false : true}
                color="primary"
                className={classes.globalbuttonStyle}
                startIcon={<FolderIcon />}
                onClick={() => dealFolder(dealFolderLink)}
              />
            </Grid>
          ) : (
            ""
          )}
          {getObjectFromJSONString(
            props,
            ".toolbar.primary.triggerCall.show",
            false
          ) &&
          checkTemplate(
            getObjectFromJSONString(
              props,
              ".toolbar.primary.triggerCall.templateIds",
              []
            )
          ) ? (
            <Grid item>
              <A_Button
                label={getObjectFromJSONString(
                  props,
                  ".toolbar.primary.triggerCall.label",
                  ""
                )}
                color="primary"
                className={classes.globalbuttonStyle}
                disabled={triggerLoading}
                endIcon={
                  triggerLoading ? (
                    <A_CircularIndeternment color="secondary" size={20} />
                  ) : (
                    <></>
                  )
                }
                onClick={() =>
                  handleTriggerCall(
                    getObjectFromJSONString(
                      props,
                      ".toolbar.primary.triggerCall",
                      {}
                    )
                  )
                }
              />
            </Grid>
          ) : (
            ""
          )}

          {props.readOnlyTemplate !== true && renderBookIRCSlot() ? (
            <>
              <Grid item>
                <A_Button
                  color="primary"
                  className={classes.globalbuttonStyle}
                  label={toolbar?.bookIRC?.buttonLabel || "+Book IRC"}
                  onClick={handleBookIRC}
                ></A_Button>
              </Grid>
            </>
          ) : (
            ""
          )}
          {renderForms()}
          <Grid item>
            {toolbar && toolbar.primary && toolbar.primary.print && (
              <A_IconButton
                edge="start"
                color="inherit"
                aria-label="Print"
                icon={<PrintIcon />}
                className="page-header-print-button"
              ></A_IconButton>
            )}
          </Grid>
          <Grid item className={classes.secondaryMenuButton}>
            {crmId != pageConfig.CRMID &&
              ((getObjectFromJSONString(
                toolbar,
                ".moreOption.showMergeRecord",
                false
              ) &&
                (getObjectFromJSONString(
                  props,
                  ".currentPage.pageConfig.pageSecurity.Update",
                  false
                ) == true ||
                  getObjectFromJSONString(
                    props,
                    ".currentPage.pageConfig.pageSecurity.Full",
                    false
                  ) == true)) ||
                (getObjectFromJSONString(
                  toolbar,
                  ".moreOption.showDelete",
                  false
                ) &&
                  getObjectFromJSONString(
                    props,
                    ".currentPage.pageConfig.type",
                    false
                  ) === pageConfig.CRM &&
                  getObjectFromJSONString(
                    props,
                    ".currentPage.pageConfig.pageSecurity.Full",
                    false
                  ) == true) ||
                (getObjectFromJSONString(
                  toolbar,
                  ".moreOption.showRecordAudit",
                  true
                ) !== false &&
                  (getObjectFromJSONString(
                    props,
                    ".currentPage.pageConfig.pageSecurity.Update",
                    false
                  ) == true ||
                    getObjectFromJSONString(
                      props,
                      ".currentPage.pageConfig.pageSecurity.Full",
                      false
                    ) == true))) && (
                <A_IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleOptions}
                  aria-label="secondaryMenuPage"
                  icon={<MoreVertIcon className={classes.secondaryIcon} />}
                ></A_IconButton>
              )}
            <Popper
              className={classes.paper}
              id={OptionId}
              open={openOptions}
              anchorEl={anchorElement}
              placement="bottom-end"
            >
              <Slide
                direction="down"
                in={openOptions}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <ClickAwayListener onClickAway={(e) => handleOptions(e)}>
                    <div>
                      <A_Paper
                        className={classes.PaperHeight}
                        width={30}
                        height={"auto"}
                        content={
                          <List
                            component="nav"
                            aria-label="user area"
                            className={classes.listspacing}
                          >
                            {getObjectFromJSONString(
                              toolbar,
                              ".moreOption.showMergeRecord",
                              false
                            ) &&
                              (getObjectFromJSONString(
                                props,
                                ".currentPage.pageConfig.pageSecurity.Update",
                                false
                              ) == true ||
                                getObjectFromJSONString(
                                  props,
                                  ".currentPage.pageConfig.pageSecurity.Full",
                                  false
                                ) == true) && (
                                <>
                                  <ListItem
                                    button
                                    onClick={() => handleClickOpen()}
                                  >
                                    <ListItemIcon>
                                      <MergeTypeIcon />
                                    </ListItemIcon>

                                    <ListItemText
                                      className="List-Font"
                                      primary="Merge Records"
                                    />
                                  </ListItem>

                                  <A_Divider />
                                </>
                              )}
                            {getObjectFromJSONString(
                              toolbar,
                              ".moreOption.showRecordAudit",
                              true
                            ) !== false &&
                            (getObjectFromJSONString(
                              props,
                              ".currentPage.pageConfig.pageSecurity.Update",
                              false
                            ) == true ||
                              getObjectFromJSONString(
                                props,
                                ".currentPage.pageConfig.pageSecurity.Full",
                                false
                              ) == true) ? (
                              <ListItem
                                button
                                onClick={() => handleClickChangeLog()}
                              >
                                <ListItemIcon>
                                  <HistoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                  className="List-Font"
                                  primary="Record Audit Trail"
                                />
                              </ListItem>
                            ) : (
                              ""
                            )}
                            {getObjectFromJSONString(
                              toolbar,
                              ".moreOption.showDelete",
                              false
                            ) &&
                            getObjectFromJSONString(
                              props,
                              ".currentPage.pageConfig.type",
                              false
                            ) === pageConfig.CRM &&
                            getObjectFromJSONString(
                              props,
                              ".currentPage.pageConfig.pageSecurity.Full",
                              false
                            ) == true ? (
                              <>
                                {show ? (
                                  <>
                                    <A_Divider />
                                    <ListItem
                                      button
                                      onClick={() => handleClickDelete()}
                                    >
                                      <ListItemIcon>
                                        <DeleteIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                        className="List-Font"
                                        primary="Delete"
                                      />
                                    </ListItem>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </List>
                        }
                        size="large"
                      />

                      {open && (
                        <A_SimpleDialog
                          //entity={entity}
                          fullScreen={false}
                          height="400px"
                          fullWidth={true}
                          maxWidth={"md"}
                          open={open}
                          //handlePopup
                          onClose={handlePopup}
                          closePopUp={handlePopup}
                          dialogContent={
                            <RecordMerge
                              searchOn={
                                toolbar &&
                                toolbar.secondary &&
                                toolbar.secondary.searchOn
                              }
                              handleClosePopup={handlePopup}
                              open
                            />
                          }
                          //onClose={handleClose}
                        />
                      )}
                      {changeLog && (
                        <A_SimpleDialog
                          //entity={entity}
                          fullScreen={false}
                          title={"Record Audit Trail"}
                          height="400px"
                          fullWidth={true}
                          maxWidth={"lg"}
                          open={changeLog}
                          onClose={handleChangelogPopup}
                          closePopUp={handleChangelogPopup}
                          dialogContent={
                            <ChangeLog
                              currentPage={props.currentPage}
                              templateId={templateId}
                            />
                          }
                          //onClose={handleClose}
                        />
                      )}
                      {deleteCRM && (
                        <DeleteCRMRecord
                          handleDeleteEvent={handleDeleteEvent}
                          confirmDelete={deleteCRM}
                        />
                      )}
                    </div>
                  </ClickAwayListener>
                </div>
              </Slide>
            </Popper>
          </Grid>
        </Grid>
        <A_SimpleDialog
          open={isdownloadExcelPopup}
          title="Download Excel Template"
          fullScreen={false}
          height="400px"
          fullwidth={true}
          maxWidth={"xs"}
          onClose={handleClosePopup}
          closePopUp={handleClosePopup}
          dialogContent={renderDownloadExcelPopup(function () {
            handleClosePopup();
          })}
        />
        <A_SimpleDialog
          open={bookIRC}
          formTemplateId
          title={toolbar?.bookIRC?.headerLabel || "Add a new IRC Meeting"}
          fullScreen={false}
          height="400px"
          maxWidth={"sm"}
          onClose={handleIRCForm}
          closePopUp={() => {
            handleIRCForm;
          }}
          dialogContent={
            <O_IRCSlots
              onClose={handleIRCForm}
              onReload={onReload}
              purposeLabel={toolbar?.bookIRC?.purposeLabel}
              otherPurposeLabel={toolbar?.bookIRC?.otherPurposeLabel}
              noOfDays={toolbar && toolbar.bookIRC && toolbar.bookIRC.noOfDays}
              closedAfter={
                toolbar && toolbar.bookIRC && toolbar.bookIRC.closedAfter
              }
              warningAfter={
                toolbar && toolbar.bookIRC && toolbar.bookIRC.warningAfter
              }
              idLabel={toolbar && toolbar.bookIRC && toolbar.bookIRC.id}
              ircConfig={toolbar && toolbar.bookIRC}
            />
          }
        />
        {isFormOpen && (
          <M_ManageEntityForm
            showPopup={true}
            refresh={false}
            templateId={formTemplateId}
            entity={entity}
            crmData={[]}
            open={isFormOpen}
            onClose={() => {
              handleForm(false);
            }}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    user: state.oidc.user,
    toolbarSelections: state.manageToolbarSelections,
    filters: state.manageToolbarSelections.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, currentPageActions, toastActions),
    dispatch
  );
}

M_PageToolbar.propTypes = {
  toolbar: PropTypes.object,
  optionSets: PropTypes.array,
  currentPage: PropTypes.object,
  user: PropTypes.object,
  primary: PropTypes.object,
  downloadExcelTemplatePopUp: PropTypes.func,
  manageExcelTemplates: PropTypes.any,
  setToast: PropTypes.any,
  params: PropTypes.any,
  onReload: PropTypes.any,
  getCurrentPage: PropTypes.any,
  toolbarSelections: PropTypes.any,
  JLExcelDownload: PropTypes.func,
  readOnlyTemplate: PropTypes.any,
  filters: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_PageToolbar);
