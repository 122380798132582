import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Box, Grid } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import calendarIcon from "../../../../../../src/Assets/Images/Defaults/meetingDef.jpg";
import { useCallback } from "react";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import { useParams } from "react-router";
import { Skeleton } from "@material-ui/lab";
import { CRMEdgeVertexEngine } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { labelName, toolbarIRCSlots } from "../../../../../Utils/config/config";
import { O_MeetingSlotCardCss } from "../MeetingSlotCard/O_MeetingSlotCardCss";
import {
  groupBy,
  diff_hours,
  sortByKey,
  dateCheck,
  groupByCustom,
} from "../MeetingSlotCard/O_MeetingSlotCard.helper";
import {
  FIELDS,
  ircSlotsEnums,
  IS_NOT_PE,
  IS_PE,
  RESULT,
  RESULT2,
} from "./ircSlots.Helper";
import { ircSlotEnums } from "../MeetingSlotCard/mettingSlotCard.Helper";
const useStyles = makeStyles(O_MeetingSlotCardCss);
const O_IRCSlots = (props) => {
  const classes = useStyles();
  const [slots, setSlots] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [ircPurpose, setIrcPurpose] = useState("");
  const [otherIRCPurpose, setOtherIRCPurpose] = useState("");
  const [iRCPurposeError, setIRCPurposeError] = useState(false);
  const [otherIRCPurposeError, setOtherIRCPurposeError] = useState(false);
  const [response, setResponse] = useState();
  const [response2, setResponse2] = useState();
  const [responseRecord, setResponseRecord] = useState();
  const { onReload } = props;
  let finalDataList = [];

  const { crmId } = useParams();
  const handleDropdowns = (selectedValue) => {
    if (iRCPurposeError) {
      setIRCPurposeError(!iRCPurposeError);
    }
    if (selectedValue.value === "Other") {
      setShowTextField(true);
    } else {
      setShowTextField(false);
    }
    setIrcPurpose(selectedValue.value);
  };
  const handleTextboxes = (event) => {
    if (otherIRCPurposeError) {
      setOtherIRCPurposeError(!otherIRCPurposeError);
    }
    setOtherIRCPurpose(event.target.value);
  };

  const checkProperties = (ircPurposeValue, otherIRCPurposeValue) => {
    if (ircPurposeValue === "") {
      setIRCPurposeError(true);
      return false;
    } else {
      setIRCPurposeError(false);
    }
    if (showTextField && otherIRCPurposeValue === "") {
      setOtherIRCPurposeError(true);
      return false;
    } else {
      setOtherIRCPurposeError(false);
    }
    return true;
  };

  const RescheduleSlot = async (ircTime, ircDate, id) => {
    let payload = {
      id: id,
      label:
        props.ircConfig && props.ircConfig.label
          ? props.ircConfig.label
          : toolbarIRCSlots.LABEL,
      properties: {
        ircTime: ircTime,
        ircDate: ircDate,
      },
    };
    await httpPut(`/CRMData/update?pageUrl=contact`, payload);
    props.getReload();
  };

  const bookSlot = async (
    ircTime,
    ircDate,
    ircPurposeValue,
    otherIRCPurposeValue
  ) => {
    if (checkProperties(ircPurposeValue, otherIRCPurposeValue)) {
      let properties = {
        type: toolbarIRCSlots.TYPE,
        ircPurpose:
          ircPurposeValue.toLowerCase() === ircSlotsEnums.OTHER
            ? otherIRCPurposeValue
            : ircPurposeValue,
        "Purpose (Other)" :
          ircPurposeValue.toLowerCase() === ircSlotsEnums.OTHER
            ? otherIRCPurposeValue
            : ircPurposeValue,
        otherIrcPurpose: otherIRCPurposeValue,
        ircTime: ircTime,
        ircDate: ircDate,
      };
      properties[labelName.purposeOther] = ircPurposeValue;
      if ("undefined" in properties) {
         delete properties["undefined"];
      }
      let payload = {
        edges: [
          {
            guid: [crmId],
            label:
              props.ircConfig && props.ircConfig.edgeLabel
                ? props.ircConfig.edgeLabel
                : toolbarIRCSlots.EDGE_LABEL,
          },
        ],
        id: null,
        label:
          props.ircConfig && props.ircConfig.label
            ? props.ircConfig.label
            : toolbarIRCSlots.LABEL,
        properties: properties,
        type: toolbarIRCSlots.PAYLOAD_TYPE,
        security: {
          read: {
            groupids: [""],
            users: [""],
          },
          update: {
            groupids: [""],
            users: [""],
          },
          full: {
            groupids: [""],
            users: [],
          },
          privateRecord: false,
        },
      };
      let restParams = {
        entity: {
          parameters: {
            actionType: ircSlotsEnums.ADD,
            edgelabel:
              props.ircConfig && props.ircConfig.edgeLabel
                ? props.ircConfig.edgeLabel
                : toolbarIRCSlots.EDGE_LABEL,
            sourceVertexId: crmId,
          },
        },
      };
      let res = await CRMEdgeVertexEngine(payload, restParams);
      let payloadChecklist = {
        dealId: res.edges[0].guid[0],
        dealWorkFlowState: toolbarIRCSlots.DEALWORKFLOWSTATE,
        dateActive: res.properties.ircDate,
        ircPurpose: res.properties.ircPurpose,
        dealStatus: toolbarIRCSlots.DEALSTATUS,
        action: toolbarIRCSlots.ACTION,
        dealIrcId: res.id,
      };
      await httpPost(`/DealChecklist/Insert`, payloadChecklist);
      props.onClose();
      onReload();
    }
  };

  const renderSlot = (
    responseValue,
    response2Value,
    responseRecordValue,
    ircPurposeValue,
    otherIRCPurposeValue
  ) => {
    for (let i in response2Value) {
      if (Array.isArray(responseValue)) {
        response.push(response2Value[i]);
      }
    }
    if (responseRecordValue && Array.isArray(responseRecordValue)) {
      for (let i in responseValue) {
        responseRecordValue.map((record) => {
          if (responseValue[i].id === record.id) {
            responseValue[i] = { ...responseValue[i], ...record };
          }
        });
      }
    }
    let outJSON = responseValue;
    let formatedDataList = [];
    for (let i in outJSON) {
      let convertedDate = FromEpochFormat(outJSON[i].properties.ircDate);
      if (outJSON[i].properties.ircDate) {
        outJSON[i].ircDate = convertedDate;
        formatedDataList.push(outJSON[i]);
      }
    }

    const sortedData = sortByKey(formatedDataList, ircSlotsEnums.IRC_DATE);
    let groupedByDate = groupByCustom(sortedData, ircSlotsEnums.IRC_DATE);

    let closedAfter = props.closedAfter ? props.closedAfter : 0;
    let warningAfter = props.warningAfter ? props.warningAfter : 0;
    for (let slotDate in groupedByDate) {
      let bookingStatus = false;
      groupedByDate[slotDate].records.map((slotData) => {
        if (
          slotData[
            props.idLabel ? props.idLabel : ircSlotsEnums.OPPORTUNITY_ID
          ] === crmId
        ) {
          bookingStatus = true;
        }
      });
      groupedByDate[slotDate]["bookingStatus"] = bookingStatus;
    }
    for (let slotDate in groupedByDate) {
      let disableBook = diff_hours(slotDate) <= closedAfter;
      let selectedData = groupedByDate[slotDate];
      let displayMessage =
        closedAfter <= warningAfter &&
        diff_hours(slotDate) > closedAfter &&
        diff_hours(slotDate) <= warningAfter;

      let detail = (
        <Box className={classes.detailFirstBox} style={{ width: "98%" }}>
          <Grid container spacing={8} alignItems="start">
            <Grid item xs={2}>
              <img src={calendarIcon} alt="" />
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.detailSecondBox}>
                <Typography
                  className={classes.typographyStyle}
                  style={{ fontSize: "1.2rem" }}
                >
                  {selectedData?.subject}
                </Typography>
                <Typography className={classes.typographyStyle}>
                  {getFormattedDate(slotDate, ircSlotsEnums.DATE_FORMATE)}
                  {selectedData?.ircEndDate && "-"}{" "}
                  {getFormattedDate(
                    FromEpochFormat(selectedData?.ircEndDate),
                    ircSlotEnums.TIME_FORMAT
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} justifyContent="end" className="text-center">
              <A_Button
                onClick={() => {
                  let ircDate =
                    groupedByDate[slotDate].records[0].properties.ircDate;
                  let ircTime = slotDate.toString().split(" ")[1];
                  if (!props.modify) {
                    bookSlot(
                      ircTime,
                      ircDate,
                      ircPurposeValue,
                      otherIRCPurposeValue
                    );
                  } else {
                    RescheduleSlot(ircTime, ircDate, props.id);
                  }
                }}
                color={displayMessage ? "secondary" : "primary"}
                label="Book"
                disabled={
                  disableBook ? true : groupedByDate[slotDate].bookingStatus
                }
              />{" "}
              {!(disableBook || groupedByDate[slotDate].bookingStatus) &&
              displayMessage ? (
                <span className={classes.timePeriod}>
                  {props.content &&
                  (props.content.closingText ||
                    props.content.closingText === "")
                    ? props.content.closingText
                    : "Closing Soon!"}
                </span>
              ) : null}
            </Grid>
          </Grid>
          <Box style={{ paddingTop: "0.8rem" }}>
            <A_Divider />
          </Box>
        </Box>
      );
      let componentJSON = {
        Date: slotDate,
        Detail: detail,
      };

      if (dateCheck(slotDate, props.noOfDays)) {
        finalDataList.push(componentJSON);
      }
    }
    setSlots(finalDataList);
  };
  const getSlot = useCallback(async () => {
    setIsLoading(true);
    const payload = {
      request: {
        sourceId: crmId,
        sourceLabel:
          props.ircConfig && props.ircConfig.sourceLabel
            ? props.ircConfig.sourceLabel
            : toolbarIRCSlots.LABEL,
        label: ircSlotsEnums.IRC_PORTAL,
        fields: FIELDS,
      },
      filter: {
        field: ircSlotsEnums.IRC_DATE,
        operator: ircSlotsEnums.GTE,
      },
    };
    const result = await httpCall.httpPost(`/IRC/getIRCSlot`, RESULT);
    setResponse(result);
    const result2 = await httpCall.httpPost(`/IRC/getIRCSlot`, RESULT2);
    setResponse2(result2);
    const resultRecord = await httpCall.httpPost(`/IRC/getIRCRecord`, payload);
    setResponseRecord(resultRecord);
    setIsLoading(false);
  });
  useEffect(() => {
    getSlot();
  }, []);
  useEffect(() => {
    renderSlot(
      response,
      response2,
      responseRecord,
      ircPurpose,
      otherIRCPurpose
    );
  }, [ircPurpose, otherIRCPurpose, response, response2, responseRecord]);

  return (
    <>
      <Box className={classes.skeleton}>
        {!props.modify ? (
          <A_Select
            className={classes.inputcontrol}
            fullWidth={true}
            variant="standard"
            native={true}
            options={
              props.ircConfig && props.ircConfig.isPE === true
                ? IS_PE
                : IS_NOT_PE
            }
            onChange={handleDropdowns}
            label={props.purposeLabel || ircSlotsEnums.IRC_PURPOSE}
            required={true}
            error={iRCPurposeError}
          />
        ) : (
          ""
        )}
        {showTextField ? (
          <A_TextField
            className={classes.inputcontrolTextField}
            onChange={handleTextboxes}
            variant="standard"
            label={props.otherPurposeLabel || ircSlotsEnums.OTHER_IRC_PURPOSE}
            required={true}
            error={otherIRCPurposeError}
          />
        ) : (
          ""
        )}
        {loading ? (
          <>
            <Skeleton variant="text" width={100} height={100} />
          </>
        ) : slots.length > 0 ? (
          slots.map((slot) => {
            return slot.Detail;
          })
        ) : (
          ircSlotsEnums.NO_SLOT_AVALIABLE
        )}
      </Box>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}), dispatch);
}
O_IRCSlots.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  maxHeight: propTypes.any,
  onClose: propTypes.func,
  closedAfter: propTypes.any,
  modify: propTypes.bool,
  onReload: propTypes.func,
  ircConfig: propTypes.any,
  getReload: propTypes.func,
  warningAfter: propTypes.any,
  idLabel: propTypes.any,
  id: propTypes.any,
  noOfDays: propTypes.any,
};
O_IRCSlots.defaultProps = {
  onReload: () => {},
};
export default connect(mapStateToProps, mapDispatchToProps)(O_IRCSlots);
