import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Divider,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import PropTypes from "prop-types";
import AddUsers from "./AddUsers";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import {
  getUserGroupList,
  getAllGroups,
  addUserToGroups,
  removeUserToGroups,
} from "./UserManagementHelper";
import AddIcon from "@material-ui/icons/Add";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { message } from "../../../../../Utils/config/messages";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DeleteIcon from "@material-ui/icons/Cancel";
import BlockIcon from "@material-ui/icons/Block";
import Tooltip from "@material-ui/core/Tooltip";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import GroupsComponent from "./GroupsComponent";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const useStyles = makeStyles((theme) => ({
  mainTop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: "5px",
    },
  },
  mainBottom: {
    display: "flex",
    padding: "5px",
    flexDirection: "row",
    width: "100%",
    overflow: "auto",
    fontFamily: "sans-serif",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "2px",
    },
  },
  mainBottomResponsive: {
    display: "flex",
    padding: "5px",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    fontFamily: "sans-serif",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "2px",
    },
  },
  card: {
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  },
  detailItemNew: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
  },
  backButton: {
    padding: theme.spacing(1),
    width: "100%",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#1976D1",
    marginLeft: "0.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      alignItems: "right",
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.3),
      alignItems: "right",
    },
  },
  chipsContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      display: "flex",
      flexWrap: "nowrap",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.3),
      paddingTop: theme.spacing(0.3),
    },
  },
  addButton: {
    textAlign: "right",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  arrow: {
    marginLeft: "8px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    },
  },
  IconPlacement: { margin: "-2px 9px 0px -7px" },
  chipsContainerMain: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  wordWrap: {
    wordWrap: "anywhere",
  },
  selectedListItem: {
    "&.MuiMenuItem-root": {
      overflow: "auto",
      textWrap: "wrap",
    },
  },
}));

const UserDetails = (props) => {
  const { data, UserNavigateActive } = props;
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [openPermissionsForm, setOpenPermissionsForm] = useState(false);
  const [userGroup, setUserGroup] = useState([]);
  const [allGroup, setAllGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupforDelete, setSelectedGroupforDelete] = useState([]);
  const [SaveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    getUsersGroup();
  }, []);

  const handleChange = (event) => {
    setSelectedGroups(event.target.value);
  };
  const handleDelete = (data) => {
    setSelectedGroupforDelete(data);
    setDeletePopup(true);
  };
  const confirmDelete = async () => {
    setSaveLoading(true);
    let res = await removeUserToGroups(data.id, selectedGroupforDelete.id);
    if (res.status >= 200 && res.status < 300) {
      props.setToast({
        type: toastSeverity.SUCCESS,
        severity: toastSeverity.SUCCESS,
        message: `${data.email || "User"} removed from ${
          selectedGroupforDelete?.name || "Group"
        }`,
        status: true,
      });
    } else {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setDeletePopup(false);
    setSelectedGroupforDelete([]);
    await getUsersGroup();
    setSaveLoading(false);
  };
  const handleSave = async () => {
    if (selectedGroups.length === 0) {
      props.setToast({
        type: toastSeverity.WARNING,
        severity: toastSeverity.WARNING,
        message: "Please select at least one group.",
        status: true,
      });
      return;
    }
    setSaveLoading(true);
    const payload = selectedGroups.map((group) => ({
      userId: data.id,
      groupId: group?.id?.toString(),
      id: 0,
    }));
    let res = await addUserToGroups(payload);
    if (res.status >= 200 && res.status < 300) {
      props.setToast({
        type: toastSeverity.SUCCESS,
        severity: toastSeverity.SUCCESS,
        message: `${data?.email || "User"} added to new Group`,
        status: true,
      });
    } else {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
    setSelectedGroups([]);
    setOpenPermissionsForm(false);
    await getUsersGroup();
    setSaveLoading(false);
  };

  async function getUsersGroup() {
    try {
      setLoading(true);
      const [userGroups, allGroups] = await Promise.all([
        getUserGroupList(data.id),
        getAllGroups(),
      ]);
      const userGroupIds = new Set(userGroups.map((group) => group.id));
      const currentUserGroups = userGroups.map((group) => ({
        id: group.id,
        name: group.name,
      }));
      const dropDownData = allGroups.filter(
        (group) => !userGroupIds.has(group.id)
      );
      setAllGroup(dropDownData);
      setUserGroup(currentUserGroups);
      setLoading(false);
    } catch (error) {
      setUserGroup([]);
      setAllGroup([]);
      setLoading(true);
      setLoading(false);
    }
  }
  const handleCloseform = () => {
    setFormOpen(!formOpen);
  };
  const handlePermissionForm = () => {
    setOpenPermissionsForm(!openPermissionsForm);
    setSelectedGroups([]);
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.mainTop}>
          <div>
            <div
              className={classes.backButton}
              onClick={() => UserNavigateActive(false)}
            >
              <ArrowBack />
              <Typography variant="body1" className={classes.arrow}>
                {`Back to ${props.header}`}
              </Typography>
            </div>
          </div>
          <div>
            <div className={classes.addButton}>
              <A_Button
                label={"Add User to Group"}
                color="primary"
                onClick={() => handlePermissionForm()}
                size={"small"}
                endIcon={
                  <AddIcon fontSize="small" style={{ marginTop: "3px" }} />
                }
              />
            </div>
          </div>
        </div>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <div
                className={
                  data?.email?.length > 45
                    ? classes.mainBottomResponsive
                    : classes.mainBottom
                }
              >
                <div
                  style={{
                    background: "#F7F7F7",
                    padding: "2rem",
                    borderRadius: "10px",
                    minWidth: `${data?.email?.length * 2 || 27} rem`,
                  }}
                >
                  <div className={classes.detailItemNew}>
                    <PersonIcon style={{ marginRight: "5px" }} />
                    {`${data?.firstName} ${data?.lastName}`}
                    {data.isEnabled ? (
                      <Tooltip title="Account Enabled" placement="right">
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{
                            color: "green",
                            margin: "0px 0px 2px 5px",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Account Disabled" placement="right">
                        <BlockIcon
                          style={{
                            margin: "0px 0px 2px 5px",
                            height: "1.2rem",
                          }}
                          fontSize="small"
                        />
                      </Tooltip>
                    )}
                  </div>
                  <Divider />
                  <div className={classes.detailItemNew}>
                    <MailOutlineIcon style={{ marginRight: "5px" }} />
                    <Typography>{`  ${data?.email}`}</Typography>
                  </div>
                  <Divider />
                  <div className={classes.detailItemNew}>
                    <PhoneIcon style={{ marginRight: "5px" }} />
                    <Typography>{` ${data?.phoneNumber}`}</Typography>
                  </div>
                </div>

                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "0px 10px" }}
                />
                <div className={classes.chipsContainerMain}>
                  <Typography
                    variant="body1"
                    style={{ margin: "5px 8px 8px 0px", fontWeight: "bold" }}
                  >
                    Associated permission groups
                  </Typography>
                  <div>
                    {!loading ? (
                      userGroup.length > 0 ? (
                        userGroup.map((item) => (
                          <>
                            <span
                              key={item.id}
                              className={classes.chipsContainer}
                            >
                              <Chip
                                label={item.name}
                                onDelete={() => handleDelete(item)}
                                className={classes.chip}
                                deleteIcon={
                                  <Tooltip title="Remove Group">
                                    <DeleteIcon
                                      className={classes.IconPlacement}
                                    />
                                  </Tooltip>
                                }
                                style={{
                                  boxShadow:
                                    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                }}
                              />
                            </span>
                          </>
                        ))
                      ) : (
                        "No Group Associated."
                      )
                    ) : (
                      <>
                        <Skeleton variant="text" width={80} height={80} />
                        <Skeleton variant="text" width={"100%"} height={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <A_SimpleDialog
        id="1"
        open={formOpen}
        title="Edit User"
        fullScreen={false}
        height="600px"
        fullwidth={true}
        maxWidth={"sm"}
        onClose={handleCloseform}
        dialogContent={<AddUsers data={data} />}
      />
      <A_SimpleDialog
        id="2"
        open={openPermissionsForm}
        title="Add Group"
        fullScreen={false}
        height="600px"
        fullwidth={true}
        maxWidth={"sm"}
        onClose={handlePermissionForm}
        dialogContent={
          <GroupsComponent
            dropDownVals={allGroup}
            selectedGroups={selectedGroups}
            handleChange={handleChange}
            classes={classes}
          />
        }
        dialogActions={
          <>
            <A_Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              label="Save"
              disabled={allGroup?.length === 0 || SaveLoading ? true : false}
              endIcon={
                SaveLoading && (
                  <A_CircularIndeternment color="secondary" size={20} />
                )
              }
            />
          </>
        }
      />
      <A_SimpleDialog
        id="3"
        title="Remove Group"
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        dialogContent={
          <div style={{ wordWrap: "break-word" }}>
            Are you sure you want to disassociate the group
            <b> &apos;{selectedGroupforDelete?.name || "Group"}&apos; </b>
            from {data?.email}?
          </div>
        }
        dialogActions={
          <>
            <A_Button
              label="Yes"
              color={"primary"}
              onClick={() => confirmDelete()}
              disabled={SaveLoading ? true : false}
              endIcon={
                SaveLoading && (
                  <A_CircularIndeternment color="secondary" size={20} />
                )
              }
            />
            <A_Button
              label="No"
              color={"inherit"}
              disabled={SaveLoading ? true : false}
              onClick={() => setDeletePopup(false)}
            />
          </>
        }
      />
    </>
  );
};

UserDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    url: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    emailConfirmed: PropTypes.bool,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumberConfirmed: PropTypes.bool,
    twoFactorEnabled: PropTypes.bool,
    lockoutEnd: PropTypes.string,
    lockoutEnabled: PropTypes.bool,
    accessFailedCount: PropTypes.number,
    isEnabled: PropTypes.bool,
    createCRMContact: PropTypes.bool,
    name: PropTypes.any,
  }).isRequired,
  UserNavigateActive: PropTypes.func.isRequired,
  name: PropTypes.any,
  setToast: PropTypes.func,
  header: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
