import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SurveyFilter, SurveyFilterYear ,CommonFilter} from "../components";
import * as esgApi from "../ESG_API/index";
import emitter from "../components/Emitter";
import ESG_CurrentPracticesView from "../components/ESG_CurrentPracticesView";
import { addHeaderHightForESGPages } from "../utils";



const ESG_AdminCurrentPractice = () => {
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [selectedSubPillarValue, setSelectedSubPillarValue] = useState("");
  const [selectedSubPillarId, setSelectedSubPillarId] = useState("");
  const [filterSubPillarTextValues, setFilterSubPillarTextValues] = useState([]);
  const [subPillardata, setSubPillardata] = useState([]);
  const [selectedSectorValue, setSelectedSectorValue] = useState([]);
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [filterSectorTextValues, setFilterSectorTextValues] = useState([]);
  const [sectorData, setSectorData] = useState([]);

  function getUniqueNames(array) {
    const uniqueNames = new Set(); // Use a Set to automatically handle uniqueness
    array.forEach((item) => {
      uniqueNames.add(item.name?item.name:(item.value?item.value:"")); // Add each name to the Set
    });
    return Array.from(uniqueNames); // Convert the Set back to an array
  }

  //Get SubPillar Data
  const getsubPillar = async () => {
    const resp = await esgApi.getSubPillar();
    resp.sort((a, b) => a.name.localeCompare(b.name));
    setSubPillardata(resp);
    setSelectedSubPillarId(resp.length > 0 ? resp[0].questionsubcategoryid : "")
    setSelectedSubPillarValue(resp.length > 0 ? resp[0].name : "");
    let uniqueSubPillarNames = getUniqueNames(resp);
    setFilterSubPillarTextValues(uniqueSubPillarNames);
  };

  //Get Sector Data
  const getSector = async () => {
    const getResp = await esgApi.getAllSector();
    const resp= getResp.data?getResp.data:[];
    resp.sort((a, b) => a.value.localeCompare(b.value));
    resp.unshift({sectorId:0,value:"All Sectors",label:"All Sectors"})
    setSectorData(resp);
    setSelectedSectorId(resp.length > 0?resp[0].sectorId:0);
    setSelectedSectorValue(resp.length > 0 ?resp[0].value:"");
    let uniqueSectorNames = getUniqueNames(resp);
    setFilterSectorTextValues(uniqueSectorNames);
  };
  

  useEffect(() => {
      if (selectedSubPillarValue !== "" ) {
          let selectedSubPillar = subPillardata.find((item) => item.name === selectedSubPillarValue);
          setSelectedSubPillarId(selectedSubPillar.questionsubcategoryid)
          localStorage.setItem("subPillarId", JSON.stringify(selectedSubPillar.questionsubcategoryid));
      }
      if (selectedSectorValue !== "" && selectedSectorValue !=="All Sectors" && selectedSectorValue !== 0) {
         let selectedSector = sectorData.find((item) => item.value == selectedSectorValue);
         setSelectedSectorId(selectedSector?.sectorId)
         localStorage.setItem("sectorId", JSON.stringify(selectedSector?.sectorId));
      }else{
        setSelectedSectorId(0);
        localStorage.removeItem("sectorId");
      }
      
      // emit event
      emitter.emit("reloadBestPracticeTable", true);

  }, [selectedSubPillarValue,selectedSectorValue,selectedSectorValue]);

  useEffect(()=>{
     // emit event
     emitter.emit("reloadBestPracticeTable", true);
  },[surveyId]);



  useEffect(() => {
    getsubPillar();
    getSector();
  }, []);

  useEffect(() => {
    const cleanupFunction = addHeaderHightForESGPages();
    return cleanupFunction;
  }, []); 


  return (
     <div>
      <SurveyFilter
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        surveyClosetoggle={true}
        heading={"Closed Survey"}
        style={{"styleTop":"112px"}}
      />
      <SurveyFilterYear
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        setSurveyFilterYear={setSurveyYear}
        surveyClosetoggle={true}
        heading={"Years"}
        style={{"styleTop":"112px"}}
      />
          <div>
            <CommonFilter
            selectedDataValue={selectedSubPillarValue}
            list={filterSubPillarTextValues}
            setSelectedDataValue={setSelectedSubPillarValue}
            defaultText={selectedSubPillarValue}
            heading={"SubPillar"}
            style={{
                position: "fixed",
                top: "112px",
                left: "605px",
                zIndex: "2",
                backgroundColor: "#ffff",
                width: "150px",
                height: "40px",
                borderRadius: "3px",
              }}
              headerStyle={{
                 position: "fixed",
                 top:"91px",
                 left: "605px",
                 zIndex: "2",
                 fontSize:"14px",
                 color:"white", 
                 fontWeight:"600"
                }} 
              
          />
          </div>
          <div>
           <CommonFilter
            selectedDataValue={selectedSectorValue}
            list={filterSectorTextValues}
            setSelectedDataValue={setSelectedSectorValue}
            defaultText={selectedSectorValue}
            heading={"Sectors"}
            style={{
                position: "fixed",
                top: "112px",
                left: "775px",
                zIndex: "2",
                backgroundColor: "#ffff",
                width: "150px",
                height: "40px",
                borderRadius: "3px",
              }}
              headerStyle={{
                position: "fixed",
                top:"91px",
                left: "775px",
                zIndex: "2",
                fontSize:"14px",
                color:"white", 
                fontWeight:"600"
               }} 
          />
         </div>
        <ESG_CurrentPracticesView
         selectedSurveyId={surveyId}
         selectedSubPillarId={selectedSubPillarId}
         selectedSectorId={selectedSectorId}
      />
     </div>
  );
};
ESG_AdminCurrentPractice.propTypes = {
  content: PropTypes,
};

export default ESG_AdminCurrentPractice;
