/* eslint-disable */
import {
  Tab,
  Tabs,
  AppBar,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "react-summernote/dist/react-summernote.css";
import { useParams } from "react-router";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import { dealTeam } from "../../../../../Utils/config/config";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../Utils/apis/apis";
import { withStyles } from "@material-ui/core/styles";
import M_ActionGroup from "../../../Molecules/Common/ActionGroup/M_ActionGroup";
import LoaderSkeleton from "../../../Molecules/Common/LoaderSkeleton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { cardType } from "../../../../../Utils/config/cardType";
import { message } from "../../../../../Utils/config/messages";
import * as dealTeamAction from "../../../../../store/actions/dealTeamAction";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router";
import { dealTeamEnums } from "./dealTeam.Helper";
import { executeJSString } from "../Layouts/layout.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

const useStyles = makeStyles({
  setListItemAvatar: {
    paddingTop: "8px",
  },
  spanPrimary1: {
    fontWeight: "600",
  },
  spanPrimary: {
    fontWeight: "600",
    cursor: "pointer",
    "&:hover": {
      color: "#007bff !important",
    },
  },
  mainDiv: {
    margin: "0px",
    maxHeight: "700px",
    overflow: "auto",
  },
  Label: {
    fontWeight: "900",
    width: 800,
    wordBreak: "break-word",
    textTransform: "capitalize",
    border: 0,
  },
  textLabel: {
    wordBreak: "break-word",
    width: 800,
    border: 0,
  },
  labelcolor: {
    paddingTop: "1rem",
    fontSize: "1.5rem",
    color: "#9c8662",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
    marginTop: "2%",
  },
});

const DealTeam = (props) => {
  const classes = useStyles();
  const [allData, setallData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDataNow, setShowDataNow] = useState(false);
  const [value, setValue] = useState(0);
  const [contentMessage, setContentMessage] = useState("");
  let { crmId } = useParams();
  const history = useHistory();
  const notClickable =
    props && props.content && props.content.notClickable
      ? props.content.notClickable
      : false;
  // This useEffect will run when data is available on redux props.dealTeamDataSet.data
  useEffect(() => {
    if (
      props.dealTeamDataSet &&
      props.dealTeamDataSet.data &&
      props.dealTeamDataSet.data.length > 0 &&
      props.dealTeamDataSet.data != message.NO_EDGE_FOUND &&
      props.dealTeamDataSet.loading == false &&
      showDataNow == true
    ) {
      let content = props && props.content ? props.content : "";
      getDealTeamData(content, [...props.dealTeamDataSet.data]);
      getImages([...props.dealTeamDataSet.data]);
    } else {
      setallData([]);
      if (
        (props.dealTeamDataSet &&
          props.dealTeamDataSet.data &&
          props.dealTeamDataSet.data == message.NO_EDGE_FOUND) ||
        (typeof props.dealTeamDataSet.data === "string" &&
          props.dealTeamDataSet.data == "")
      ) {
        setContentMessage(message.NO_CONTENT);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [props.dealTeamDataSet.data]);

  useEffect(() => {
    let convertedFormData = {
      sourceVertexId: crmId,
      edgeLabel: dealTeam.edgeLabel,
      fields: props.content.fieldPayload ? props.content.fieldPayload : "",
    };
    props.getEdge(convertedFormData);
    setShowDataNow(true);
  }, []);

  const getImages = async (data) => {
    if (data.length > 0 && data[0] != "[]") {
      let res = data;
      let images = [];
      let profileImageList = [];
      if (res.length != 0 && res != message.NO_EDGE_FOUND) {
        res.map((item) => {
          profileImageList.push({
            file: dealTeamEnums.PROFILE + item.inV,
            type: dealTeamEnums.IMAGE,
          });
        });
        let apidata = profileImageList.length > 0 ? profileImageList : [];
        if (apidata.length != 0) {
          let subdomain = new URL(window.location.href).hostname.split(".")[0];
          const url = `${process.env.BULK_DOCUMENT_HELPER}`;
          const httpConfig = commonFunctions.getHttpConfig();
          let header = {
            "file-operation": dealTeamEnums.DOWNLOAD,
            "process-file": dealTeamEnums.YES,
            "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
            subdomain: subdomain,
            Authorization: httpConfig.headers.Authorization,
          };
          var response = await httpPost(url, apidata, {
            responseType: "json",
            headers: header,
          });
        }
        images = response;
        try {
          if (images) {
            for (let i in res) {
              res[i][dealTeamEnums.IMAGE_LINK] = images[i].link;
            }
          }
        } catch {}
        setallData(res);
        setIsLoading(false);
      } else {
        setContentMessage(message.NO_CONTENT);
      }
    }
  };

  // TODO while Editing or adding page  should not be reload.
  const getDealTeamData = (content, allData) => {
    try {
      let res = allData;
      if (res.length > 0) {
        if (content && content.showTabs && content.showTabs.length == 1) {
          setallData(res);
          setIsLoading(false);
        } else {
          var dataArray = createVariables(content.showTabs);
          var Tabs = props.content.showTabs;
          for (let resData = 0; resData < res.length; resData++) {
            for (let tabsData = 0; tabsData < Tabs.length; tabsData++) {
              if (res[resData].user == Tabs[tabsData].title) {
                dataArray[tabsData].push(res[resData]);
                break;
              }
            }
          }
          setallData(dataArray);
          setIsLoading(false);
        }
      } else {
        if (res === "") {
          setIsLoading(false);
          setContentMessage(message.NO_CONTENT);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setContentMessage(message.NO_CONTENT);
    }
  };
  function createVariables(item) {
    var varArray = [];
    for (var i = 0; i < item.length; ++i) {
      varArray[i] = [];
    }
    return varArray;
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box padding={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes,
    value: PropTypes,
    index: PropTypes,
  };
  const contactName = (data) => {
    try {
      let result = `${
        data && data[props.content.names[0]] ? data[props.content.names[0]] : ""
      } ${
        data && data[props.content.names[1]] ? data[props.content.names[1]] : ""
      }`;
      return result;
    } catch (e) {
      return "";
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onDelete = async (edgeId) => {
    let payload = [`${edgeId}`];
    props.delEdge({ payload });
  };
  const handleLookup = (data) => {
    try {
      let subdomain = new URL(window.location.origin);
      let parseData = JSON.parse(data);
      return (
        <a href={`${subdomain}${parseData[0].label}/${parseData[0].id}`}>
          {parseData[0].title}
        </a>
      );
    } catch (e) {
      return data == "[]" ? "" : data;
    }
  };
  const GetTextField = ({ data }) => {
    return (
      <TableRow key={data}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {data.label}
        </TableCell>

        <TableCell align={"left"} className={classes.textLabel}>
          {handleLookup(
            props && props.currentPage && props.currentPage.data[data.id]
          )}
        </TableCell>
      </TableRow>
    );
  };
  const Redirect = (data) => {
    if (!notClickable) {
      try {
        let pageUrl = data.inVLabel;
        let UserId = data.inV;
        history.push(`/${pageUrl}/${UserId}`);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const contactInfo = (index, modify) => {
    var currentData = [];
    if (modify) {
      currentData = allData;
    } else {
      currentData = allData[index];
    }
    return (
      <div className={classes.mainDiv}>
        {props &&
          props.content &&
          props.content.members &&
          props.content.members.length > 0 &&
          props.content.members.map((option) => {
            return <GetTextField data={option} key={option} />;
          })}
        {props &&
        props.content &&
        props.content.members &&
        props.content.members.length > 0 ? (
          <>
            {" "}
            <Typography className={classes.labelcolor}>
              Deal Team Members
            </Typography>
            <Divider />
          </>
        ) : (
          ""
        )}
        {isLoading ? (
          <LoaderSkeleton />
        ) : currentData != undefined &&
          currentData.length > 0 &&
          currentData != dealTeam.NO_EDGE_FOUND ? (
          currentData.map((data) => (
            <span key={data.id}>
              <ListItem>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <ListItemAvatar className={classes.setListItemAvatar}>
                      <Avatar src={data.ImageLink} />
                    </ListItemAvatar>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    onClick={() => {
                      Redirect(data);
                    }}
                  >
                    <ListItemText
                      primary={
                        <span
                          className={
                            !notClickable
                              ? classes.spanPrimary
                              : classes.spanPrimary1
                          }
                        >
                          {data[dealTeamEnums.COMPANY_NAME] != undefined
                            ? data[dealTeamEnums.COMPANY_NAME]
                            : contactName(data)}
                        </span>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {data.role == undefined || data.role == ""
                              ? handleOptionSetValue(data.companyRole)
                              : handleOptionSetValue(data.role)}
                          </Typography>
                          <br />
                        </React.Fragment>
                      }
                    />
                  </Grid>

                  <Grid item xs={2}>
                    {executeJSString(
                      getObjectFromJSONString(
                        props,
                        ".content.readOnlyTemplate"
                      ),
                      getObjectFromJSONString(props, ".currentPage.data")
                    ) != true && (
                      <M_ActionGroup
                        edit={props.content.editbutton}
                        delete={props.content.deletebutton}
                        data={{
                          managedBy: createLookUpJson(data),
                          role: data.role,
                        }}
                        card={cardType.DEAL_TEAM}
                        edgeId={data.id}
                        onDelete={onDelete}
                        modify={true}
                        {...props}
                      />
                    )}
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </span>
          ))
        ) : (
          <div className={classes.noData}>
            <p>{contentMessage}</p>
          </div>
        )}
      </div>
    );
  };

  const createLookUpJson = (data) => {
    let reqObject = [
      {
        id: data.id,
        label: dealTeam.edgeLabel,
        title: data.firstName + data.lastName,
      },
    ];
    return JSON.stringify(reqObject);
  };
  return (
    <>
      {props &&
      props.content &&
      props.content.showTabs &&
      props.content.showTabs.length == 1 ? (
        contactInfo("0", "modify")
      ) : (
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {props &&
              props.content &&
              props.content.showTabs &&
              props.content.showTabs.length > 0
                ? props.content.showTabs.map((option) => {
                    return <CustomTab label={option.title} key={option.id} />;
                  })
                : ""}
            </Tabs>
          </AppBar>
          {props &&
          props.content &&
          props.content.showTabs &&
          props.content.showTabs.length > 0
            ? props.content.showTabs.map((option, index) => {
                return (
                  <TabPanel index={index} value={value} key={option.id}>
                    {contactInfo(option.id)}
                  </TabPanel>
                );
              })
            : ""}
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    dealTeamDataSet: state.dealTeamGetORdel,
    currentPage: state.currentPage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, toastActions, dealTeamAction),
    dispatch
  );
}
DealTeam.propTypes = {
  content: PropTypes.any,
  image: PropTypes.any,
  dealTeamDataSet: PropTypes.any,
  currentPage: PropTypes.object,
  setToast: PropTypes.object,
  dealTeam: PropTypes.any,
  delEdge: PropTypes.func,
  getEdge: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealTeam);
