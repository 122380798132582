/* eslint-disable no-eval */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import * as httpCall from "../../../../../Utils/apis/apis";
import {
  getEvaluatedValue,
  getUrlFromString,
} from "../../../../../Utils/Helpers/common";
import { useParams } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import "./M_MeetingsResultCard.css";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import { bindActionCreators } from "redux";
import {
  getFormattedDate,
  FromEpochFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import { httpPost } from "../../../../../Utils/apis/apis";
import {
  enhanceEvalString,
  renderIcons,
  getMeetings,
  getMeetingsV2,
} from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCardHelper";
import { ErrorMsg } from "../../../../../Utils/config/config";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import M_ActionGroup from "../../../Molecules/Common/ActionGroup/M_ActionGroup";
import * as dealTeamAction from "../../../../../store/actions/dealTeamAction";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import theme from "../../../../Themes/defaultTheme";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
} from "@material-ui/core";
import { AuditMeeting } from "./meetingSyncHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  avatar: {
    marginLeft: "-10px",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  card: {
    minHeight: "5rem",
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
  },
  popover: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const M_MeetingsResultTemplate = (props) => {
  let title =
    props &&
    props.item &&
    props.item.header &&
    props.item.header &&
    props.item.header.title
      ? props.item.header.title
      : "";
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { pageUrl, crmId } = useParams();
  const classes = useStyles();
  const edgeDelete =
    props && props.content && props.content.edgeDelete
      ? props.content.edgeDelete
      : false;
  let userSpecificDelete = false;
  let { crmContactUserId, crmContactUserEmail } = getCRMContactInfo();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );

  let MeetingAuditSpName = props?.content?.MeetingAuditSpName
    ? props.content.MeetingAuditSpName
    : "";

  useEffect(() => {
    setResultData([]);
  }, [undefined]);

  useEffect(() => {
    setIsLoading(true);
    setResultData([]);
    getResultTemplateData(true);
  }, [props.reload && props.reload.reloadChild]);

  useEffect(() => {
    getImages(resultData);
  }, [isLoading]);

  if (props && props.content && props.content.userSpecificDelete) {
    try {
      userSpecificDelete = crmId == userid ? true : false;
    } catch (error) {
      //intentionally added comment
    }
  }

  const getImages = async (finalData) => {
    if (finalData != undefined && finalData.length > 0) {
      var images = [];
      let profileImageList = [];
      let crmids = [];
      finalData.map((item) => {
        crmids.push("profile_" + item.id);
      });
      let finalObj = { items: crmids, type: "image" };
      profileImageList.push(finalObj);
      let apidata = profileImageList.length > 0 ? profileImageList : [];
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      const url = `${process.env.BULK_DOCUMENT_HELPER}`;
      let header = {
        "file-operation": "blob-items-link",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
      };
      var res = await httpPost(url, apidata, {
        responseType: "json",
        headers: header,
      });
      images = res && res.image ? res.image : [];

      finalData.forEach((item) => {
        var filteredImageItem = images.filter(
          (imageItem) => imageItem.file == `profile_${item.id}`
        );
        if (filteredImageItem && filteredImageItem.length > 0) {
          item["ImageLink"] = filteredImageItem[0].link;
        } else {
          item["ImageLink"] = null;
        }
      });
      var newData = [...finalData];
      setResultData(newData ? newData : []);
    }
  };

  const genericGremlinSP = async (content) => {
    let url = `/GenericGremlin/generic/${
      content && content.genericGremlinSP ? content.genericGremlinSP : ""
    }`;
    let requestPayload = {
      "@id": content["id"],
    };
    let data = [];
    const result = await httpCall.httpPost(url, requestPayload);
    result.map((item) => {
      data.push({
        id: item.id ? item.id : "",
        label: item.label ? item.label : "",
        properties: item,
      });
    });
    return data;
  };
  const getResultTemplateData = async (check = false) => {
    try {
      if (!resultData || resultData.length === 0 || check) {
        if (props.content) {
          props.content["id"] =
            props.content["id"] && props.content["id"][0] === "=" && crmId
              ? crmId
              : props.content["id"];
          let requestPayload = props.content;
          let url = "/CRMData/getCRMResultData";
          const result = await httpCall.httpPost(url, requestPayload);
          if (requestPayload && requestPayload.genericGremlinSP) {
            result.data = await genericGremlinSP(requestPayload);
          }
          if (
            requestPayload &&
            requestPayload.meetings &&
            requestPayload.isCompany != undefined
          ) {
            result.data = await getMeetings(crmId, requestPayload.isCompany);
          }
          if (requestPayload && requestPayload.getMeetingsV2) {
            result.data = await getMeetingsV2(crmId, requestPayload.isCompany);
          }
          if ("message" in result) {
            setIsError(result);
          } else {
            let template = result.template;
            let data = result.data;
            let finalData =
              data && Array.isArray(data)
                ? data.map((row) => {
                    let templateJson = JSON.parse(template.templateJson);
                    let newRow = {};
                    edgeDelete
                      ? (newRow["id"] =
                          row && row.properties && row.properties.edgeId
                            ? row.properties.edgeId
                            : row.id)
                      : (newRow["id"] = row.id);
                    let iconValue;
                    if (templateJson.multiIconCondition) {
                      try {
                        eval(
                          enhanceEvalString(
                            templateJson.multiIconCondition,
                            row.properties
                          )
                        );
                      } catch (error) {
                        // intentionally empty block
                      }
                    }
                    newRow["icon"] = iconValue;
                    for (let field in templateJson) {
                      let fieldValue = templateJson[field];
                      if (
                        typeof field === "string" &&
                        field.toLocaleLowerCase() === "redirectionurl"
                      ) {
                        newRow[field] = getUrlFromString(
                          fieldValue,
                          row,
                          pageUrl,
                          crmId
                        );
                        continue;
                      }
                      if (typeof fieldValue === "object") {
                        if (fieldValue && fieldValue.value) {
                          let newFieldValue = fieldValue.value;
                          newRow[field] = newFieldValue;
                          newRow["pageUrl"] = row.label;
                          if (newFieldValue && newFieldValue[0] === "=") {
                            let tempValue = getEvaluatedValue(
                              newFieldValue,
                              row.properties
                            );
                            let dateObject =
                              fieldValue.type &&
                              typeof fieldValue.type == "string" &&
                              fieldValue.type.toLowerCase() === "date"
                                ? FromEpochFormat(
                                    isNaN(tempValue)
                                      ? tempValue
                                      : parseInt(tempValue)
                                  )
                                : tempValue;
                            newRow[field] = getFormattedDate(
                              dateObject.toString(),
                              newFieldValue.format
                                ? newFieldValue.format
                                : "MM/DD/YYYY HH:MM"
                            );
                          }
                        }
                      } else {
                        newRow[field] = fieldValue;
                        newRow["pageUrl"] = row.label;
                        if (fieldValue && fieldValue[0] === "=") {
                          newRow[field] = getEvaluatedValue(
                            fieldValue,
                            row.properties,
                            false
                          );
                        }
                      }
                    }
                    if (
                      templateJson["tags"] &&
                      templateJson["tags"].length > 0
                    ) {
                      for (const [i, item] of templateJson["tags"].entries()) {
                        for (let field in item) {
                          let fieldValue = item[field];
                          newRow["pageUrl"] = row.label;
                          if (fieldValue && fieldValue[0] === "=") {
                            newRow["tags"][i][field] = getEvaluatedValue(
                              fieldValue,
                              row.properties
                            );
                          }
                        }
                      }
                    }
                    try {
                      var fullName = "";
                      if (
                        Array.isArray(templateJson.title) &&
                        templateJson.title.length > 0
                      ) {
                        for (let i = 0; i < templateJson.title.length; i++) {
                          fullName +=
                            row &&
                            row.properties &&
                            row.properties[templateJson.title[i]]
                              ? row.properties[templateJson.title[i]]
                              : "";
                          fullName += " ";
                        }
                        newRow["title"] = fullName;
                      }
                    } catch (e) {
                      return newRow;
                    }
                    if (row.properties && row.properties.owner != undefined) {
                      newRow["owner"] = row.properties.owner;
                    }
                    return newRow;
                  })
                : [];
            setResultData(finalData ? finalData : []);
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setResultData([]);
      }
    } catch (e) {
      setIsLoading(false);
      setResultData([]);
    }
  };
  const getTags = (tags) => {
    if (!tags || tags.length < 1) {
      return;
    }

    return tags.map((tag) => {
      const keys = Object.keys(tag);
      return tag[keys[0]] ? (
        <Chip
          key={tag[keys[0]]}
          size="small"
          label={tag[keys[0]]}
          style={{ backgroundColor: tag[keys[1]] ? tag[keys[1]] : "" }}
        />
      ) : (
        ""
      );
    });
  };

  const onDelete = async (edgeId) => {
    let payload = [`${edgeId}`];
    await AuditMeeting(pageUrl, edgeId, MeetingAuditSpName);
    props.delEdge({ payload });
  };

  const getTitle = (title) => {
    try {
      let parsedName = JSON.parse(title);
      return parsedName[0].title;
    } catch {
      return title;
    }
  };

  const meetingOwnerOrNot = (item) => {
    let current_epoch = new Date() / 1000;
    try {
      if (
        item &&
        item.owner == crmContactUserEmail &&
        item.date > current_epoch
      ) {
        return true;
      }
    } catch {
      return false;
    }
  };
  const renderResult =
    resultData &&
    resultData.map((record) => {
      return (
        <span key={record.title}>
          <div className={props.selectedId === record.id ? "selected" : ""}>
            <ListItem button>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <ListItemAvatar
                    style={{
                      paddingTop: "8px",
                      justify: "center",
                      alignItems: "center",
                    }}
                  >
                    {record.ImageLink != null ? (
                      <Avatar src={record.ImageLink} />
                    ) : (
                      <Avatar>
                        {renderIcons(
                          record["icon"]
                            ? record["icon"]
                            : props &&
                              props.content &&
                              props.content.defaultImage
                            ? props.content.defaultImage
                            : ""
                        )}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                </Grid>
                <Grid item xs={record.tags ? 4 : 8}>
                  <div
                    className={classes.avatar}
                    onClick={() => {
                      props.onSelect && typeof props.onSelect === "function"
                        ? props.onSelect(record)
                        : "";
                    }}
                  >
                    <ListItemText
                      primary={
                        record["redirectionUrl"] ? (
                          <a href={record["redirectionUrl"]}>
                            {record.title == ""
                              ? record && record.fullName
                                ? record.fullName
                                : ""
                              : getTitle(record.title)}
                          </a>
                        ) : record.title ? (
                          getTitle(record.title)
                        ) : (
                          ""
                        )
                      }
                      secondary={
                        <React.Fragment>
                          {record && record.mobileno ? (
                            <span>
                              <Typography component="span" variant="body2">
                                {record && record.mobileno == "=mobileno"
                                  ? "mobileno"
                                  : handleOptionSetValue(record.mobileno)}
                              </Typography>
                              <br />
                            </span>
                          ) : (
                            ""
                          )}
                          {record && record.information ? (
                            <span>
                              <Typography component="span" variant="body2">
                                {handleOptionSetValue(record.information)}
                              </Typography>
                              <br />
                            </span>
                          ) : (
                            ""
                          )}

                          {record && record.subtitle ? (
                            <span>
                              <Typography component="span" variant="body2">
                                {handleOptionSetValue(record.subtitle)}
                              </Typography>
                              <br />
                            </span>
                          ) : (
                            ""
                          )}
                          {record && record.date ? (
                            <span>
                              <Typography component="span" variant="body2">
                                {handelSubtilte(record.date)}
                              </Typography>
                            </span>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      }
                    />
                  </div>
                </Grid>

                {record.tags ? (
                  <Grid item xs={4}>
                    {getTags(record.tags)}
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={2}>
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  (props.currentPage.pageConfig.pageSecurity.Full === true ||
                    props.currentPage.pageConfig.pageSecurity.Update ===
                      true) ? (
                    edgeDelete ? (
                      <M_ActionGroup
                        delete={
                          (props && props.content && props.content.delete) ||
                          (userSpecificDelete && meetingOwnerOrNot(record))
                            ? true
                            : false
                        }
                        edit={
                          props && props.content && props.content.edit
                            ? props.content.edit
                            : false
                        }
                        id={record.id}
                        label={pageUrl}
                        onDelete={onDelete}
                        modify={true}
                        {...props}
                      />
                    ) : (
                      <M_ActionGroup
                        delete={
                          (props && props.content && props.content.delete) ||
                          (userSpecificDelete && meetingOwnerOrNot(record))
                            ? true
                            : false
                        }
                        edit={
                          props && props.content && props.content.edit
                            ? props.content.edit
                            : false
                        }
                        id={record.id}
                        label={pageUrl}
                        onDelete={onDelete}
                        modify={true}
                        {...props}
                      />
                    )
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </ListItem>
          </div>
          <Divider />
        </span>
      );
    });

  return (
    <ThemeProvider theme={theme}>
      <Card
        className={classes.root}
        style={{ overflow: "visible" }}
        elevation={2}
      >
        <Box>
          <CardHeader
            className={classes.card}
            style={{ width: "100%", height: "1rem" }}
            title={
              <React.Fragment>
                <Typography variant="h6" id="tableTitle" component="div">
                  {title}
                </Typography>
              </React.Fragment>
            }
            classes={{
              title: classes.title,
            }}
          />
        </Box>
        <A_Divider />
        <CardContent style={{ height: "700px", overflowY: "auto" }}>
          <div
            style={{
              height: props.height ? props.height : "400px",
              overflowY: "auto",
            }}
          >
            {isLoading && (
              <>
                <Skeleton variant="text" width={80} height={80} />
                <Skeleton variant="text" width={"100%"} height={80} />
                <Skeleton variant="rect" width={"100%"} height={118} />
              </>
            )}
            {isError && (
              <div>
                <h4>{ErrorMsg.ERROR}</h4>
                <p> Error : {isError.message}</p>
                <h5>{ErrorMsg.MESSAGE}</h5>
              </div>
            )}

            {!isError ? (
              resultData && resultData.length > 0 ? (
                <List dense className={classes.root}>
                  {renderResult}
                </List>
              ) : (
                <>
                  {!isLoading && (
                    <div className={classes.noData}>
                      <p>WE ARE SORRY, NO CONTENT FOUND!</p>
                    </div>
                  )}
                </>
              )
            ) : (
              ""
            )}
          </div>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </ThemeProvider>
  );
};
M_MeetingsResultTemplate.defaultProps = {
  content: {},
};

function handelSubtilte(data) {
  let convertData = "";
  try {
    convertData = JSON.parse(data);
    if (convertData.timeZone) {
      let newDate = new Date(convertData.dateTime + "Z");
      let year = newDate.getFullYear();
      let Month = newDate.getMonth() + 1;
      let day = newDate.getDate();
      let Datee = day + "/" + Month + "/" + year;
      return Datee;
    } else {
      let newDate = FromEpochFormat(convertData);
      let date = new Date(newDate);
      let year = date.getFullYear();
      let Month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let finalDate = day + "/" + Month + "/" + year;
      return finalDate;
    }
  } catch (e) {
    return data;
  }
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
    dealTeamDataSet: state.dealTeamGetORdel,
    reload: state.ReloadResultTemplate,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, managePageActions, dealTeamAction),
    dispatch
  );
}

M_MeetingsResultTemplate.propTypes = {
  stateObj: PropTypes.object,
  content: PropTypes.object,
  onSelect: PropTypes.func,
  selectedId: PropTypes.any,
  currentPage: PropTypes.object,
  resetManagePage: PropTypes.func,
  height: PropTypes.any,
  delEdge: PropTypes.any,
  reload: PropTypes.any,
  item: PropTypes.any,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_MeetingsResultTemplate);
